import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { AssetMediaEdm } from './DataDomain/EFEntities/assetmediaedm.entity';
import { AssetOuEdm } from './DataDomain/EFEntities/assetouedm.entity';
import { AssetParentAssetEdm } from './DataDomain/EFEntities/assetparentassetedm.entity';
import { AssetTemplateEdm } from './DataDomain/EFEntities/assettemplateedm.entity';
import { IconEdm } from './DataDomain/EFEntities/iconedm.entity';
import { PersonAssetStatusEdm } from './DataDomain/EFEntities/personassetstatusedm.entity';
//#endregion

@Injectable()
export class AssetsService extends ODataEntitySetService<AssetEdm> {
  constructor(client: ODataClient) {
    super(client, 'Assets', 'PaPlusApi.DataDomain.EFEntities.AssetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public assetMedias(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<AssetMediaEdm> { 
    return this.entity(key).navigationProperty<AssetMediaEdm>('assetMedias'); 
  }
  public fetchAssetMedias(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<AssetMediaEdm>) {
    return this.fetchNavigationProperty<AssetMediaEdm>(
      this.assetMedias(key), 
      'entities', options) as Observable<ODataEntities<AssetMediaEdm>>;
  }
  public addAssetMediaEdmToAssetMedias(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<AssetMediaEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetMedias(key).reference()
      .add(target);
  }
  public removeAssetMediaEdmFromAssetMedias(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetMediaEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetMedias(key).reference()
      .remove(target);
  }
  public assetOus(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<AssetOuEdm> { 
    return this.entity(key).navigationProperty<AssetOuEdm>('assetOus'); 
  }
  public fetchAssetOus(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<AssetOuEdm>) {
    return this.fetchNavigationProperty<AssetOuEdm>(
      this.assetOus(key), 
      'entities', options) as Observable<ODataEntities<AssetOuEdm>>;
  }
  public addAssetOuEdmToAssetOus(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<AssetOuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetOus(key).reference()
      .add(target);
  }
  public removeAssetOuEdmFromAssetOus(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetOuEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetOus(key).reference()
      .remove(target);
  }
  public assetParentAssets(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<AssetParentAssetEdm> { 
    return this.entity(key).navigationProperty<AssetParentAssetEdm>('assetParentAssets'); 
  }
  public fetchAssetParentAssets(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<AssetParentAssetEdm>) {
    return this.fetchNavigationProperty<AssetParentAssetEdm>(
      this.assetParentAssets(key), 
      'entities', options) as Observable<ODataEntities<AssetParentAssetEdm>>;
  }
  public addAssetParentAssetEdmToAssetParentAssets(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<AssetParentAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetParentAssets(key).reference()
      .add(target);
  }
  public removeAssetParentAssetEdmFromAssetParentAssets(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetParentAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetParentAssets(key).reference()
      .remove(target);
  }
  public assetTemplates(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<AssetTemplateEdm> { 
    return this.entity(key).navigationProperty<AssetTemplateEdm>('assetTemplates'); 
  }
  public fetchAssetTemplates(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<AssetTemplateEdm>) {
    return this.fetchNavigationProperty<AssetTemplateEdm>(
      this.assetTemplates(key), 
      'entities', options) as Observable<ODataEntities<AssetTemplateEdm>>;
  }
  public addAssetTemplateEdmToAssetTemplates(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .add(target);
  }
  public removeAssetTemplateEdmFromAssetTemplates(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .remove(target);
  }
  public icon(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<IconEdm> { 
    return this.entity(key).navigationProperty<IconEdm>('icon'); 
  }
  public fetchIcon(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<IconEdm>) {
    return this.fetchNavigationProperty<IconEdm>(
      this.icon(key), 
      'entity', options) as Observable<ODataEntity<IconEdm>>;
  }
  public setIconEdmAsIcon(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntity<IconEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.icon(key).reference()
      .set(target, {etag});
  }
  public unsetIconEdmAsIcon(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<IconEdm>>, etag?: string} = {}): Observable<any> {
    return this.icon(key).reference()
      .unset({etag});
  }
  public parentAssetParentAssets(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<AssetParentAssetEdm> { 
    return this.entity(key).navigationProperty<AssetParentAssetEdm>('parentAssetParentAssets'); 
  }
  public fetchParentAssetParentAssets(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<AssetParentAssetEdm>) {
    return this.fetchNavigationProperty<AssetParentAssetEdm>(
      this.parentAssetParentAssets(key), 
      'entities', options) as Observable<ODataEntities<AssetParentAssetEdm>>;
  }
  public addAssetParentAssetEdmToParentAssetParentAssets(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<AssetParentAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentAssetParentAssets(key).reference()
      .add(target);
  }
  public removeAssetParentAssetEdmFromParentAssetParentAssets(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetParentAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentAssetParentAssets(key).reference()
      .remove(target);
  }
  public personAssetStatuses(key: EntityKey<AssetEdm>): ODataNavigationPropertyResource<PersonAssetStatusEdm> { 
    return this.entity(key).navigationProperty<PersonAssetStatusEdm>('personAssetStatuses'); 
  }
  public fetchPersonAssetStatuses(key: EntityKey<AssetEdm>, options?: ODataQueryArgumentsOptions<PersonAssetStatusEdm>) {
    return this.fetchNavigationProperty<PersonAssetStatusEdm>(
      this.personAssetStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonAssetStatusEdm>>;
  }
  public addPersonAssetStatusEdmToPersonAssetStatuses(key: EntityKey<AssetEdm>, target: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .add(target);
  }
  public removePersonAssetStatusEdmFromPersonAssetStatuses(key: EntityKey<AssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
