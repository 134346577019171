<ion-list>
    <ion-list-header>
        <ion-label>Environment</ion-label>
    </ion-list-header>
    <!-- Segment to switch environments -->
    <ion-item>
        <ion-segment [scrollable]="true" [value]="selectedEnvironment" (ionChange)="onEnvironmentSegmentChanged($event)">
            <!-- Dynamically create segment buttons for each environment in the enum -->
            <ion-segment-button *ngFor="let env of availableEnvironments" [value]="env">
                {{ env }}
            </ion-segment-button>
        </ion-segment>
    </ion-item>
</ion-list>

<ion-list>
    <ion-list-header>
        <ion-label>Selected Environment Config</ion-label>
    </ion-list-header>

    <!-- Display environment config key/value pairs (example approach) -->
    <ion-item *ngFor="let key of objectKeys(environmentConfig)">
        <ion-label>
            <strong>{{ key }}</strong>:
            <span>{{ environmentConfig[key] }}</span>
        </ion-label>
    </ion-item>
</ion-list>

<div class="ion-padding">
    <ion-button color="primary" expand="block" (click)="confirmEnvironmentSwitch()">
        Switch Environment
    </ion-button>
</div>