import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { AssetOuEdm } from './DataDomain/EFEntities/assetouedm.entity';
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
//#endregion

@Injectable()
export class AssetOusService extends ODataEntitySetService<AssetOuEdm> {
  constructor(client: ODataClient) {
    super(client, 'AssetOus', 'PaPlusApi.DataDomain.EFEntities.AssetOuEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public asset(key: EntityKey<AssetOuEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('asset'); 
  }
  public fetchAsset(key: EntityKey<AssetOuEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.asset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsAsset(key: EntityKey<AssetOuEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsAsset(key: EntityKey<AssetOuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .unset({etag});
  }
  public ou(key: EntityKey<AssetOuEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<AssetOuEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<AssetOuEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<AssetOuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  //#endregion
}
