import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    constructor(private toastController: ToastController) { }

    private async showToast(message: string, color: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 5000,
            color: color,
            position: 'bottom',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel'
                }
            ]
        });
        toast.present();
    }

    // Error notification
    showError(message: string) {
        this.showToast(message, 'danger');
        console.error(message);
    }

    // Information notification
    showInfo(message: string) {
        this.showToast(message, 'primary');
        console.info(message);
    }

    // Debug notification
    showDebug(message: string) {
        this.showToast(message, 'medium');
        console.debug(message);
    }

    // Warning notification
    showWarning(message: string) {
        this.showToast(message, 'warning');
        console.warn(message);
    }

    // Error logging to console
    logError(message: string) {
        console.error(message);
    }

    // Warning logging to console
    logInfo(message: string) {
        console.info(message);
    }

    // Debug logging to console
    logDebug(message: string) {
        console.debug(message);
    }   

    // Warning logging to console
    logWarning(message: string) {
        console.warn(message);
    }
}
