import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { IconEdm } from './DataDomain/EFEntities/iconedm.entity';
import { JobAssetEdm } from './DataDomain/EFEntities/jobassetedm.entity';
//#endregion

@Injectable()
export class IconsService extends ODataEntitySetService<IconEdm> {
  constructor(client: ODataClient) {
    super(client, 'Icons', 'paplusApi.DataDomain.EFEntities.IconEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public assets(key: EntityKey<IconEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('assets'); 
  }
  public fetchAssets(key: EntityKey<IconEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.assets(key), 
      'entities', options) as Observable<ODataEntities<AssetEdm>>;
  }
  public addAssetEdmToAssets(key: EntityKey<IconEdm>, target: ODataEntityResource<ODataEntities<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assets(key).reference()
      .add(target);
  }
  public removeAssetEdmFromAssets(key: EntityKey<IconEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.assets(key).reference()
      .remove(target);
  }
  public jobAssets(key: EntityKey<IconEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobAssets'); 
  }
  public fetchJobAssets(key: EntityKey<IconEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobAssets(key), 
      'entities', options) as Observable<ODataEntities<JobAssetEdm>>;
  }
  public addJobAssetEdmToJobAssets(key: EntityKey<IconEdm>, target: ODataEntityResource<ODataEntities<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssets(key).reference()
      .add(target);
  }
  public removeJobAssetEdmFromJobAssets(key: EntityKey<IconEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssets(key).reference()
      .remove(target);
  }
  //#endregion
}
