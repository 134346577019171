//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssignedJobsListEdm } from './assignedjobslistedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssignedJobsListEdmComplexConfig = {
  name: 'AssignedJobsListEdm',
  fields: {
    jobGuid: {type: 'Edm.Guid', nullable: false},
    projectGuid: {type: 'Edm.Guid'},
    ouGuid: {type: 'Edm.Guid'},
    jobName: {type: 'Edm.String', maxLength: -1},
    lat: {type: 'Edm.Decimal', scale: 'variable'},
    lng: {type: 'Edm.Decimal', scale: 'variable'},
    what3Words: {type: 'Edm.String'},
    siteCode: {type: 'Edm.String'},
    notes: {type: 'Edm.String', maxLength: -1},
    status: {type: 'Edm.Int16'},
    statusDate: {type: 'Edm.DateTimeOffset'},
    siteName: {type: 'Edm.String'},
    projectName: {type: 'Edm.String', nullable: false, maxLength: -1},
    code: {type: 'Edm.String', nullable: false},
    externalCode: {type: 'Edm.String'},
    importTemplatesSerialisedValue: {type: 'Edm.String', maxLength: -1},
    likeCount: {type: 'Edm.Int32'},
    disLikeCount: {type: 'Edm.Int32'},
    refreshCount: {type: 'Edm.Int32'},
    activityCount: {type: 'Edm.Int32'},
    customerJobCode: {type: 'Edm.String'},
    expr1: {type: 'Edm.String', maxLength: -1},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16'},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    line1: {type: 'Edm.String'},
    line2: {type: 'Edm.String'},
    city: {type: 'Edm.String'},
    county: {type: 'Edm.String'},
    countryCode: {type: 'Edm.String'},
    country: {type: 'Edm.String'},
    postcode: {type: 'Edm.String'},
    toDate: {type: 'Edm.DateTimeOffset'},
    deleted: {type: 'Edm.DateTimeOffset'},
    jobTemplateGuid: {type: 'Edm.Guid'},
    templateGuid: {type: 'Edm.Guid'},
    hasTemplate: {type: 'Edm.String', nullable: false},
    jobCode: {type: 'Edm.String'},
    personGuid: {type: 'Edm.Guid', nullable: false}
  }
} as StructuredTypeConfig<AssignedJobsListEdm>;
//#endregion