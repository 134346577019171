import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonOuRoleEdm } from './DataDomain/EFEntities/personouroleedm.entity';
import { RoleEdm } from './DataDomain/EFEntities/roleedm.entity';
//#endregion

@Injectable()
export class PersonOuRolesService extends ODataEntitySetService<PersonOuRoleEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonOuRoles', 'PaPlusApi.DataDomain.EFEntities.PersonOuRoleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public ou(key: EntityKey<PersonOuRoleEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<PersonOuRoleEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<PersonOuRoleEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<PersonOuRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonOuRoleEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonOuRoleEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonOuRoleEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonOuRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public reportingPerson(key: EntityKey<PersonOuRoleEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('reportingPerson'); 
  }
  public fetchReportingPerson(key: EntityKey<PersonOuRoleEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.reportingPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsReportingPerson(key: EntityKey<PersonOuRoleEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reportingPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsReportingPerson(key: EntityKey<PersonOuRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.reportingPerson(key).reference()
      .unset({etag});
  }
  public role(key: EntityKey<PersonOuRoleEdm>): ODataNavigationPropertyResource<RoleEdm> { 
    return this.entity(key).navigationProperty<RoleEdm>('role'); 
  }
  public fetchRole(key: EntityKey<PersonOuRoleEdm>, options?: ODataQueryArgumentsOptions<RoleEdm>) {
    return this.fetchNavigationProperty<RoleEdm>(
      this.role(key), 
      'entity', options) as Observable<ODataEntity<RoleEdm>>;
  }
  public setRoleEdmAsRole(key: EntityKey<PersonOuRoleEdm>, target: ODataEntityResource<ODataEntity<RoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .set(target, {etag});
  }
  public unsetRoleEdmAsRole(key: EntityKey<PersonOuRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<RoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .unset({etag});
  }
  //#endregion
}
