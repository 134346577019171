import { AuthenticationResult, AuthError, Configuration, IController, INavigationClient, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { Capacitor } from "@capacitor/core";
import { CustomNavigationClient } from "./custom-navigation.client";
import { DeepLinksService } from "@sharedLibrary/services/deep-links.service";

export class PAPlusPublicClientApplication extends PublicClientApplication implements IPublicClientApplication {
    constructor(navigationClient: INavigationClient, configuration: Configuration, controller?: IController) {
        super(configuration, controller);
        if (Capacitor.isNativePlatform()) {
            this.setNavigationClient(navigationClient);
        }
    }

    override async handleRedirectPromise(hash?: string): Promise<AuthenticationResult | null> {
        try {
            const result = await super.handleRedirectPromise(hash);
            return result;
        } catch (error) {
            if (error instanceof AuthError) {
                // AADB2C90091: The user has cMsalGuardancelled entering self-asserted information.
                // Remove or replace the hash in the URL here if needed, to keep your app from “blocking” the canceled state
                if (error.errorMessage.includes("AADB2C90091")) {
                    // Example: remove the hash by resetting the browser's URL
                    window.history.replaceState({}, document.title, window.location.pathname + window.location.search);
                }
            }

            throw error; // rethrow so the app knows about the error
        }
    }
}