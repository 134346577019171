//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TemplateStatusesService } from './templatestatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TemplateStatusesServiceEntitySetConfig = {
  name: 'TemplateStatuses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.TemplateStatusEdm',
  service: TemplateStatusesService
} as EntitySetConfig;
//#endregion