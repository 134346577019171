//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectProjectStatusesService } from './projectprojectstatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProjectProjectStatusesServiceEntitySetConfig = {
  name: 'ProjectProjectStatuses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.ProjectProjectStatusEdm',
  service: ProjectProjectStatusesService
} as EntitySetConfig;
//#endregion