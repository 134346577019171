//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface JobsListEdm {
  //#region ODataApiGen Properties
  jobGuid: string;
  projectGuid?: string;
  ouGuid?: string;
  jobName?: string;
  jobCode?: string;
  lat?: number;
  lng?: number;
  what3Words?: string;
  siteCode?: string;
  notes?: string;
  status?: number;
  statusDate?: Date;
  siteName?: string;
  projectName: string;
  code: string;
  externalCode?: string;
  importTemplatesSerialisedValue?: string;
  likeCount: number;
  disLikeCount: number;
  refreshCount: number;
  activityCount: number;
  customerJobCode?: string;
  expr1?: string;
  addressGuid?: string;
  addressType?: number;
  manuallyEntered?: boolean;
  fromDate?: Date;
  line1?: string;
  line2?: string;
  city?: string;
  county?: string;
  countryCode?: string;
  country?: string;
  postcode?: string;
  toDate?: Date;
  deleted?: Date;
  jobTemplateGuid?: string;
  templateGuid?: string;
  hasTemplate?: string;
  //#endregion
}
