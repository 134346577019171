import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetStatusEdm } from './DataDomain/EFEntities/assetstatusedm.entity';
import { PersonAssetStatusEdm } from './DataDomain/EFEntities/personassetstatusedm.entity';
//#endregion

@Injectable()
export class AssetStatusesService extends ODataEntitySetService<AssetStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'AssetStatuses', 'paplusApi.DataDomain.EFEntities.AssetStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public personAssetStatuses(key: EntityKey<AssetStatusEdm>): ODataNavigationPropertyResource<PersonAssetStatusEdm> { 
    return this.entity(key).navigationProperty<PersonAssetStatusEdm>('personAssetStatuses'); 
  }
  public fetchPersonAssetStatuses(key: EntityKey<AssetStatusEdm>, options?: ODataQueryArgumentsOptions<PersonAssetStatusEdm>) {
    return this.fetchNavigationProperty<PersonAssetStatusEdm>(
      this.personAssetStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonAssetStatusEdm>>;
  }
  public addPersonAssetStatusEdmToPersonAssetStatuses(key: EntityKey<AssetStatusEdm>, target: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .add(target);
  }
  public removePersonAssetStatusEdmFromPersonAssetStatuses(key: EntityKey<AssetStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
