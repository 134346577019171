//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuCloseoutPackagesService } from './oucloseoutpackages.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OuCloseoutPackagesServiceEntitySetConfig = {
  name: 'OuCloseoutPackages',
  entityType: 'PaPlusApi.DataDomain.EFEntities.OuCloseoutPackageEdm',
  service: OuCloseoutPackagesService
} as EntitySetConfig;
//#endregion