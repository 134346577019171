import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './DataDomain/EFEntities/permissionedm.entity';
import { RoleEdm } from './DataDomain/EFEntities/roleedm.entity';
import { RolePermissionEdm } from './DataDomain/EFEntities/rolepermissionedm.entity';
//#endregion

@Injectable()
export class RolePermissionsService extends ODataEntitySetService<RolePermissionEdm> {
  constructor(client: ODataClient) {
    super(client, 'RolePermissions', 'paplusApi.DataDomain.EFEntities.RolePermissionEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public permission(key: EntityKey<RolePermissionEdm>): ODataNavigationPropertyResource<PermissionEdm> { 
    return this.entity(key).navigationProperty<PermissionEdm>('permission'); 
  }
  public fetchPermission(key: EntityKey<RolePermissionEdm>, options?: ODataQueryArgumentsOptions<PermissionEdm>) {
    return this.fetchNavigationProperty<PermissionEdm>(
      this.permission(key), 
      'entity', options) as Observable<ODataEntity<PermissionEdm>>;
  }
  public setPermissionEdmAsPermission(key: EntityKey<RolePermissionEdm>, target: ODataEntityResource<ODataEntity<PermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .set(target, {etag});
  }
  public unsetPermissionEdmAsPermission(key: EntityKey<RolePermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .unset({etag});
  }
  public role(key: EntityKey<RolePermissionEdm>): ODataNavigationPropertyResource<RoleEdm> { 
    return this.entity(key).navigationProperty<RoleEdm>('role'); 
  }
  public fetchRole(key: EntityKey<RolePermissionEdm>, options?: ODataQueryArgumentsOptions<RoleEdm>) {
    return this.fetchNavigationProperty<RoleEdm>(
      this.role(key), 
      'entity', options) as Observable<ODataEntity<RoleEdm>>;
  }
  public setRoleEdmAsRole(key: EntityKey<RolePermissionEdm>, target: ODataEntityResource<ODataEntity<RoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .set(target, {etag});
  }
  public unsetRoleEdmAsRole(key: EntityKey<RolePermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<RoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .unset({etag});
  }
  //#endregion
}
