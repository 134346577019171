//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuTemplateEdm } from './outemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OuTemplateEdmEntityConfig = {
  name: 'OuTemplateEdm',
  keys: [{name: 'ouTemplateGuid'}],
  fields: {
    ouTemplateGuid: {type: 'Edm.Guid', nullable: false},
    ouGuid: {type: 'Edm.Guid'},
    templateGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    ou: {type: 'PaPlusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouGuid', referencedProperty: 'ouGuid'}]},
    template: {type: 'PaPlusApi.DataDomain.EFEntities.TemplateEdm', navigation: true, referentials: [{property: 'templateGuid', referencedProperty: 'templateGuid'}]}
  }
} as StructuredTypeConfig<OuTemplateEdm>;
//#endregion