//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateInfoEdmService } from './persontemplateinfoedm.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonTemplateInfoEdmServiceEntitySetConfig = {
  name: 'PersonTemplateInfoEdm',
  entityType: 'paplusApi.DataDomain.EFEntities.PersonTemplateInfoEdm',
  service: PersonTemplateInfoEdmService
} as EntitySetConfig;
//#endregion