import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaptureEdm, JobAssetMediaEdm, PersonMediaStatusEdm } from '@odata';
import { ODataServiceFactory } from 'angular-odata';
import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { CaptureSelectedInfo } from '../entities/capture-selected-info';
import { DynamicEnvironmentService } from './dynamic-environment.service';
import { BaseSignalRService, SignalREventType } from './base-signalr.service';
import { SignalRService } from './signalr.service';
import { CaptureSignalRUpdate } from '@sharedLibrary/models/custom-capture-models';

@Injectable({
  providedIn: 'root'
})
export class CapturesService {

  private selectedCapture: Subject<CaptureSelectedInfo | null> = new Subject<CaptureSelectedInfo | null>();
  public selectedCapture$ = this.selectedCapture.asObservable();

  private assetMediaCaptureUpdatedSubject: Subject<CaptureSignalRUpdate> = new Subject<CaptureSignalRUpdate>();
  public assetMediaCaptureUpdated$ = this.assetMediaCaptureUpdatedSubject.asObservable();

  private personMediaStatusUpdatedSubject: Subject<PersonMediaStatusEdm> = new Subject<PersonMediaStatusEdm>();
  public personMediaStatusUpdated$ = this.personMediaStatusUpdatedSubject.asObservable();


  private captureStatusUpdated: Subject<JobAssetMediaEdm> = new Subject<JobAssetMediaEdm>();
  public captureStatusUpdated$ = this.captureStatusUpdated.asObservable();

  constructor(private envService: DynamicEnvironmentService,
    private http: HttpClient,
    private factory: ODataServiceFactory,
    private signalRService: SignalRService
  ) {
    this.setupSignalREvents();
  }

  setupSignalREvents() {
    const assetMediaUpdate = new BaseSignalRService<PersonMediaStatusEdm | CaptureEdm>(this.signalRService,
      [
        { name: 'PersonMediaStatus', type: SignalREventType.All },
        { name: 'Capture', type: SignalREventType.All },
      ]);

    assetMediaUpdate.events$.subscribe(async (assetMediaUpdateEvent) => {
      // Handle Person Media Status event
      if (assetMediaUpdateEvent && assetMediaUpdateEvent.name == 'PersonMediaStatus') {
        const personMediaStatus = assetMediaUpdateEvent.item as PersonMediaStatusEdm;
        this.personMediaStatusUpdatedSubject.next(personMediaStatus);
      }

      // Handle Capture event
      if (assetMediaUpdateEvent && assetMediaUpdateEvent.name == 'Capture') {
        const capture: CaptureEdm = assetMediaUpdateEvent.item as CaptureEdm;
        const captureBlob = await firstValueFrom(this.getFileByCaptureGuid(capture.captureGuid, false));
        const captureUpdate: CaptureSignalRUpdate = { capture: capture, captureBlob };
        this.assetMediaCaptureUpdatedSubject.next(captureUpdate);
      }
    });
  }

  getCapturesByJobAsset(jobAssetGuid: string): Observable<CaptureEdm[]> {
    let capturesService = this.factory.entitySet<CaptureEdm>(
      "CapturesByJobAsset(" + jobAssetGuid + ")",
      "paplusApi.DataDomain.EFEntities.CaptureEdm"
    );

    return capturesService.entities().fetch().pipe(
      map(res =>
        res.entities == null ? [] : res.entities)
    );
  }

  getCapturesByJobAssetMedia(jobAssetMediaGuid: string): Observable<CaptureDTO[]> {
    let capturesService = this.factory.entitySet<CaptureDTO>(
      "CapturesWithImage",
      "paplusApi.DataDomain.EFEntities.CaptureEdm"
    );

    let captures = capturesService.entities();
    captures.query((q) => q.filter("JobAssetMediaGuid eq " + jobAssetMediaGuid))

    return captures.fetchEntities().pipe(
      map(res =>
        res == null ? [] : res)
    );
  }

  getFileByCaptureGuid(captureGuid: string, fullsize: boolean): Observable<Blob> {
    return this.http.get(`${this.envService.environment.Api}/CaptureFile(${captureGuid},${fullsize})`, { responseType: 'blob' });
  }

  uploadCapture(jobGuid: string, jobAssetGuid: string, jobAssetMediaGuid: string, source: number, latitude: number | undefined, longitude: number | undefined, exifMetadata: string | undefined, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('JobGuid', jobGuid);
    formData.append('JobAssetGuid', jobAssetGuid);
    formData.append('JobAssetMediaGuid', jobAssetMediaGuid);
    formData.append('Source', source.toString());
    formData.append('Latitude', latitude ? latitude.toString() : null);
    formData.append('Longitude', longitude ? longitude.toString() : null);
    formData.append('exifMetadata', exifMetadata ? exifMetadata : null);
    formData.append('CaptureFile', file);

    return this.http.post(`${this.envService.environment.Api}/UploadCapture`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  updateSelectedCapture(jobAssetMedia: JobAssetMediaEdm, capture: CaptureEdm, fullsize: boolean) {
    let selectedCaptureInfo = {
      selectedCapture: capture,
      currentJobAssetMedia: jobAssetMedia
    } as CaptureSelectedInfo;

    this.selectedCapture.next(selectedCaptureInfo);

    /*this.getFileByCaptureGuid(capture.captureGuid, fullsize).subscribe(res => {
      this.selectedImage.next(URL.createObjectURL(res));
    });*/
  }

  captureStatusHasBeenUpdated(assetMedia: JobAssetMediaEdm) {
    this.captureStatusUpdated.next(assetMedia);
  }
}


export interface CaptureDTO {
  captureGuid: string;
  jobAssetMediaGuid?: string;
  createUtcDateTime: Date;
  notes?: string;
  mediaSource?: number;
  filePath: string;
  nativeFileName?: string;
  imageData?: ArrayBuffer;
  contentType?: string;
}