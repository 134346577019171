import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectEdm } from './DataDomain/EFEntities/projectedm.entity';
import { ProjectProjectStatusEdm } from './DataDomain/EFEntities/projectprojectstatusedm.entity';
import { ProjectStatusEdm } from './DataDomain/EFEntities/projectstatusedm.entity';
//#endregion

@Injectable()
export class ProjectProjectStatusesService extends ODataEntitySetService<ProjectProjectStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'ProjectProjectStatuses', 'PaPlusApi.DataDomain.EFEntities.ProjectProjectStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public project(key: EntityKey<ProjectProjectStatusEdm>): ODataNavigationPropertyResource<ProjectEdm> { 
    return this.entity(key).navigationProperty<ProjectEdm>('project'); 
  }
  public fetchProject(key: EntityKey<ProjectProjectStatusEdm>, options?: ODataQueryArgumentsOptions<ProjectEdm>) {
    return this.fetchNavigationProperty<ProjectEdm>(
      this.project(key), 
      'entity', options) as Observable<ODataEntity<ProjectEdm>>;
  }
  public setProjectEdmAsProject(key: EntityKey<ProjectProjectStatusEdm>, target: ODataEntityResource<ODataEntity<ProjectEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .set(target, {etag});
  }
  public unsetProjectEdmAsProject(key: EntityKey<ProjectProjectStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ProjectEdm>>, etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .unset({etag});
  }
  public projectStatus(key: EntityKey<ProjectProjectStatusEdm>): ODataNavigationPropertyResource<ProjectStatusEdm> { 
    return this.entity(key).navigationProperty<ProjectStatusEdm>('projectStatus'); 
  }
  public fetchProjectStatus(key: EntityKey<ProjectProjectStatusEdm>, options?: ODataQueryArgumentsOptions<ProjectStatusEdm>) {
    return this.fetchNavigationProperty<ProjectStatusEdm>(
      this.projectStatus(key), 
      'entity', options) as Observable<ODataEntity<ProjectStatusEdm>>;
  }
  public setProjectStatusEdmAsProjectStatus(key: EntityKey<ProjectProjectStatusEdm>, target: ODataEntityResource<ODataEntity<ProjectStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectStatus(key).reference()
      .set(target, {etag});
  }
  public unsetProjectStatusEdmAsProjectStatus(key: EntityKey<ProjectProjectStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ProjectStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectStatus(key).reference()
      .unset({etag});
  }
  //#endregion
}
