//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectListEdm } from './projectlistedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProjectListEdmComplexConfig = {
  name: 'ProjectListEdm',
  fields: {
    projectGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    code: {type: 'Edm.String', nullable: false},
    status: {type: 'Edm.String', maxLength: -1},
    customer: {type: 'Edm.String'},
    jobCount: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<ProjectListEdm>;
//#endregion