//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonAssetStatusEdm } from './personassetstatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonAssetStatusEdmEntityConfig = {
  name: 'PersonAssetStatusEdm',
  keys: [{name: 'personAssetStatusGuid'}],
  fields: {
    personAssetStatusGuid: {type: 'Edm.Guid', nullable: false},
    assetStatusGuid: {type: 'Edm.Guid'},
    assetGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    updatedBy: {type: 'Edm.Guid'},
    asset: {type: 'PaPlusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'assetGuid', referencedProperty: 'assetGuid'}]},
    assetStatus: {type: 'PaPlusApi.DataDomain.EFEntities.AssetStatusEdm', navigation: true, referentials: [{property: 'assetStatusGuid', referencedProperty: 'assetStatusGuid'}]},
    person: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    updatedByPerson: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'updatedBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonAssetStatusEdm>;
//#endregion