//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './jobassetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobAssetEdmEntityConfig = {
  name: 'JobAssetEdm',
  keys: [{name: 'jobAssetGuid'}],
  fields: {
    jobAssetGuid: {type: 'Edm.Guid', nullable: false},
    jobParentAssetGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    serialNumber: {type: 'Edm.String', maxLength: -1},
    notes: {type: 'Edm.String', nullable: false, maxLength: -1},
    abbreviatedDescription: {type: 'Edm.String'},
    metaData: {type: 'Edm.String', maxLength: -1},
    tag: {type: 'Edm.String'},
    groupInUi: {type: 'Edm.Boolean'},
    fileNamePart: {type: 'Edm.String'},
    iconGuid: {type: 'Edm.Guid'},
    icon: {type: 'paplusApi.DataDomain.EFEntities.IconEdm', navigation: true, referentials: [{property: 'iconGuid', referencedProperty: 'iconGuid'}]},
    jobParentAssetGuidJobAsset: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobParentAssetGuid', referencedProperty: 'jobAssetGuid'}]},
    jobAssetMedias: {type: 'paplusApi.DataDomain.EFEntities.JobAssetMediaEdm', collection: true, navigation: true},
    jobAssetParentAssets: {type: 'paplusApi.DataDomain.EFEntities.JobAssetParentAssetEdm', collection: true, navigation: true},
    jobAssetTemplates: {type: 'paplusApi.DataDomain.EFEntities.JobAssetTemplateEdm', collection: true, navigation: true},
    jobParentAssetGuidJobAssets: {type: 'paplusApi.DataDomain.EFEntities.JobAssetEdm', collection: true, navigation: true},
    jobParentAssetGuidJobAssetParentAssets: {type: 'paplusApi.DataDomain.EFEntities.JobAssetParentAssetEdm', collection: true, navigation: true},
    mediaContributorJobAssets: {type: 'paplusApi.DataDomain.EFEntities.MediaContributorJobAssetEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<JobAssetEdm>;
//#endregion