//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateStatusesService } from './persontemplatestatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonTemplateStatusesServiceEntitySetConfig = {
  name: 'PersonTemplateStatuses',
  entityType: 'paplusApi.DataDomain.EFEntities.PersonTemplateStatusEdm',
  service: PersonTemplateStatusesService
} as EntitySetConfig;
//#endregion