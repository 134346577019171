//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetOuEdm } from './assetouedm.entity';
import { JobEdm } from './jobedm.entity';
import { OuAddressEdm } from './ouaddressedm.entity';
import { OuCloseoutPackageEdm } from './oucloseoutpackageedm.entity';
import { OuTemplateEdm } from './outemplateedm.entity';
import { PersonOuRoleEdm } from './personouroleedm.entity';
import { ProjectEdm } from './projectedm.entity';
import { RoleEdm } from './roleedm.entity';
//#endregion

export interface OuEdm {
  //#region ODataApiGen Properties
  ouGuid?: string;
  parentOuGuid?: string;
  description?: string;
  phoneNumber?: string;
  webAddress?: string;
  parentOu?: OuEdm;
  assetOus?: AssetOuEdm[];
  jobs?: JobEdm[];
  ouAddresses?: OuAddressEdm[];
  ouCloseoutPackages?: OuCloseoutPackageEdm[];
  ouGuidProjects?: ProjectEdm[];
  ouGuidRoles?: RoleEdm[];
  ouTemplates?: OuTemplateEdm[];
  parentOus?: OuEdm[];
  personOuRoles?: PersonOuRoleEdm[];
  //#endregion
}