//#region ODataApiGen ODataImports
import {
  NgModule
} from '@angular/core';//#endregion

//#region ODataApiGen Imports
import { ContainerService } from './PaPlusApi/container.service';
import { AddressesService } from './PaPlusApi/addresses.service';
import { AssetsService } from './PaPlusApi/assets.service';
import { AssetMediasService } from './PaPlusApi/assetmedias.service';
import { AssetOusService } from './PaPlusApi/assetous.service';
import { AssetParentAssetsService } from './PaPlusApi/assetparentassets.service';
import { AssetStatusesService } from './PaPlusApi/assetstatuses.service';
import { AssetTemplatesService } from './PaPlusApi/assettemplates.service';
import { CapturesService } from './PaPlusApi/captures.service';
import { CloseoutPackagesService } from './PaPlusApi/closeoutpackages.service';
import { DevicesService } from './PaPlusApi/devices.service';
import { IconsService } from './PaPlusApi/icons.service';
import { JobsService } from './PaPlusApi/jobs.service';
import { JobAddressesService } from './PaPlusApi/jobaddresses.service';
import { JobAssetsService } from './PaPlusApi/jobassets.service';
import { JobAssetMediasService } from './PaPlusApi/jobassetmedias.service';
import { JobAssetParentAssetsService } from './PaPlusApi/jobassetparentassets.service';
import { JobAssetTemplatesService } from './PaPlusApi/jobassettemplates.service';
import { JobTemplatesService } from './PaPlusApi/jobtemplates.service';
import { MediaContributorsService } from './PaPlusApi/mediacontributors.service';
import { MediaContributorJobAssetsService } from './PaPlusApi/mediacontributorjobassets.service';
import { MediaStatusesService } from './PaPlusApi/mediastatuses.service';
import { NotificationTemplatesService } from './PaPlusApi/notificationtemplates.service';
import { OusService } from './PaPlusApi/ous.service';
import { OuAddressesService } from './PaPlusApi/ouaddresses.service';
import { OuCloseoutPackagesService } from './PaPlusApi/oucloseoutpackages.service';
import { OuTemplatesService } from './PaPlusApi/outemplates.service';
import { PermissionsService } from './PaPlusApi/permissions.service';
import { PeopleService } from './PaPlusApi/people.service';
import { PersonAssetStatusesService } from './PaPlusApi/personassetstatuses.service';
import { PersonCaptureMediaStatusesService } from './PaPlusApi/personcapturemediastatuses.service';
import { PersonDevicesService } from './PaPlusApi/persondevices.service';
import { PersonJobsService } from './PaPlusApi/personjobs.service';
import { PersonMediaContributorsService } from './PaPlusApi/personmediacontributors.service';
import { PersonMediaStatusesService } from './PaPlusApi/personmediastatuses.service';
import { PersonOuRolesService } from './PaPlusApi/personouroles.service';
import { PersonTemplateStatusesService } from './PaPlusApi/persontemplatestatuses.service';
import { ProjectsService } from './PaPlusApi/projects.service';
import { ProjectPersonRolesService } from './PaPlusApi/projectpersonroles.service';
import { ProjectProjectStatusesService } from './PaPlusApi/projectprojectstatuses.service';
import { ProjectStatusesService } from './PaPlusApi/projectstatuses.service';
import { RolesService } from './PaPlusApi/roles.service';
import { RolePermissionsService } from './PaPlusApi/rolepermissions.service';
import { TemplatesService } from './PaPlusApi/templates.service';
import { TemplateStatusesService } from './PaPlusApi/templatestatuses.service';
import { PersonTemplateInfosDtoService } from './PaPlusApi/persontemplateinfosdto.service';
//#endregion

@NgModule({
  providers: [
//#region ODataApiGen Providers
    ContainerService,
    AddressesService,
    AssetsService,
    AssetMediasService,
    AssetOusService,
    AssetParentAssetsService,
    AssetStatusesService,
    AssetTemplatesService,
    CapturesService,
    CloseoutPackagesService,
    DevicesService,
    IconsService,
    JobsService,
    JobAddressesService,
    JobAssetsService,
    JobAssetMediasService,
    JobAssetParentAssetsService,
    JobAssetTemplatesService,
    JobTemplatesService,
    MediaContributorsService,
    MediaContributorJobAssetsService,
    MediaStatusesService,
    NotificationTemplatesService,
    OusService,
    OuAddressesService,
    OuCloseoutPackagesService,
    OuTemplatesService,
    PermissionsService,
    PeopleService,
    PersonAssetStatusesService,
    PersonCaptureMediaStatusesService,
    PersonDevicesService,
    PersonJobsService,
    PersonMediaContributorsService,
    PersonMediaStatusesService,
    PersonOuRolesService,
    PersonTemplateStatusesService,
    ProjectsService,
    ProjectPersonRolesService,
    ProjectProjectStatusesService,
    ProjectStatusesService,
    RolesService,
    RolePermissionsService,
    TemplatesService,
    TemplateStatusesService,
    PersonTemplateInfosDtoService//#endregion
  ]
})
export class PAPlusOdataModule { }