//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssignedJobsListEdmComplexConfig } from './assignedjobslistedm.complex.config';
import { JobsListEdmComplexConfig } from './jobslistedm.complex.config';
import { OuListEdmComplexConfig } from './oulistedm.complex.config';
import { ProjectListEdmComplexConfig } from './projectlistedm.complex.config';
import { AddressEdmEntityConfig } from './addressedm.entity.config';
import { AssetEdmEntityConfig } from './assetedm.entity.config';
import { AssetMediaEdmEntityConfig } from './assetmediaedm.entity.config';
import { AssetOuEdmEntityConfig } from './assetouedm.entity.config';
import { AssetParentAssetEdmEntityConfig } from './assetparentassetedm.entity.config';
import { AssetStatusEdmEntityConfig } from './assetstatusedm.entity.config';
import { AssetTemplateEdmEntityConfig } from './assettemplateedm.entity.config';
import { CaptureEdmEntityConfig } from './captureedm.entity.config';
import { CloseoutPackageEdmEntityConfig } from './closeoutpackageedm.entity.config';
import { DeviceEdmEntityConfig } from './deviceedm.entity.config';
import { IconEdmEntityConfig } from './iconedm.entity.config';
import { JobEdmEntityConfig } from './jobedm.entity.config';
import { JobAddressEdmEntityConfig } from './jobaddressedm.entity.config';
import { JobAssetEdmEntityConfig } from './jobassetedm.entity.config';
import { JobAssetMediaEdmEntityConfig } from './jobassetmediaedm.entity.config';
import { JobAssetParentAssetEdmEntityConfig } from './jobassetparentassetedm.entity.config';
import { JobAssetTemplateEdmEntityConfig } from './jobassettemplateedm.entity.config';
import { JobTemplateEdmEntityConfig } from './jobtemplateedm.entity.config';
import { MediaContributorEdmEntityConfig } from './mediacontributoredm.entity.config';
import { MediaContributorJobAssetEdmEntityConfig } from './mediacontributorjobassetedm.entity.config';
import { MediaStatusEdmEntityConfig } from './mediastatusedm.entity.config';
import { NotificationTemplateEdmEntityConfig } from './notificationtemplateedm.entity.config';
import { OuEdmEntityConfig } from './ouedm.entity.config';
import { OuAddressEdmEntityConfig } from './ouaddressedm.entity.config';
import { OuCloseoutPackageEdmEntityConfig } from './oucloseoutpackageedm.entity.config';
import { OuTemplateEdmEntityConfig } from './outemplateedm.entity.config';
import { PermissionEdmEntityConfig } from './permissionedm.entity.config';
import { PersonEdmEntityConfig } from './personedm.entity.config';
import { PersonAssetStatusEdmEntityConfig } from './personassetstatusedm.entity.config';
import { PersonCaptureMediaStatusEdmEntityConfig } from './personcapturemediastatusedm.entity.config';
import { PersonDeviceEdmEntityConfig } from './persondeviceedm.entity.config';
import { PersonJobEdmEntityConfig } from './personjobedm.entity.config';
import { PersonMediaContributorEdmEntityConfig } from './personmediacontributoredm.entity.config';
import { PersonMediaStatusEdmEntityConfig } from './personmediastatusedm.entity.config';
import { PersonOuRoleEdmEntityConfig } from './personouroleedm.entity.config';
import { PersonTemplateStatusEdmEntityConfig } from './persontemplatestatusedm.entity.config';
import { ProjectEdmEntityConfig } from './projectedm.entity.config';
import { ProjectPersonRoleEdmEntityConfig } from './projectpersonroleedm.entity.config';
import { ProjectProjectStatusEdmEntityConfig } from './projectprojectstatusedm.entity.config';
import { ProjectStatusEdmEntityConfig } from './projectstatusedm.entity.config';
import { RoleEdmEntityConfig } from './roleedm.entity.config';
import { RolePermissionEdmEntityConfig } from './rolepermissionedm.entity.config';
import { TemplateEdmEntityConfig } from './templateedm.entity.config';
import { TemplateStatusEdmEntityConfig } from './templatestatusedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const EFEntitiesSchema = {
  namespace: 'PaPlusApi.DataDomain.EFEntities',
  enums: [],
  entities: [AssignedJobsListEdmComplexConfig,
    JobsListEdmComplexConfig,
    OuListEdmComplexConfig,
    ProjectListEdmComplexConfig,
    AddressEdmEntityConfig,
    AssetEdmEntityConfig,
    AssetMediaEdmEntityConfig,
    AssetOuEdmEntityConfig,
    AssetParentAssetEdmEntityConfig,
    AssetStatusEdmEntityConfig,
    AssetTemplateEdmEntityConfig,
    CaptureEdmEntityConfig,
    CloseoutPackageEdmEntityConfig,
    DeviceEdmEntityConfig,
    IconEdmEntityConfig,
    JobEdmEntityConfig,
    JobAddressEdmEntityConfig,
    JobAssetEdmEntityConfig,
    JobAssetMediaEdmEntityConfig,
    JobAssetParentAssetEdmEntityConfig,
    JobAssetTemplateEdmEntityConfig,
    JobTemplateEdmEntityConfig,
    MediaContributorEdmEntityConfig,
    MediaContributorJobAssetEdmEntityConfig,
    MediaStatusEdmEntityConfig,
    NotificationTemplateEdmEntityConfig,
    OuEdmEntityConfig,
    OuAddressEdmEntityConfig,
    OuCloseoutPackageEdmEntityConfig,
    OuTemplateEdmEntityConfig,
    PermissionEdmEntityConfig,
    PersonEdmEntityConfig,
    PersonAssetStatusEdmEntityConfig,
    PersonCaptureMediaStatusEdmEntityConfig,
    PersonDeviceEdmEntityConfig,
    PersonJobEdmEntityConfig,
    PersonMediaContributorEdmEntityConfig,
    PersonMediaStatusEdmEntityConfig,
    PersonOuRoleEdmEntityConfig,
    PersonTemplateStatusEdmEntityConfig,
    ProjectEdmEntityConfig,
    ProjectPersonRoleEdmEntityConfig,
    ProjectProjectStatusEdmEntityConfig,
    ProjectStatusEdmEntityConfig,
    RoleEdmEntityConfig,
    RolePermissionEdmEntityConfig,
    TemplateEdmEntityConfig,
    TemplateStatusEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion