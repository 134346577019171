<ion-app [class.dark-theme]="dark">
  <app-main-header [headerMenus]="headerLink" [hidden]="!loggedIn"></app-main-header>
  <ion-split-pane when="sm" contentId="main-content" *ngIf="loggedIn">
    <ion-menu contentId="main-content" type="overlay" *ngIf="loggedIn">
      <ion-content>
        <h1>&nbsp;<b *ngIf="isMenuOpen">Menu</b></h1>

        <ion-toolbar class="menu-space">
          <ion-list id="pas-list">

            <ion-menu-toggle autoHide="false" *ngFor="let p of appPages">
              <ion-item *ngIf="canReadPage(p)" [routerLink]="[p.url]" (click)="updateTabs(p.tab,p.TABS)"
                #rla="routerLinkActive" [routerLinkActive]="['selected']" routerDirection="root" lines="none"
                detail="false" class="sidemenu-ion" [ngClass]="isMenuOpen?'':'sideRemovePad'">

                <ion-img slot="start" [src]="rla.isActive? p.iconActive : p.icon"></ion-img>
                <ion-label *ngIf="isMenuOpen">{{p.title | translate}}</ion-label>

              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-toolbar>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <!--<ion-router-outlet *ngIf="!loggedIn" id="main-content"></ion-router-outlet>-->

</ion-app>