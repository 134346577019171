//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaStatusEdm } from './mediastatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MediaStatusEdmEntityConfig = {
  name: 'MediaStatusEdm',
  keys: [{name: 'mediaStatusGuid'}],
  fields: {
    mediaStatusGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    isAccepted: {type: 'Edm.Boolean'},
    enumVal: {type: 'Edm.Int32'},
    personMediaStatuses: {type: 'paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<MediaStatusEdm>;
//#endregion