import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './DataDomain/EFEntities/permissionedm.entity';
import { RolePermissionEdm } from './DataDomain/EFEntities/rolepermissionedm.entity';
//#endregion

@Injectable()
export class PermissionsService extends ODataEntitySetService<PermissionEdm> {
  constructor(client: ODataClient) {
    super(client, 'Permissions', 'paplusApi.DataDomain.EFEntities.PermissionEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public rolePermissions(key: EntityKey<PermissionEdm>): ODataNavigationPropertyResource<RolePermissionEdm> { 
    return this.entity(key).navigationProperty<RolePermissionEdm>('rolePermissions'); 
  }
  public fetchRolePermissions(key: EntityKey<PermissionEdm>, options?: ODataQueryArgumentsOptions<RolePermissionEdm>) {
    return this.fetchNavigationProperty<RolePermissionEdm>(
      this.rolePermissions(key), 
      'entities', options) as Observable<ODataEntities<RolePermissionEdm>>;
  }
  public addRolePermissionEdmToRolePermissions(key: EntityKey<PermissionEdm>, target: ODataEntityResource<ODataEntities<RolePermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.rolePermissions(key).reference()
      .add(target);
  }
  public removeRolePermissionEdmFromRolePermissions(key: EntityKey<PermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RolePermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.rolePermissions(key).reference()
      .remove(target);
  }
  //#endregion
}
