export enum GlobalEvents {
  PAGE_TABS = 'pageTabs',
  USER_LOGIN = 'userLogin',
  USER_LOGOUT = 'userLogout',
  UPDATEJOB = "updateJob",
  UPDATELANGUAGES = "updateJobLanguage",
  UPDATEJOBMODE = "updateJobMode",
  UPDATEJOBEditMODE = "updateJobEditMode",
  OPENCLOSEMENU = "openandclosemenu",
  WINDOWRESIZE = "windowresize",
  JOBINVALIDECODE = "jobInvalideCode",

}
