//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetOusService } from './assetous.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetOusServiceEntitySetConfig = {
  name: 'AssetOus',
  entityType: 'PaPlusApi.DataDomain.EFEntities.AssetOuEdm',
  service: AssetOusService
} as EntitySetConfig;
//#endregion