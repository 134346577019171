//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetStatusesService } from './assetstatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetStatusesServiceEntitySetConfig = {
  name: 'AssetStatuses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.AssetStatusEdm',
  service: AssetStatusesService
} as EntitySetConfig;
//#endregion