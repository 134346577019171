//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RolePermissionEdm } from './rolepermissionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RolePermissionEdmEntityConfig = {
  name: 'RolePermissionEdm',
  keys: [{name: 'permissionGuid'},{name: 'roleGuid'}],
  fields: {
    permissionGuid: {type: 'Edm.Guid'},
    roleGuid: {type: 'Edm.Guid'},
    permission: {type: 'PaPlusApi.DataDomain.EFEntities.PermissionEdm', navigation: true, referentials: [{property: 'permissionGuid', referencedProperty: 'permissionGuid'}]},
    role: {type: 'PaPlusApi.DataDomain.EFEntities.RoleEdm', navigation: true, referentials: [{property: 'roleGuid', referencedProperty: 'roleGuid'}]}
  }
} as StructuredTypeConfig<RolePermissionEdm>;
//#endregion