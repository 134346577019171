//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './captureedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CaptureEdmEntityConfig = {
  name: 'CaptureEdm',
  keys: [{name: 'captureGuid'}],
  fields: {
    captureGuid: {type: 'Edm.Guid', nullable: false},
    jobAssetMediaGuid: {type: 'Edm.Guid'},
    createUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    notes: {type: 'Edm.String', maxLength: -1},
    mediaSource: {type: 'Edm.Int32'},
    filePath: {type: 'Edm.String', nullable: false},
    nativeFileName: {type: 'Edm.String'},
    uploadedBy: {type: 'Edm.Guid'},
    latitude: {type: 'Edm.Double'},
    longitude: {type: 'Edm.Double'},
    exifMetadata: {type: 'Edm.String', maxLength: -1},
    jobAssetMedia: {type: 'PaPlusApi.DataDomain.EFEntities.JobAssetMediaEdm', navigation: true, referentials: [{property: 'jobAssetMediaGuid', referencedProperty: 'jobAssetMediaGuid'}]},
    uploadedByPerson: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'uploadedBy', referencedProperty: 'personGuid'}]},
    personCaptureMediaStatuses: {type: 'PaPlusApi.DataDomain.EFEntities.PersonCaptureMediaStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CaptureEdm>;
//#endregion