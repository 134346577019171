//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RolePermissionEdm } from './rolepermissionedm.entity';
//#endregion

export interface PermissionEdm {
  //#region ODataApiGen Properties
  permissionGuid: string;
  description?: string;
  tag?: string;
  active?: boolean;
  rolePermissions?: RolePermissionEdm[];
  //#endregion
}