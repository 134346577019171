//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetStatusEdm } from './assetstatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetStatusEdmEntityConfig = {
  name: 'AssetStatusEdm',
  keys: [{name: 'assetStatusGuid'}],
  fields: {
    assetStatusGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    showInUi: {type: 'Edm.Boolean', nullable: false},
    active: {type: 'Edm.Boolean', nullable: false},
    personAssetStatuses: {type: 'paplusApi.DataDomain.EFEntities.PersonAssetStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AssetStatusEdm>;
//#endregion