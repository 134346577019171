import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CloseoutPackageEdm } from './DataDomain/EFEntities/closeoutpackageedm.entity';
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { OuCloseoutPackageEdm } from './DataDomain/EFEntities/oucloseoutpackageedm.entity';
//#endregion

@Injectable()
export class OuCloseoutPackagesService extends ODataEntitySetService<OuCloseoutPackageEdm> {
  constructor(client: ODataClient) {
    super(client, 'OuCloseoutPackages', 'PaPlusApi.DataDomain.EFEntities.OuCloseoutPackageEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public closeoutPackage(key: EntityKey<OuCloseoutPackageEdm>): ODataNavigationPropertyResource<CloseoutPackageEdm> { 
    return this.entity(key).navigationProperty<CloseoutPackageEdm>('closeoutPackage'); 
  }
  public fetchCloseoutPackage(key: EntityKey<OuCloseoutPackageEdm>, options?: ODataQueryArgumentsOptions<CloseoutPackageEdm>) {
    return this.fetchNavigationProperty<CloseoutPackageEdm>(
      this.closeoutPackage(key), 
      'entity', options) as Observable<ODataEntity<CloseoutPackageEdm>>;
  }
  public setCloseoutPackageEdmAsCloseoutPackage(key: EntityKey<OuCloseoutPackageEdm>, target: ODataEntityResource<ODataEntity<CloseoutPackageEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.closeoutPackage(key).reference()
      .set(target, {etag});
  }
  public unsetCloseoutPackageEdmAsCloseoutPackage(key: EntityKey<OuCloseoutPackageEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CloseoutPackageEdm>>, etag?: string} = {}): Observable<any> {
    return this.closeoutPackage(key).reference()
      .unset({etag});
  }
  public ou(key: EntityKey<OuCloseoutPackageEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<OuCloseoutPackageEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<OuCloseoutPackageEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<OuCloseoutPackageEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  //#endregion
}
