import { TAB_CUSTOMER_PAGES } from "./tab_pages_list";

//Set Tab sidebar here
export const TAB_PAGES: any[] = [
  {
    title: 'sidebar.jobs',
    tab: 'jobs',
    icon: 'assets/img/job_icon.png',
    iconActive: 'assets/img/job_icon_white.png',
    inSidemenu: true,
    inTabBar: false,
    showTabBar: false,
    permission: '_readJobs'
    //Assign Tab object for this page
    //TABS: undefined//Object.assign(new Array(), TAB_PAGES_HOME)
  },
  {
    title: 'sidebar.projects',
    tab: 'projects',
    icon: 'assets/img/project_icon.png',
    iconActive: 'assets/img/project_icon_white.png',
    inSidemenu: true,
    inTabBar: false,
    showTabBar: false,
    permission: '_readProject',
    //Assign Tab object for this page
    TABS: undefined//Object.assign(new Array(), TAB_PAGES_HOME)
  },
  {
    title: 'sidebar.users',
    tab: 'users',
    icon: 'assets/img/user_icon.png',
    iconActive: 'assets/img/user_icon_white.png',
    inSidemenu: true,
    inTabBar: false,
    showTabBar: false,
    permission: '_readUsers',
    //Assign Tab object for this page
    TABS: undefined//Object.assign(new Array(), TAB_PAGES_HOME)
  },
  {
    title: 'sidebar.customers',
    tab: 'customers',
    icon: 'assets/img/customer_icon.png',
    iconActive: 'assets/img/customer_icon_white.png',
    inSidemenu: true,
    inTabBar: false,
    showTabBar: false,
    permission: '_readCustomers'
    //Assign Tab object for this page
    //TABS: Object.assign(new Array(), TAB_CUSTOMER_PAGES)
  },
  {
    title: 'sidebar.admin',
    tab: 'admin',
    icon: 'assets/img/admin.png',
    iconActive: 'assets/img/admin_white.png',
    inSidemenu: true,
    inTabBar: false,
    showTabBar: false,
    permission: '_readAssets'
    //Assign Tab object for this page
    //TABS: Object.assign(new Array(), TAB_CUSTOMER_PAGES)
  },
];


//Reference object sample how to create sidemenu and tabs
//{
//  title: 'Home',
//  tab: 'home',
//  icon: 'home',
//  inSidemenu: true,
//  inTabBar: false,
//  showTabBar: true,
//  //Assign Tab object for this page
//  TABS: Object.assign(new Array(), TAB_PAGES_HOME)
//},
