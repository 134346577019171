//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonJobEdm } from './personjobedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonJobEdmEntityConfig = {
  name: 'PersonJobEdm',
  keys: [{name: 'personJobGuid'}],
  fields: {
    personJobGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    jobGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    job: {type: 'PaPlusApi.DataDomain.EFEntities.JobEdm', navigation: true, referentials: [{property: 'jobGuid', referencedProperty: 'jobGuid'}]},
    person: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonJobEdm>;
//#endregion