//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './personedm.entity';
import { TemplateEdm } from './templateedm.entity';
import { TemplateStatusEdm } from './templatestatusedm.entity';
//#endregion

export interface PersonTemplateStatusEdm {
  //#region ODataApiGen Properties
  personTemplateStatusGuid?: string;
  templateStatusGuid?: string;
  templateGuid?: string;
  personGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  updatedBy?: string;
  person?: PersonEdm;
  template?: TemplateEdm;
  templateStatus?: TemplateStatusEdm;
  updatedByPerson?: PersonEdm;
  //#endregion
}