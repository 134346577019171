//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureDtoComplexConfig } from './capturedto.complex.config';
import { ContainerContainer } from './container.container';
//#endregion

//#region ODataApiGen SchemaConfig
export const PaplusApiSchema = {
  namespace: 'paplusApi',
  enums: [],
  entities: [CaptureDtoComplexConfig],
  callables: [{
    name: 'JobsLists',
    bound: false,
    composable: false,
    return: { type: 'paplusApi.DataDomain.EFEntities.JobsListEdm', collection: false }
  }, {
    name: 'OuLists',
    bound: false,
    composable: false,
    return: { type: 'paplusApi.DataDomain.EFEntities.OuListEdm', collection: false }
  }, {
    name: 'ProjectLists',
    bound: false,
    composable: false,
    return: { type: 'paplusApi.DataDomain.EFEntities.ProjectListEdm', collection: false }
  }, {
    name: 'myTemplates',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'paplusApi.DataDomain.EFEntities.TemplateEdm', collection: true}, personGuid: {type: 'Edm.String'}, roleGuid: {type: 'Edm.String'}, filter: {type: 'Edm.String'}, skip: {type: 'Edm.Int32', nullable: false}, take: {type: 'Edm.Int32', nullable: false}, sortColumn: {type: 'Edm.String'}, sortOrder: {type: 'Edm.String'} },
    return: { type: 'paplusApi.DataDomain.EFEntities.PersonTemplateInfoEdm', collection: true }
  }, {
    name: 'templateClone',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'paplusApi.DataDomain.EFEntities.TemplateEdm'}, personGuid: {type: 'Edm.String'}, ouGuid: {type: 'Edm.String'} },
    return: { type: 'Edm.String', collection: false }
  }, {
    name: 'CapturesWithImage',
    bound: true,
    composable: false,
    parameters: { bindingParameter: {type: 'paplusApi.DataDomain.EFEntities.CaptureEdm'} },
    return: { type: 'paplusApi.CaptureDto', collection: false }
  }],
  containers: [ContainerContainer]
} as SchemaConfig;
//#endregion