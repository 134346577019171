import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './DataDomain/EFEntities/jobedm.entity';
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { ProjectEdm } from './DataDomain/EFEntities/projectedm.entity';
import { ProjectPersonRoleEdm } from './DataDomain/EFEntities/projectpersonroleedm.entity';
import { ProjectProjectStatusEdm } from './DataDomain/EFEntities/projectprojectstatusedm.entity';
//#endregion

@Injectable()
export class ProjectsService extends ODataEntitySetService<ProjectEdm> {
  constructor(client: ODataClient) {
    super(client, 'Projects', 'PaPlusApi.DataDomain.EFEntities.ProjectEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobs(key: EntityKey<ProjectEdm>): ODataNavigationPropertyResource<JobEdm> { 
    return this.entity(key).navigationProperty<JobEdm>('jobs'); 
  }
  public fetchJobs(key: EntityKey<ProjectEdm>, options?: ODataQueryArgumentsOptions<JobEdm>) {
    return this.fetchNavigationProperty<JobEdm>(
      this.jobs(key), 
      'entities', options) as Observable<ODataEntities<JobEdm>>;
  }
  public addJobEdmToJobs(key: EntityKey<ProjectEdm>, target: ODataEntityResource<ODataEntities<JobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobs(key).reference()
      .add(target);
  }
  public removeJobEdmFromJobs(key: EntityKey<ProjectEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobs(key).reference()
      .remove(target);
  }
  public ou(key: EntityKey<ProjectEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<ProjectEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<ProjectEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<ProjectEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  public projectPersonRoles(key: EntityKey<ProjectEdm>): ODataNavigationPropertyResource<ProjectPersonRoleEdm> { 
    return this.entity(key).navigationProperty<ProjectPersonRoleEdm>('projectPersonRoles'); 
  }
  public fetchProjectPersonRoles(key: EntityKey<ProjectEdm>, options?: ODataQueryArgumentsOptions<ProjectPersonRoleEdm>) {
    return this.fetchNavigationProperty<ProjectPersonRoleEdm>(
      this.projectPersonRoles(key), 
      'entities', options) as Observable<ODataEntities<ProjectPersonRoleEdm>>;
  }
  public addProjectPersonRoleEdmToProjectPersonRoles(key: EntityKey<ProjectEdm>, target: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .add(target);
  }
  public removeProjectPersonRoleEdmFromProjectPersonRoles(key: EntityKey<ProjectEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .remove(target);
  }
  public projectProjectStatuses(key: EntityKey<ProjectEdm>): ODataNavigationPropertyResource<ProjectProjectStatusEdm> { 
    return this.entity(key).navigationProperty<ProjectProjectStatusEdm>('projectProjectStatuses'); 
  }
  public fetchProjectProjectStatuses(key: EntityKey<ProjectEdm>, options?: ODataQueryArgumentsOptions<ProjectProjectStatusEdm>) {
    return this.fetchNavigationProperty<ProjectProjectStatusEdm>(
      this.projectProjectStatuses(key), 
      'entities', options) as Observable<ODataEntities<ProjectProjectStatusEdm>>;
  }
  public addProjectProjectStatusEdmToProjectProjectStatuses(key: EntityKey<ProjectEdm>, target: ODataEntityResource<ODataEntities<ProjectProjectStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectProjectStatuses(key).reference()
      .add(target);
  }
  public removeProjectProjectStatusEdmFromProjectProjectStatuses(key: EntityKey<ProjectEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectProjectStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectProjectStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
