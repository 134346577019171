import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { JobAddressEdm } from './DataDomain/EFEntities/jobaddressedm.entity';
import { OuAddressEdm } from './DataDomain/EFEntities/ouaddressedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
//#endregion

@Injectable()
export class AddressesService extends ODataEntitySetService<AddressEdm> {
  constructor(client: ODataClient) {
    super(client, 'Addresses', 'paplusApi.DataDomain.EFEntities.AddressEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public jobAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<JobAddressEdm> { 
    return this.entity(key).navigationProperty<JobAddressEdm>('jobAddresses'); 
  }
  public fetchJobAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<JobAddressEdm>) {
    return this.fetchNavigationProperty<JobAddressEdm>(
      this.jobAddresses(key), 
      'entities', options) as Observable<ODataEntities<JobAddressEdm>>;
  }
  public addJobAddressEdmToJobAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<JobAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAddresses(key).reference()
      .add(target);
  }
  public removeJobAddressEdmFromJobAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAddresses(key).reference()
      .remove(target);
  }
  public ouAddresses(key: EntityKey<AddressEdm>): ODataNavigationPropertyResource<OuAddressEdm> { 
    return this.entity(key).navigationProperty<OuAddressEdm>('ouAddresses'); 
  }
  public fetchOuAddresses(key: EntityKey<AddressEdm>, options?: ODataQueryArgumentsOptions<OuAddressEdm>) {
    return this.fetchNavigationProperty<OuAddressEdm>(
      this.ouAddresses(key), 
      'entities', options) as Observable<ODataEntities<OuAddressEdm>>;
  }
  public addOuAddressEdmToOuAddresses(key: EntityKey<AddressEdm>, target: ODataEntityResource<ODataEntities<OuAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouAddresses(key).reference()
      .add(target);
  }
  public removeOuAddressEdmFromOuAddresses(key: EntityKey<AddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouAddresses(key).reference()
      .remove(target);
  }
  //#endregion
}
