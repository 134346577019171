//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuAddressesService } from './ouaddresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OuAddressesServiceEntitySetConfig = {
  name: 'OuAddresses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.OuAddressEdm',
  service: OuAddressesService
} as EntitySetConfig;
//#endregion