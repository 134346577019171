//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureDTO } from './capturedto.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CaptureDTOComplexConfig = {
  name: 'CaptureDTO',
  fields: {
    captureGuid: {type: 'Edm.Guid', nullable: false},
    jobAssetMediaGuid: {type: 'Edm.Guid', nullable: false},
    createUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    notes: {type: 'Edm.String', nullable: false},
    mediaSource: {type: 'Edm.Int32'},
    filePath: {type: 'Edm.String', nullable: false},
    nativeFileName: {type: 'Edm.String', nullable: false},
    imageData: {type: 'Edm.Binary'},
    contentType: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<CaptureDTO>;
//#endregion