//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetMediaEdm } from './assetmediaedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetMediaEdmEntityConfig = {
  name: 'AssetMediaEdm',
  keys: [{name: 'assetMediaGuid'}],
  fields: {
    assetMediaGuid: {type: 'Edm.Guid', nullable: false},
    assetGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', maxLength: -1},
    abbreviatedDescription: {type: 'Edm.String', nullable: false},
    fileNamePart: {type: 'Edm.String', nullable: false},
    type: {type: 'Edm.Int32'},
    min: {type: 'Edm.Int32'},
    max: {type: 'Edm.Int32'},
    comment: {type: 'Edm.String', maxLength: -1},
    asset: {type: 'PaPlusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'assetGuid', referencedProperty: 'assetGuid'}]}
  }
} as StructuredTypeConfig<AssetMediaEdm>;
//#endregion