import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { JobAssetEdm, JobAssetMediaEdm, JobEdm } from "@sharedLibrary/odata";
import { AssetCounterService } from "@sharedLibrary/services/asset-counter.service";


@Component({
  selector: 'lib-asset-media-set',
  templateUrl: './asset-media-set.component.html',
  styleUrls: ['./asset-media-set.component.scss'],
})
export class AssetMediaSetComponent implements OnInit, OnChanges {

  @Input() job: JobEdm;
  @Input() jobGuid: string;
  @Input() jobAsset: JobAssetEdm;
  
  public childAssets: JobAssetEdm[] = [];
  public mediaList: JobAssetMediaEdm[];
  jobAssetUpdated: any = false;

  constructor(
    private assetCounterService: AssetCounterService
  ) { }
  
  ngOnInit() {
    
    if (this.jobAsset) {
      this.childAssets = this.jobAsset.childAssets;
      this.mediaList = this.jobAsset.jobAssetMedias;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes in ASSET MEDIA COMPONENT', changes);
    // console.log('job ASSET MEDIA set has been UPDATED, CHANGES: ', changes);
    
    // const canUpdateJob: boolean = !changes['jobAsset'].firstChange && changes['jobAsset'].currentValue && changes['jobAsset'].currentValue.jobTemplates.length > 0;
    // if (canUpdateJob) {
    //   console.log('job ASSET MEDIA set has been UPDATED second change, CHANGES: ', changes);
    //   this.jobAssetUpdated = true;
    //   // this.jobTemplate = this.job.jobTemplates[0];
    //   // this.jobTemplate.jobAssetTemplates.forEach(jat => {
    //     //   if (jat.jobAsset.jobParentAssetGuid == null) {
    //       //     this.jobAssets.push(jat.jobAsset);
    //       //   }
    //       // });
    // }
  }

  fillAssetCounter(asset: JobAssetEdm) {    
    // console.log('fillAssetCounter ASSET MEDIA: asset updated ->', this.jobAssetUpdated);
    return this.assetCounterService.fillAssetCounter(asset);
  }
}
