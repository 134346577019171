//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectPersonRolesService } from './projectpersonroles.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProjectPersonRolesServiceEntitySetConfig = {
  name: 'ProjectPersonRoles',
  entityType: 'PaPlusApi.DataDomain.EFEntities.ProjectPersonRoleEdm',
  service: ProjectPersonRolesService
} as EntitySetConfig;
//#endregion