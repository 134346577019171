import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { ProjectEdm } from './DataDomain/EFEntities/projectedm.entity';
import { ProjectPersonRoleEdm } from './DataDomain/EFEntities/projectpersonroleedm.entity';
import { RoleEdm } from './DataDomain/EFEntities/roleedm.entity';
//#endregion

@Injectable()
export class ProjectPersonRolesService extends ODataEntitySetService<ProjectPersonRoleEdm> {
  constructor(client: ODataClient) {
    super(client, 'ProjectPersonRoles', 'PaPlusApi.DataDomain.EFEntities.ProjectPersonRoleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public person(key: EntityKey<ProjectPersonRoleEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<ProjectPersonRoleEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<ProjectPersonRoleEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<ProjectPersonRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public project(key: EntityKey<ProjectPersonRoleEdm>): ODataNavigationPropertyResource<ProjectEdm> { 
    return this.entity(key).navigationProperty<ProjectEdm>('project'); 
  }
  public fetchProject(key: EntityKey<ProjectPersonRoleEdm>, options?: ODataQueryArgumentsOptions<ProjectEdm>) {
    return this.fetchNavigationProperty<ProjectEdm>(
      this.project(key), 
      'entity', options) as Observable<ODataEntity<ProjectEdm>>;
  }
  public setProjectEdmAsProject(key: EntityKey<ProjectPersonRoleEdm>, target: ODataEntityResource<ODataEntity<ProjectEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .set(target, {etag});
  }
  public unsetProjectEdmAsProject(key: EntityKey<ProjectPersonRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ProjectEdm>>, etag?: string} = {}): Observable<any> {
    return this.project(key).reference()
      .unset({etag});
  }
  public role(key: EntityKey<ProjectPersonRoleEdm>): ODataNavigationPropertyResource<RoleEdm> { 
    return this.entity(key).navigationProperty<RoleEdm>('role'); 
  }
  public fetchRole(key: EntityKey<ProjectPersonRoleEdm>, options?: ODataQueryArgumentsOptions<RoleEdm>) {
    return this.fetchNavigationProperty<RoleEdm>(
      this.role(key), 
      'entity', options) as Observable<ODataEntity<RoleEdm>>;
  }
  public setRoleEdmAsRole(key: EntityKey<ProjectPersonRoleEdm>, target: ODataEntityResource<ODataEntity<RoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .set(target, {etag});
  }
  public unsetRoleEdmAsRole(key: EntityKey<ProjectPersonRoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<RoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.role(key).reference()
      .unset({etag});
  }
  //#endregion
}
