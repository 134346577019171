import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent, MsalModule, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { MsalGuard } from './aadb2cauth/msal.guard';
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";
import { PAPlusPublicClientApplication } from './aadb2cauth/paplus-public-client-application';

import { loginRequest, msalConfig, protectedResources } from './aadb2cauth/auth-config';
import { RequestInterceptor } from './shared/services/interceptor.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { ApiService } from './shared/services/api.service';
import { PAPlusOdataModule } from '@app/odata/paplus-odata.module';
import { PAPlusOdataConfig } from '@app/odata';
import { ODataModule } from 'angular-odata';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PAPlusPublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.backendApi.endpoint, protectedResources.backendApi.scopes);
return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function createTranslateLoader(http: HttpClient) {

  const path = window.location.origin + '/assets/i18n/';
  return new TranslateHttpLoader(http, path, '.json');
}


@NgModule({
  declarations: [AppComponent  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule ,
    IonicModule.forRoot(), 
    AppRoutingModule,
    PAPlusOdataModule,
    FormsModule,
    MsalModule,
    ODataModule.forRoot({ config: PAPlusOdataConfig }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  providers: [
    { 
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: LOCALE_ID,
      useValue: "en-GB"
    },
    provideHttpClient(withInterceptorsFromDi()),
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ApiService
   

  ],
  
})
export class AppModule {}
