//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
//#endregion

export interface AssetParentAssetEdm {
  //#region ODataApiGen Properties
  assetGuid?: string;
  parentAssetGuid?: string;
  asset?: AssetEdm;
  parentAsset?: AssetEdm;
  //#endregion
}