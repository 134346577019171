import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { OuAddressEdm } from './DataDomain/EFEntities/ouaddressedm.entity';
//#endregion

@Injectable()
export class OuAddressesService extends ODataEntitySetService<OuAddressEdm> {
  constructor(client: ODataClient) {
    super(client, 'OuAddresses', 'PaPlusApi.DataDomain.EFEntities.OuAddressEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public address(key: EntityKey<OuAddressEdm>): ODataNavigationPropertyResource<AddressEdm> { 
    return this.entity(key).navigationProperty<AddressEdm>('address'); 
  }
  public fetchAddress(key: EntityKey<OuAddressEdm>, options?: ODataQueryArgumentsOptions<AddressEdm>) {
    return this.fetchNavigationProperty<AddressEdm>(
      this.address(key), 
      'entity', options) as Observable<ODataEntity<AddressEdm>>;
  }
  public setAddressEdmAsAddress(key: EntityKey<OuAddressEdm>, target: ODataEntityResource<ODataEntity<AddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .set(target, {etag});
  }
  public unsetAddressEdmAsAddress(key: EntityKey<OuAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.address(key).reference()
      .unset({etag});
  }
  public ou(key: EntityKey<OuAddressEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ou'); 
  }
  public fetchOu(key: EntityKey<OuAddressEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ou(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOu(key: EntityKey<OuAddressEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOu(key: EntityKey<OuAddressEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ou(key).reference()
      .unset({etag});
  }
  //#endregion
}
