//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetTemplateEdm } from './jobassettemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobAssetTemplateEdmEntityConfig = {
  name: 'JobAssetTemplateEdm',
  keys: [{name: 'jobAssetTemplateGuid'}],
  fields: {
    jobAssetTemplateGuid: {type: 'Edm.Guid', nullable: false},
    jobTemplateGuid: {type: 'Edm.Guid'},
    jobAssetGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    jobAsset: {type: 'PaPlusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobAssetGuid', referencedProperty: 'jobAssetGuid'}]},
    jobTemplate: {type: 'PaPlusApi.DataDomain.EFEntities.JobTemplateEdm', navigation: true, referentials: [{property: 'jobTemplateGuid', referencedProperty: 'jobTemplateGuid'}]}
  }
} as StructuredTypeConfig<JobAssetTemplateEdm>;
//#endregion