import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetTemplateEdm } from './DataDomain/EFEntities/assettemplateedm.entity';
import { JobTemplateEdm } from './DataDomain/EFEntities/jobtemplateedm.entity';
import { OuTemplateEdm } from './DataDomain/EFEntities/outemplateedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonTemplateStatusEdm } from './DataDomain/EFEntities/persontemplatestatusedm.entity';
import { TemplateEdm } from './DataDomain/EFEntities/templateedm.entity';
import { PersonTemplateInfoEdm } from './DataDomain/EFEntities/persontemplateinfoedm.entity';
//#endregion

@Injectable()
export class TemplatesService extends ODataEntitySetService<TemplateEdm> {
  constructor(client: ODataClient) {
    super(client, 'Templates', 'paplusApi.DataDomain.EFEntities.TemplateEdm');
  }
  //#region ODataApiGen Actions
  public myTemplates(): ODataActionResource<{personGuid: string, roleGuid: string, filter: string, skip: number, take: number, sortColumn: string, sortOrder: string}, PersonTemplateInfoEdm> { 
    return this.entities().action<{personGuid: string, roleGuid: string, filter: string, skip: number, take: number, sortColumn: string, sortOrder: string}, PersonTemplateInfoEdm>('paplusApi.myTemplates');
  }
  public callMyTemplates(personGuid: string, roleGuid: string, filter: string, skip: number, take: number, sortColumn: string, sortOrder: string, options?: ODataActionOptions<PersonTemplateInfoEdm>) {
    return this.callAction<{personGuid: string, roleGuid: string, filter: string, skip: number, take: number, sortColumn: string, sortOrder: string}, PersonTemplateInfoEdm>(
      {personGuid, roleGuid, filter, skip, take, sortColumn, sortOrder}, 
      this.myTemplates(), 
      'entities', options) as Observable<ODataEntities<PersonTemplateInfoEdm>>;
  }
  public templateClone(key: EntityKey<TemplateEdm>): ODataActionResource<{personGuid: string, ouGuid: string}, string> { 
    return this.entity(key).action<{personGuid: string, ouGuid: string}, string>('paplusApi.templateClone');
  }
  public callTemplateClone(key: EntityKey<TemplateEdm>, personGuid: string, ouGuid: string, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<{personGuid: string, ouGuid: string}, string>(
      {personGuid, ouGuid}, 
      this.templateClone(key), 
      'property', options) as Observable<ODataProperty<string>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public assetTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<AssetTemplateEdm> { 
    return this.entity(key).navigationProperty<AssetTemplateEdm>('assetTemplates'); 
  }
  public fetchAssetTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<AssetTemplateEdm>) {
    return this.fetchNavigationProperty<AssetTemplateEdm>(
      this.assetTemplates(key), 
      'entities', options) as Observable<ODataEntities<AssetTemplateEdm>>;
  }
  public addAssetTemplateEdmToAssetTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .add(target);
  }
  public removeAssetTemplateEdmFromAssetTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetTemplates(key).reference()
      .remove(target);
  }
  public jobTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<JobTemplateEdm> { 
    return this.entity(key).navigationProperty<JobTemplateEdm>('jobTemplates'); 
  }
  public fetchJobTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<JobTemplateEdm>) {
    return this.fetchNavigationProperty<JobTemplateEdm>(
      this.jobTemplates(key), 
      'entities', options) as Observable<ODataEntities<JobTemplateEdm>>;
  }
  public addJobTemplateEdmToJobTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<JobTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .add(target);
  }
  public removeJobTemplateEdmFromJobTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobTemplates(key).reference()
      .remove(target);
  }
  public ouTemplates(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<OuTemplateEdm> { 
    return this.entity(key).navigationProperty<OuTemplateEdm>('ouTemplates'); 
  }
  public fetchOuTemplates(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<OuTemplateEdm>) {
    return this.fetchNavigationProperty<OuTemplateEdm>(
      this.ouTemplates(key), 
      'entities', options) as Observable<ODataEntities<OuTemplateEdm>>;
  }
  public addOuTemplateEdmToOuTemplates(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<OuTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .add(target);
  }
  public removeOuTemplateEdmFromOuTemplates(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .remove(target);
  }
  public person(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public personTemplateStatuses(key: EntityKey<TemplateEdm>): ODataNavigationPropertyResource<PersonTemplateStatusEdm> { 
    return this.entity(key).navigationProperty<PersonTemplateStatusEdm>('personTemplateStatuses'); 
  }
  public fetchPersonTemplateStatuses(key: EntityKey<TemplateEdm>, options?: ODataQueryArgumentsOptions<PersonTemplateStatusEdm>) {
    return this.fetchNavigationProperty<PersonTemplateStatusEdm>(
      this.personTemplateStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonTemplateStatusEdm>>;
  }
  public addPersonTemplateStatusEdmToPersonTemplateStatuses(key: EntityKey<TemplateEdm>, target: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .add(target);
  }
  public removePersonTemplateStatusEdmFromPersonTemplateStatuses(key: EntityKey<TemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
