//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetTemplateEdm } from './assettemplateedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetTemplateEdmEntityConfig = {
  name: 'AssetTemplateEdm',
  keys: [{name: 'assetTemplateGuid'}],
  fields: {
    assetTemplateGuid: {type: 'Edm.Guid', nullable: false},
    templateGuid: {type: 'Edm.Guid'},
    assetGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    asset: {type: 'PaPlusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'assetGuid', referencedProperty: 'assetGuid'}]},
    template: {type: 'PaPlusApi.DataDomain.EFEntities.TemplateEdm', navigation: true, referentials: [{property: 'templateGuid', referencedProperty: 'templateGuid'}]}
  }
} as StructuredTypeConfig<AssetTemplateEdm>;
//#endregion