//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateInfoEdm } from './persontemplateinfoedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonTemplateInfoEdmEntityConfig = {
  name: 'PersonTemplateInfoEdm',
  keys: [{name: 'templateGuid'}],
  fields: {
    templateGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid', nullable: false},
    ouGuid: {type: 'Edm.Guid', nullable: false},
    templateDescription: {type: 'Edm.String'},
    readOnlyTemplate: {type: 'Edm.Boolean'},
    templateStatusGuid: {type: 'Edm.Guid'},
    templateStatusDescription: {type: 'Edm.String'},
    totalSearchCount: {type: 'Edm.Int32', nullable: false},
    assetsCount: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<PersonTemplateInfoEdm>;
//#endregion