//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressesService } from './addresses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AddressesServiceEntitySetConfig = {
  name: 'Addresses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.AddressEdm',
  service: AddressesService
} as EntitySetConfig;
//#endregion