//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { IconEdm } from './iconedm.entity';
import { JobAssetMediaEdm } from './jobassetmediaedm.entity';
import { JobAssetParentAssetEdm } from './jobassetparentassetedm.entity';
import { JobAssetTemplateEdm } from './jobassettemplateedm.entity';
import { MediaContributorJobAssetEdm } from './mediacontributorjobassetedm.entity';
//#endregion

export interface JobAssetEdm {
  //#region ODataApiGen Properties
  jobAssetGuid: string;
  jobParentAssetGuid?: string;
  description: string;
  serialNumber?: string;
  notes: string;
  abbreviatedDescription?: string;
  metaData?: string;
  tag?: string;
  groupInUi?: boolean;
  fileNamePart?: string;
  iconGuid?: string;
  icon?: IconEdm;
  jobParentAssetGuidJobAsset?: JobAssetEdm;
  jobAssetMedias?: JobAssetMediaEdm[];
  jobAssetParentAssets?: JobAssetParentAssetEdm[];
  jobAssetTemplates?: JobAssetTemplateEdm[];
  jobParentAssetGuidJobAssets?: JobAssetEdm[];
  jobParentAssetGuidJobAssetParentAssets?: JobAssetParentAssetEdm[];
  mediaContributorJobAssets?: MediaContributorJobAssetEdm[];
  //#endregion
}