//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './jobedm.entity';
import { OuEdm } from './ouedm.entity';
import { ProjectPersonRoleEdm } from './projectpersonroleedm.entity';
import { ProjectProjectStatusEdm } from './projectprojectstatusedm.entity';
//#endregion

export interface ProjectEdm {
  //#region ODataApiGen Properties
  projectGuid?: string;
  ouGuid?: string;
  description: string;
  code: string;
  externalCode?: string;
  photoExportResolution?: string;
  importTemplatesSerialisedValue?: string;
  notes?: string;
  ou?: OuEdm;
  jobs?: JobEdm[];
  projectPersonRoles?: ProjectPersonRoleEdm[];
  projectProjectStatuses?: ProjectProjectStatusEdm[];
  //#endregion
}