//#region ODataApiGen ODataImports
import {
  ApiConfig,
  EDM_PARSERS
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EFEntitiesSchema } from './paplusApi/DataDomain/EFEntities/efentities.schema';
import { PaplusApiSchema } from './paplusApi/paplusapi.schema';
import { environment } from '@portalenv/environment';
//#endregion

//#region ODataApiGen ApiConfig
export const PAPlusOdataConfig = {
  serviceRootUrl: environment.API,
  name: 'PAPlusOdata',
  version: '4.0',
  creation: new Date('2024-11-26T09:10:27.7708450+00:00'),
  schemas: [
    EFEntitiesSchema,
    PaplusApiSchema
  ],
  parsers: EDM_PARSERS
} as ApiConfig;
//#endregion