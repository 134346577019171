// sentry.service.ts

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { DynamicEnvironmentService } from './dynamic-environment.service';
import { AvailableEnvironment } from '../../environments/available-environments';

@Injectable({ providedIn: 'root' })
export class SentryService {
    private initialized = false;

    constructor(private envService: DynamicEnvironmentService) {
        // Listen for environment changes
        this.envService.environment$.subscribe((envConfig) => {
            // Only update tags/context if Sentry is already initialized
            if (this.initialized ) {
                Sentry.setTag('environment', envConfig.name);
                Sentry.setContext('envConfig', { ...envConfig });

                // Dynamically update the environment
                window['__SENTRY__'].hub.getClient().getOptions().environment = envConfig.BuildEnvironment ?? envService.getProjectType();
            }
        });
    }

    /**
     * Called at app startup (via APP_INITIALIZER) to configure Sentry once.
     */
    public init(): void {
        const currentEnv: any = this.envService.environment;
        const currentEnvName = this.envService.currentEnvrionment;

        if (this.initialized || currentEnvName === AvailableEnvironment.Local) {
            // If called more than once, do nothing or handle re-init logic
            return;
        }

        // Initialize Sentry
        Sentry.init(
            {
                dsn: this.envService.getSentryDns(),
                //release: currentEnv.release, // TODO: Lets see what it does out of the box
                environment: this.envService.currentEnvironmentName ?? currentEnv.getProjectType(),
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText: this.envService.currentEnvrionment === AvailableEnvironment.Prod,
                        blockAllMedia: this.envService.currentEnvrionment === AvailableEnvironment.Prod
                    }),
                ],
                tracesSampleRate: 1.0,
                tracePropagationTargets: [currentEnv.API],
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            },
            // Forward to SentryAngular to enable Angular error handling
            SentryAngular.init
        );

        // Optionally set initial tags/context
        Sentry.setTag('environment', currentEnv.name);
        Sentry.setContext('envConfig', { ...currentEnv });

        this.initialized = true;
    }
}
