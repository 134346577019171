import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, input } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { StringDict } from 'msal/lib-commonjs/MsalTypes';
import { MsalAuthenticationService } from '../../services/msal.authentication.service';
import { Router } from '@angular/router';
import { AccountInfo, AuthenticationResult, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { b2cPolicies } from '../../../aadb2cauth/auth-config';
import { Subject } from 'rxjs';
import { compareStrings } from '../../utils/str';
import { UserInformationClaims } from '../../types/user-information-claims';
import { GlobalEvents } from '../../enum/events-enum';
import { IdTokenClaimsWithPolicyId } from '../../types/id-token-claims-with-policy';
import { PubSubService } from '../../services/pub-sub.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {

  userIsAllowedToSee = false;
  userIsLoggedIn = false;
  isMenuOpen = false;
  userFullName = '';
  language: string = this.translate.currentLang; // 2

  @Output() loginMessage = new EventEmitter<string>();
  @Input({ required: true }) headerMenus: any[];
  private readonly _destroying$ = new Subject<void>();

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
      handler: () => {
        console.log('Alert canceled');
      },
    },
    {
      text: 'OK',
      role: 'confirm',
      handler: (val) => {
        this.language = val;
        // console.log('Alert confirmed',val);
        this.languageChange();
      },
    },
  ];

  public alertInputs = [
    {
      label: 'English',
      type: 'radio',
      value: 'en',
    },
    {
      label: 'Spanish',
      type: 'radio',
      value: 'es',
    },
    {
      label: 'French',
      type: 'radio',
      value: 'fr',
    },
  ];

  constructor(
    protected pubSub: PubSubService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalAuthService: MsalAuthenticationService,
    private router: Router,
    private translate: TranslateService) {
    this.pubSub.subscribe(GlobalEvents.WINDOWRESIZE, (result) => {
      this.isMenuOpen = result[0];
    })
  }

  ngOnInit() {
    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.setLoginDisplay();
    this.setUserInfo();

    this.msalAuthService.subscribeToLoginEvent().subscribe({
      next: (req: RedirectRequest | PopupRequest) => {
        this.login(req);
      }
    });

    this.msalAuthService.subscribeAuthSuccessEvent().subscribe({
      next: (acc: AccountInfo | null) => {
        this.checkAndSetActiveAccount(acc);
      }
    });

    this.msalAuthService.subscribeLoginUpdateEvent().subscribe({
      next: (acc: AccountInfo | null) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount(acc);
      }
    });

    this.msalAuthService.subscribeSsoSilentLoginSubjectEvent().subscribe({
      next: (acc: AccountInfo) => {
        this.trySsoSilentLogin(acc);
      }
    });

  }


  trySsoSilentLogin(originalSignInAccount: AccountInfo) {

    let signInFlowRequest: SsoSilentRequest = {
      authority: b2cPolicies.authorities.customSignUpSignIn.authority,
      account: originalSignInAccount as AccountInfo
    };

    // silently login again with the customSignUpSignIn policy
    this.authService.ssoSilent(signInFlowRequest);
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.checkAndSetActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.checkAndSetActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  checkAndSetActiveAccount(account: AccountInfo | null) {

    if (account) {
      this.authService.instance.setActiveAccount(account);
      this.setUserInfo();
      this.loadSignedInPAPlusUser(account);
    } else {

      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        // add your code for handling multiple accounts here
        // check if there is an impersonated account
        let impersonatedAccount = accounts.find(elem => compareStrings((elem.idTokenClaims as IdTokenClaimsWithPolicyId).acr, b2cPolicies.names.impersonateUser));

        // here set priority for the impersonated account
        if (impersonatedAccount) {
          this.authService.instance.setActiveAccount(impersonatedAccount);
          this.setUserInfo();
          this.loadSignedInPAPlusUser(impersonatedAccount);
        } else {
          this.authService.instance.setActiveAccount(accounts[0]);
          this.setUserInfo();
          this.loadSignedInPAPlusUser(accounts[0]);
        }
      }
    }
  }


  loadSignedInPAPlusUser(activeAccount: AccountInfo | null) {
    if (!activeAccount) {
      activeAccount = this.authService.instance.getActiveAccount();

    }

    if (!activeAccount) {
      return; //TODO Sem: investigate what to do it the current account is null
    }
  }

  setUserInfo() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount !== null) {
      let userInfo = (activeAccount?.idTokenClaims as UserInformationClaims);
      this.userFullName = userInfo.given_name + ' ' + userInfo.family_name;
    }
  }

  setLoginDisplay() {

    //this.userIsAllowedToSee = false;
    this.userIsLoggedIn = false;

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.userIsLoggedIn = true;
     // this.router.navigateByUrl('/app')
       this.pubSub.publish(GlobalEvents.USER_LOGIN, [this.userIsLoggedIn]);

      // //Add an Audit log to record this user logging in
      // let audit: AuditEdm = {

      // }

    } /*else if (allowAnonymousPages.includes(location.pathname)) {
      this.userIsAllowedToSee = true;
    }*/
  }


  throwOutUnauthorisedUser(userType: number | undefined) {
    let contactStr = '';

    alert('You are logged out!');

    this.logout();
  }

  changePassword() {
    let changePasswordRequest: RedirectRequest | PopupRequest = {
      authority: b2cPolicies.authorities.customChangePassword.authority,
      scopes: [],
    };

    this.login(changePasswordRequest);
  }

  logout() {
    let logoutUseractionParam: StringDict = {
      'useraction': 'true'
    };
    this.authService.logout({
      postLogoutRedirectUri: '/logout?useraction=true',
      extraQueryParameters: logoutUseractionParam
    });
  }

  toggleSideMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.pubSub.publish(GlobalEvents.OPENCLOSEMENU, [this.isMenuOpen]);
  }


  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  languageChange() {
    //this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);  // add this
    console.log('Alert confirmed', this.language);
    this.pubSub.publish(GlobalEvents.UPDATELANGUAGES, [this.language]);
  }

}
