/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { PromptState } from 'msal/lib-commonjs/utils/Constants';
import { environment } from '@portalenv/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        customSignUpSignIn: 'B2C_1A_signup_signin',
        customResetPassword: 'B2C_1A_PasswordReset',
        editProfile: 'B2C_1_edit_profile',
        impersonateUser: 'B2C_1A_Impersonation',
        customChangePassword: 'B2C_1A_PasswordChange'
    },
    authorities: {
        customResetPassword: {
            authority: `https://${environment.B2CDomain}.b2clogin.com/${environment.B2CDomain}.onmicrosoft.com/B2C_1A_PasswordReset`,
        },
        editProfile: {
            authority: `https://${environment.B2CDomain}.b2clogin.com/${environment.B2CDomain}.onmicrosoft.com/b2c_1_edit_profile`,
        },
        impersonateUser: {
            authority: `https://${environment.B2CDomain}.b2clogin.com/${environment.B2CDomain}.onmicrosoft.com/B2C_1A_Impersonation`,
        },
        customChangePassword: {
            authority: `https://${environment.B2CDomain}.b2clogin.com/${environment.B2CDomain}.onmicrosoft.com/B2C_1A_PasswordChange`
        },
        customSignUpSignIn: {
            authority: `https://${environment.B2CDomain}.b2clogin.com/${environment.B2CDomain}.onmicrosoft.com/B2C_1A_signup_signin`
        }
    },
    authorityDomain: `${environment.B2CDomain}.b2clogin.com`,
    tenantId: environment.B2CTenantId,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.ClientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.customSignUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/logout', // Points to window.location.origin by default.,
        navigateToLoginRequestUrl: true
    },
    cache: {
        //cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        cacheLocation: BrowserCacheLocation.SessionStorage,
        //storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
        storeAuthStateInCookie: false
    },
    system: {
        /**
         * Below you can configure MSAL.js logs. For more information, visit:
         * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
         */
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            //logLevel: LogLevel.Verbose,
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
}

 /**
  * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
  */
 export const protectedResources = {
    backendApi: {
        endpoint: environment.API,
        scopes: [
            `https://${environment.B2CDomain}.onmicrosoft.com/${environment.BackendAppIdentifier}/BackendApi.Read.All`,
            `https://${environment.B2CDomain}.onmicrosoft.com/${environment.BackendAppIdentifier}/BackendApi.Write.All`]
    }
  }

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
    prompt: PromptState.SELECT_ACCOUNT
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "loginHint" property (such as a username). For more, visit:
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-different-apps
 * If you do not receive the username claim in ID tokens, see also:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#why-is-getaccountbyusername-returning-null-even-though-im-signed-in
 */
export const silentRequest = {
    scopes: [],
    loginHint: "example@domain.net",
    prompt: PromptState.SELECT_ACCOUNT
};