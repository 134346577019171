//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CloseoutPackageEdm } from './closeoutpackageedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CloseoutPackageEdmEntityConfig = {
  name: 'CloseoutPackageEdm',
  keys: [{name: 'closeoutPackageGuid'}],
  fields: {
    closeoutPackageGuid: {type: 'Edm.Guid', nullable: false},
    fileFormat: {type: 'Edm.Int32'},
    reportDefFileName: {type: 'Edm.String'},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    script: {type: 'Edm.String', maxLength: -1},
    ouCloseoutPackages: {type: 'PaPlusApi.DataDomain.EFEntities.OuCloseoutPackageEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CloseoutPackageEdm>;
//#endregion