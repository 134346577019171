import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@portalenv/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.API;//'https://api.example.com';

  constructor(private http: HttpClient) { }

  getData(endpoint:string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${endpoint}`);
  }

  postData(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/data`, payload);
  }
}
