//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectsService } from './projects.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProjectsServiceEntitySetConfig = {
  name: 'Projects',
  entityType: 'PaPlusApi.DataDomain.EFEntities.ProjectEdm',
  service: ProjectsService
} as EntitySetConfig;
//#endregion