//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateInfoDtoEntityConfig } from './persontemplateinfodto.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const DTOsSchema = {
  namespace: 'PaPlusApi.DataDomain.DTOs',
  enums: [],
  entities: [PersonTemplateInfoDtoEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion