//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
import { AssetStatusEdm } from './assetstatusedm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface PersonAssetStatusEdm {
  //#region ODataApiGen Properties
  personAssetStatusGuid: string;
  assetStatusGuid?: string;
  assetGuid?: string;
  personGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  updatedBy?: string;
  asset?: AssetEdm;
  assetStatus?: AssetStatusEdm;
  person?: PersonEdm;
  updatedByPerson?: PersonEdm;
  //#endregion
}