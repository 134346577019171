import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, PromptValue, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from 'src/app/aadb2cauth/auth-config';
import { compareStrings } from '../utils/str';
import { IdTokenClaimsWithPolicyId } from '../types/id-token-claims-with-policy';
import { PubSubService } from './pub-sub.service';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root',
})
export class MsalAuthenticationService {

  private processsLoginSubject = new ReplaySubject<RedirectRequest | PopupRequest>();
  private processSsoSilentLoginSubject = new ReplaySubject<AccountInfo>();
  private processsLoginUpdateSubject = new ReplaySubject<AccountInfo | null>();
  private processsAuthSuccessSubject = new ReplaySubject<AccountInfo | null>();
  private readonly _destroying$ = new Subject<void>();

  constructor(private pubSub: PubSubService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loggerService: LoggerService
  ) { }

  initalize() {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe({
        next: (rslt: EventMessage) => {

          // this is done to return the user back to the login screen, when he cancel the reset password flow
          // this can be done in other similar scenarios. When users cancels the previous operation.
          if (rslt.error) {
            let error = (rslt.error as any);
            if (error.errorMessage) {
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
              if (error.errorMessage.indexOf('AADB2C90118') == -1) {
                let signInFlowRequest: RedirectRequest | PopupRequest = {
                  authority: b2cPolicies.authorities.customSignUpSignIn.authority,
                  prompt: PromptValue.LOGIN,
                  scopes: []
                };

                //TODO: Sem - review this
                //this.login(signInFlowRequest);
                this.processsLoginSubject.next(signInFlowRequest);
              }
            }
          }
        },
        error: (err) => {
          console.log('msalSubject error');
          console.log(err);
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED
          || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {

          //TODO: Sem: broadcast events
          //this.setLoginDisplay();
          //this.checkAndSetActiveAccount(null);
          this.processsLoginUpdateSubject.next(null);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

        //TODO: Sem: broadcast events

        //this.setLoginDisplay();
        //this.checkAndSetActiveAccount(null);
        this.processsLoginUpdateSubject.next(null);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => compareStrings(msg.eventType, EventType.LOGIN_SUCCESS)
          || compareStrings(msg.eventType, EventType.ACQUIRE_TOKEN_SUCCESS)
          || compareStrings(msg.eventType, EventType.SSO_SILENT_SUCCESS)),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (compareStrings(idtoken.acr, b2cPolicies.names.customSignUpSignIn)
          || compareStrings(idtoken.tfp, b2cPolicies.names.customSignUpSignIn)
          || compareStrings(idtoken.acr, b2cPolicies.names.impersonateUser)
          || compareStrings(idtoken.tfp, b2cPolicies.names.impersonateUser)) {

          //TODO: Sem: broadcast events
          //this.checkAndSetActiveAccount(payload.account);
          if (result.eventType === EventType.LOGIN_SUCCESS) {
            this.loggerService.showInfo("Log in successfull");
          }

          this.processsAuthSuccessSubject.next(payload.account);
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (compareStrings(idtoken.acr, b2cPolicies.names.editProfile)
          || compareStrings(idtoken.tfp, b2cPolicies.names.editProfile)) {

          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.authService.instance.getAllAccounts()
            .find((account: AccountInfo) =>
              compareStrings(account.idTokenClaims?.oid, idtoken?.oid)
              && compareStrings(account.idTokenClaims?.sub, idtoken?.sub)
              && (compareStrings((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr, b2cPolicies.names.customSignUpSignIn)
                || compareStrings((account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp, b2cPolicies.names.customSignUpSignIn))
            );

          if (originalSignInAccount) {

            //TODO: Sem: broadcast events

            //this.trySsoSilentLogin(originalSignInAccount);

            this.processSsoSilentLoginSubject.next(originalSignInAccount);
          }
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (compareStrings(idtoken.acr, b2cPolicies.names.customResetPassword)
          || compareStrings(idtoken.tfp, b2cPolicies.names.customResetPassword)) {
          let signInFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.customSignUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };

          //TODO: Sem: broadcast events

          //this.login(signInFlowRequest);

          this.processsLoginSubject.next(signInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => compareStrings(msg.eventType, EventType.LOGIN_FAILURE) || compareStrings(msg.eventType, EventType.ACQUIRE_TOKEN_FAILURE)),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.customResetPassword.authority,
            scopes: [],
          };

          //TODO: Sem: broadcast events

          //this.login(resetPasswordFlowRequest);

          this.processsLoginSubject.next(resetPasswordFlowRequest);
        } else if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {

          let activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount == null) {

            //TODO: Sem: broadcast events

            //this.checkAndSetActiveAccount(activeAccount);

            this.processsAuthSuccessSubject.next(null);
          }

          activeAccount = this.authService.instance.getActiveAccount();
          if (activeAccount == null) {
            let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
              authority: b2cPolicies.authorities.customResetPassword.authority,
              scopes: [],
            };

            //TODO: Sem: broadcast events

            //this.login(resetPasswordFlowRequest);

            this.processsLoginSubject.next(resetPasswordFlowRequest);
          } else {
            //TODO: Sem: broadcast events

            //this.trySsoSilentLogin(activeAccount as AccountInfo);

            this.processSsoSilentLoginSubject.next(activeAccount as AccountInfo);
          }
        };
      });
  }

  subscribeToLoginEvent(): Observable<RedirectRequest | PopupRequest> {
    return this.processsLoginSubject.asObservable();
  }

  subscribeLoginUpdateEvent(): Observable<AccountInfo | null> {
    return this.processsLoginUpdateSubject.asObservable();
  }

  subscribeAuthSuccessEvent(): Observable<AccountInfo | null> {
    return this.processsAuthSuccessSubject.asObservable();
  }

  subscribeSsoSilentLoginSubjectEvent(): Observable<AccountInfo> {
    return this.processSsoSilentLoginSubject.asObservable();
  }
}
