import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './DataDomain/EFEntities/captureedm.entity';
import { JobAssetMediaEdm } from './DataDomain/EFEntities/jobassetmediaedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonCaptureMediaStatusEdm } from './DataDomain/EFEntities/personcapturemediastatusedm.entity';
import { CaptureDto } from './capturedto.complex';
//#endregion

@Injectable()
export class CapturesService extends ODataEntitySetService<CaptureEdm> {
  constructor(client: ODataClient) {
    super(client, 'Captures', 'PaPlusApi.DataDomain.EFEntities.CaptureEdm');
  }
  //#region ODataApiGen Actions
  public capturesWithImage(key: EntityKey<CaptureEdm>): ODataActionResource<null, CaptureDto> { 
    return this.entity(key).action<null, CaptureDto>('PaPlusApi.CapturesWithImage');
  }
  public callCapturesWithImage(key: EntityKey<CaptureEdm>, options?: ODataActionOptions<CaptureDto>) {
    return this.callAction<null, CaptureDto>(
      null, 
      this.capturesWithImage(key), 
      'entity', options) as Observable<ODataEntity<CaptureDto>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobAssetMedia(key: EntityKey<CaptureEdm>): ODataNavigationPropertyResource<JobAssetMediaEdm> { 
    return this.entity(key).navigationProperty<JobAssetMediaEdm>('jobAssetMedia'); 
  }
  public fetchJobAssetMedia(key: EntityKey<CaptureEdm>, options?: ODataQueryArgumentsOptions<JobAssetMediaEdm>) {
    return this.fetchNavigationProperty<JobAssetMediaEdm>(
      this.jobAssetMedia(key), 
      'entity', options) as Observable<ODataEntity<JobAssetMediaEdm>>;
  }
  public setJobAssetMediaEdmAsJobAssetMedia(key: EntityKey<CaptureEdm>, target: ODataEntityResource<ODataEntity<JobAssetMediaEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAssetMedia(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetMediaEdmAsJobAssetMedia(key: EntityKey<CaptureEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetMediaEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAssetMedia(key).reference()
      .unset({etag});
  }
  public personCaptureMediaStatuses(key: EntityKey<CaptureEdm>): ODataNavigationPropertyResource<PersonCaptureMediaStatusEdm> { 
    return this.entity(key).navigationProperty<PersonCaptureMediaStatusEdm>('personCaptureMediaStatuses'); 
  }
  public fetchPersonCaptureMediaStatuses(key: EntityKey<CaptureEdm>, options?: ODataQueryArgumentsOptions<PersonCaptureMediaStatusEdm>) {
    return this.fetchNavigationProperty<PersonCaptureMediaStatusEdm>(
      this.personCaptureMediaStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonCaptureMediaStatusEdm>>;
  }
  public addPersonCaptureMediaStatusEdmToPersonCaptureMediaStatuses(key: EntityKey<CaptureEdm>, target: ODataEntityResource<ODataEntities<PersonCaptureMediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personCaptureMediaStatuses(key).reference()
      .add(target);
  }
  public removePersonCaptureMediaStatusEdmFromPersonCaptureMediaStatuses(key: EntityKey<CaptureEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonCaptureMediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personCaptureMediaStatuses(key).reference()
      .remove(target);
  }
  public uploadedByPerson(key: EntityKey<CaptureEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('uploadedByPerson'); 
  }
  public fetchUploadedByPerson(key: EntityKey<CaptureEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.uploadedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsUploadedByPerson(key: EntityKey<CaptureEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.uploadedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsUploadedByPerson(key: EntityKey<CaptureEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.uploadedByPerson(key).reference()
      .unset({etag});
  }
  //#endregion
}
