import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions,
  ODataBaseService
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobsListEdm } from './DataDomain/EFEntities/jobslistedm.complex';
import { OuListEdm } from './DataDomain/EFEntities/oulistedm.complex';
import { ProjectListEdm } from './DataDomain/EFEntities/projectlistedm.complex';
//#endregion

@Injectable()
export class ContainerService extends ODataBaseService {

  constructor(client: ODataClient) {
    super(client, 'ContainerContainer', 'PAPlus');
  }

  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  public jobsLists(): ODataFunctionResource<null, JobsListEdm> { 
    return this.client.function<null, JobsListEdm>('paplusApi.JobsLists', this.apiNameOrEntityType);
  }
  public callJobsLists(options?: ODataFunctionOptions<JobsListEdm>) {
    return this.callFunction<null, JobsListEdm>(
      null, 
      this.jobsLists(), 
      'entity', options) as Observable<ODataEntity<JobsListEdm>>;
  }
  public ouLists(): ODataFunctionResource<null, OuListEdm> { 
    return this.client.function<null, OuListEdm>('paplusApi.OuLists', this.apiNameOrEntityType);
  }
  public callOuLists(options?: ODataFunctionOptions<OuListEdm>) {
    return this.callFunction<null, OuListEdm>(
      null, 
      this.ouLists(), 
      'entity', options) as Observable<ODataEntity<OuListEdm>>;
  }
  public projectLists(): ODataFunctionResource<null, ProjectListEdm> { 
    return this.client.function<null, ProjectListEdm>('paplusApi.ProjectLists', this.apiNameOrEntityType);
  }
  public callProjectLists(options?: ODataFunctionOptions<ProjectListEdm>) {
    return this.callFunction<null, ProjectListEdm>(
      null, 
      this.projectLists(), 
      'entity', options) as Observable<ODataEntity<ProjectListEdm>>;
  }
  //#endregion
}