//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonMediaContributorEdm } from './personmediacontributoredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonMediaContributorEdmEntityConfig = {
  name: 'PersonMediaContributorEdm',
  keys: [{name: 'personMediaContributorGuid'}],
  fields: {
    personMediaContributorGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    mediaContributorGuid: {type: 'Edm.Guid'},
    mediaContributor: {type: 'PaPlusApi.DataDomain.EFEntities.MediaContributorEdm', navigation: true, referentials: [{property: 'mediaContributorGuid', referencedProperty: 'mediaContributorGuid'}]},
    person: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonMediaContributorEdm>;
//#endregion