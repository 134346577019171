import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DeviceEdm } from './DataDomain/EFEntities/deviceedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonDeviceEdm } from './DataDomain/EFEntities/persondeviceedm.entity';
//#endregion

@Injectable()
export class PersonDevicesService extends ODataEntitySetService<PersonDeviceEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonDevices', 'PaPlusApi.DataDomain.EFEntities.PersonDeviceEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public device(key: EntityKey<PersonDeviceEdm>): ODataNavigationPropertyResource<DeviceEdm> { 
    return this.entity(key).navigationProperty<DeviceEdm>('device'); 
  }
  public fetchDevice(key: EntityKey<PersonDeviceEdm>, options?: ODataQueryArgumentsOptions<DeviceEdm>) {
    return this.fetchNavigationProperty<DeviceEdm>(
      this.device(key), 
      'entity', options) as Observable<ODataEntity<DeviceEdm>>;
  }
  public setDeviceEdmAsDevice(key: EntityKey<PersonDeviceEdm>, target: ODataEntityResource<ODataEntity<DeviceEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.device(key).reference()
      .set(target, {etag});
  }
  public unsetDeviceEdmAsDevice(key: EntityKey<PersonDeviceEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<DeviceEdm>>, etag?: string} = {}): Observable<any> {
    return this.device(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonDeviceEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonDeviceEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonDeviceEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonDeviceEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
