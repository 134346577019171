//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorEdm } from './mediacontributoredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MediaContributorEdmEntityConfig = {
  name: 'MediaContributorEdm',
  keys: [{name: 'mediaContributorGuid'}],
  fields: {
    mediaContributorGuid: {type: 'Edm.Guid', nullable: false},
    emailAddress: {type: 'Edm.String', nullable: false},
    phoneNumber: {type: 'Edm.String', nullable: false},
    firstName: {type: 'Edm.String', nullable: false},
    lastName: {type: 'Edm.String', nullable: false},
    mediaContributorJobAssets: {type: 'PaPlusApi.DataDomain.EFEntities.MediaContributorJobAssetEdm', collection: true, navigation: true},
    personMediaContributors: {type: 'PaPlusApi.DataDomain.EFEntities.PersonMediaContributorEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<MediaContributorEdm>;
//#endregion