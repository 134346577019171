import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { AssetParentAssetEdm } from './DataDomain/EFEntities/assetparentassetedm.entity';
//#endregion

@Injectable()
export class AssetParentAssetsService extends ODataEntitySetService<AssetParentAssetEdm> {
  constructor(client: ODataClient) {
    super(client, 'AssetParentAssets', 'paplusApi.DataDomain.EFEntities.AssetParentAssetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public asset(key: EntityKey<AssetParentAssetEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('asset'); 
  }
  public fetchAsset(key: EntityKey<AssetParentAssetEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.asset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsAsset(key: EntityKey<AssetParentAssetEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsAsset(key: EntityKey<AssetParentAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .unset({etag});
  }
  public parentAsset(key: EntityKey<AssetParentAssetEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('parentAsset'); 
  }
  public fetchParentAsset(key: EntityKey<AssetParentAssetEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.parentAsset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsParentAsset(key: EntityKey<AssetParentAssetEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentAsset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsParentAsset(key: EntityKey<AssetParentAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentAsset(key).reference()
      .unset({etag});
  }
  //#endregion
}
