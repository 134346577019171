import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaStatusEdm } from './DataDomain/EFEntities/mediastatusedm.entity';
import { PersonCaptureMediaStatusEdm } from './DataDomain/EFEntities/personcapturemediastatusedm.entity';
import { PersonMediaStatusEdm } from './DataDomain/EFEntities/personmediastatusedm.entity';
//#endregion

@Injectable()
export class MediaStatusesService extends ODataEntitySetService<MediaStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'MediaStatuses', 'PaPlusApi.DataDomain.EFEntities.MediaStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public personCaptureMediaStatuses(key: EntityKey<MediaStatusEdm>): ODataNavigationPropertyResource<PersonCaptureMediaStatusEdm> { 
    return this.entity(key).navigationProperty<PersonCaptureMediaStatusEdm>('personCaptureMediaStatuses'); 
  }
  public fetchPersonCaptureMediaStatuses(key: EntityKey<MediaStatusEdm>, options?: ODataQueryArgumentsOptions<PersonCaptureMediaStatusEdm>) {
    return this.fetchNavigationProperty<PersonCaptureMediaStatusEdm>(
      this.personCaptureMediaStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonCaptureMediaStatusEdm>>;
  }
  public addPersonCaptureMediaStatusEdmToPersonCaptureMediaStatuses(key: EntityKey<MediaStatusEdm>, target: ODataEntityResource<ODataEntities<PersonCaptureMediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personCaptureMediaStatuses(key).reference()
      .add(target);
  }
  public removePersonCaptureMediaStatusEdmFromPersonCaptureMediaStatuses(key: EntityKey<MediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonCaptureMediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personCaptureMediaStatuses(key).reference()
      .remove(target);
  }
  public personMediaStatuses(key: EntityKey<MediaStatusEdm>): ODataNavigationPropertyResource<PersonMediaStatusEdm> { 
    return this.entity(key).navigationProperty<PersonMediaStatusEdm>('personMediaStatuses'); 
  }
  public fetchPersonMediaStatuses(key: EntityKey<MediaStatusEdm>, options?: ODataQueryArgumentsOptions<PersonMediaStatusEdm>) {
    return this.fetchNavigationProperty<PersonMediaStatusEdm>(
      this.personMediaStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonMediaStatusEdm>>;
  }
  public addPersonMediaStatusEdmToPersonMediaStatuses(key: EntityKey<MediaStatusEdm>, target: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .add(target);
  }
  public removePersonMediaStatusEdmFromPersonMediaStatuses(key: EntityKey<MediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
