//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetOuEdm } from './assetouedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetOuEdmEntityConfig = {
  name: 'AssetOuEdm',
  keys: [{name: 'assetGuid'},{name: 'ouGuid'}],
  fields: {
    ouGuid: {type: 'Edm.Guid'},
    assetGuid: {type: 'Edm.Guid'},
    asset: {type: 'PaPlusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'assetGuid', referencedProperty: 'assetGuid'}]},
    ou: {type: 'PaPlusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouGuid', referencedProperty: 'ouGuid'}]}
  }
} as StructuredTypeConfig<AssetOuEdm>;
//#endregion