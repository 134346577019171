//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetMediaEdm } from './assetmediaedm.entity';
import { AssetOuEdm } from './assetouedm.entity';
import { AssetParentAssetEdm } from './assetparentassetedm.entity';
import { AssetTemplateEdm } from './assettemplateedm.entity';
import { IconEdm } from './iconedm.entity';
import { PersonAssetStatusEdm } from './personassetstatusedm.entity';
//#endregion

export interface AssetEdm {
  //#region ODataApiGen Properties
  assetGuid?: string;
  description: string;
  serialNumber?: string;
  notes: string;
  abbreviatedDescription?: string;
  metaData?: string;
  tag?: string;
  groupInUi?: boolean;
  fileNamePart?: string;
  iconGuid?: string;
  hide?: boolean;
  icon?: IconEdm;
  assetMedias?: AssetMediaEdm[];
  assetOus?: AssetOuEdm[];
  assetParentAssets?: AssetParentAssetEdm[];
  assetTemplates?: AssetTemplateEdm[];
  parentAssetParentAssets?: AssetParentAssetEdm[];
  personAssetStatuses?: PersonAssetStatusEdm[];
  //#endregion
}