//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetMediaEdm } from './jobassetmediaedm.entity';
import { MediaStatusEdm } from './mediastatusedm.entity';
import { PersonEdm } from './personedm.entity';
//#endregion

export interface PersonMediaStatusEdm {
  //#region ODataApiGen Properties
  personMediaStatusGuid?: string;
  personGuid?: string;
  jobAssetMediaGuid?: string;
  mediaStatusGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  notes?: string;
  jobAssetMedia?: JobAssetMediaEdm;
  mediaStatus?: MediaStatusEdm;
  person?: PersonEdm;
  //#endregion
}