//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './ouedm.entity';
import { TemplateEdm } from './templateedm.entity';
//#endregion

export interface OuTemplateEdm {
  //#region ODataApiGen Properties
  ouTemplateGuid?: string;
  ouGuid?: string;
  templateGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  ou?: OuEdm;
  template?: TemplateEdm;
  //#endregion
}