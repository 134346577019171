import { Injectable } from "@angular/core";
import { JobAssetMediaEdm, MediaStatusEdm, PersonMediaStatusEdm } from "@odata";
import { ODataServiceFactory } from "angular-odata";
import { BehaviorSubject, map, Observable } from "rxjs";
import { BaseODataService } from "./base-odata.service";


@Injectable({
  providedIn: 'root'
})
export class JobAssetMediaService {
  private selectedJobAssetMedia: BehaviorSubject<JobAssetMediaEdm | null> = new BehaviorSubject<JobAssetMediaEdm | null>(null);
  public selectedJobAssetMedia$ = this.selectedJobAssetMedia.asObservable();

  constructor(private factory: ODataServiceFactory) { }

  updateSelectedJobAssetMedia(jobAssetMedia: JobAssetMediaEdm) {
    this.selectedJobAssetMedia.next(jobAssetMedia);
  }

  getMediaStatuses(): Observable<MediaStatusEdm[]> {
    let statusService = this.factory.entitySet<MediaStatusEdm>(
      "MediaStatuses",
      "paplusApi.DataDomain.EFEntities.MediaStatusEdm"
    );

    let statuses = statusService.entities();
    return statuses.fetch().pipe(
      map(res => res.entities == null ? [] : res.entities)
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class PersonMediaStatusService extends BaseODataService<PersonMediaStatusEdm> {
  constructor(factory: ODataServiceFactory) {
    super(factory, {
      name: "PersonMediaStatuses",
      entityType: "paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm"
    });
  }

  createPersonMediaStatus(pms: PersonMediaStatusEdm): Observable<PersonMediaStatusEdm> {
    return this.create(pms);
  }

  updatePersonMediaStatus(pms: PersonMediaStatusEdm): Observable<PersonMediaStatusEdm> {
    let pmsService = this.factory.entitySet<PersonMediaStatusEdm>(
      "PersonMediaStatuses",
      "paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm"
    );

    let pmsToUpdate = { ...pms };

    if (pmsToUpdate.mediaStatus) {
      delete pmsToUpdate.mediaStatus;
    }
    if (pmsToUpdate.jobAssetMedia) {
      delete pmsToUpdate.jobAssetMedia;
    }
    if (pmsToUpdate.person) {
      delete pmsToUpdate.person;
    }

    return pmsService.update(pms.personMediaStatusGuid, pms).pipe(
      map(res => res.entity)
    );
  }

  getPersonMediaStatuses(jobAssetMediaGuid: string) {
    let pmsService = this.factory.entitySet<PersonMediaStatusEdm>(
      "PersonMediaStatuses",
      "paplusApi.DataDomain.EFEntities.PersonMediaStatusEdm"
    );

    let activityLogs = pmsService.entities();

    activityLogs.query((q) => q.expand({
      person: {},
      mediaStatus: {},
      //filter: 'ToUTCDateTime eq null'
    }));

    // showing all statuses here, not only the latest, filkter by date is not needed
    // ToUTCDateTime eq null and 
    activityLogs.query((q) => q.filter("JobAssetMediaGuid eq " + jobAssetMediaGuid));

    return activityLogs.fetch().pipe(
      map(res => res.entities == null ? [] : res.entities)
    );
  }
}