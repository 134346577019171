//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetMediaEdm } from './jobassetmediaedm.entity';
//#endregion

export interface CaptureEdm {
  //#region ODataApiGen Properties
  captureGuid: string;
  jobAssetMediaGuid?: string;
  createUtcDateTime: Date;
  notes?: string;
  mediaSource?: number;
  filePath: string;
  nativeFileName?: string;
  jobAssetMedia?: JobAssetMediaEdm;

  imageData?: ArrayBuffer;
  contentType?: string;
  //#endregion
}