//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuAddressEdm } from './ouaddressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OuAddressEdmEntityConfig = {
  name: 'OuAddressEdm',
  keys: [{name: 'addressGuid'},{name: 'fromDate'},{name: 'ouGuid'}],
  fields: {
    ouGuid: {type: 'Edm.Guid'},
    addressGuid: {type: 'Edm.Guid'},
    addressType: {type: 'Edm.Int16'},
    manuallyEntered: {type: 'Edm.Boolean'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    address: {type: 'PaPlusApi.DataDomain.EFEntities.AddressEdm', navigation: true, referentials: [{property: 'addressGuid', referencedProperty: 'addressGuid'}]},
    ou: {type: 'PaPlusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouGuid', referencedProperty: 'ouGuid'}]}
  }
} as StructuredTypeConfig<OuAddressEdm>;
//#endregion