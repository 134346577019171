import { PersonEdm } from "@odata";

export function getAllPermissions(person: PersonEdm): string[] {

    let permissions: string[] = new Array();
    if (person) {
        person.personOuRoles?.forEach((personOuRole) => {
            personOuRole.role.rolePermissions?.forEach((rolePermission) => {
                if (rolePermission.permission
                    && rolePermission.permission.tag) {
                    if (rolePermission.permission.active) {
                        permissions.push(rolePermission.permission?.tag.toLowerCase() as string);
                    }
                }
            });
        });
    }
    //console.log("My Permissions:", permissions)
    return permissions;
}

export function hasPermission(person: PersonEdm, permissionTag: string): boolean {
    //console.log("Requesting Permission:", permissionTag);
    let hasPermission = getAllPermissions(person).indexOf(permissionTag.toLowerCase()) >= 0;
    //console.log("hasPermission:", hasPermission.toString());

    return hasPermission;
}