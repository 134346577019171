//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './addressedm.entity';
import { PersonAssetStatusEdm } from './personassetstatusedm.entity';
import { PersonDeviceEdm } from './persondeviceedm.entity';
import { PersonJobEdm } from './personjobedm.entity';
import { PersonMediaContributorEdm } from './personmediacontributoredm.entity';
import { PersonMediaStatusEdm } from './personmediastatusedm.entity';
import { PersonOuRoleEdm } from './personouroleedm.entity';
import { PersonTemplateStatusEdm } from './persontemplatestatusedm.entity';
import { ProjectPersonRoleEdm } from './projectpersonroleedm.entity';
import { TemplateEdm } from './templateedm.entity';
//#endregion

export interface PersonEdm {
  //#region ODataApiGen Properties
  personGuid?: string;
  identityGuid?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  profileImage?: ArrayBuffer;
  locked?: boolean;
  createdByAddresses?: AddressEdm[];
  personAssetStatuses?: PersonAssetStatusEdm[];
  personDevices?: PersonDeviceEdm[];
  personJobs?: PersonJobEdm[];
  personMediaContributors?: PersonMediaContributorEdm[];
  personMediaStatuses?: PersonMediaStatusEdm[];
  personOuRoles?: PersonOuRoleEdm[];
  personTemplateStatuses?: PersonTemplateStatusEdm[];
  projectPersonRoles?: ProjectPersonRoleEdm[];
  reportingPersonOuRoles?: PersonOuRoleEdm[];
  templates?: TemplateEdm[];
  updatedByPersonAssetStatuses?: PersonAssetStatusEdm[];
  updatedByPersonTemplateStatuses?: PersonTemplateStatusEdm[];
  //#endregion
}