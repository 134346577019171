import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonTemplateStatusEdm } from './DataDomain/EFEntities/persontemplatestatusedm.entity';
import { TemplateEdm } from './DataDomain/EFEntities/templateedm.entity';
import { TemplateStatusEdm } from './DataDomain/EFEntities/templatestatusedm.entity';
//#endregion

@Injectable()
export class PersonTemplateStatusesService extends ODataEntitySetService<PersonTemplateStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonTemplateStatuses', 'paplusApi.DataDomain.EFEntities.PersonTemplateStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public person(key: EntityKey<PersonTemplateStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonTemplateStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonTemplateStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonTemplateStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public template(key: EntityKey<PersonTemplateStatusEdm>): ODataNavigationPropertyResource<TemplateEdm> { 
    return this.entity(key).navigationProperty<TemplateEdm>('template'); 
  }
  public fetchTemplate(key: EntityKey<PersonTemplateStatusEdm>, options?: ODataQueryArgumentsOptions<TemplateEdm>) {
    return this.fetchNavigationProperty<TemplateEdm>(
      this.template(key), 
      'entity', options) as Observable<ODataEntity<TemplateEdm>>;
  }
  public setTemplateEdmAsTemplate(key: EntityKey<PersonTemplateStatusEdm>, target: ODataEntityResource<ODataEntity<TemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.template(key).reference()
      .set(target, {etag});
  }
  public unsetTemplateEdmAsTemplate(key: EntityKey<PersonTemplateStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.template(key).reference()
      .unset({etag});
  }
  public templateStatus(key: EntityKey<PersonTemplateStatusEdm>): ODataNavigationPropertyResource<TemplateStatusEdm> { 
    return this.entity(key).navigationProperty<TemplateStatusEdm>('templateStatus'); 
  }
  public fetchTemplateStatus(key: EntityKey<PersonTemplateStatusEdm>, options?: ODataQueryArgumentsOptions<TemplateStatusEdm>) {
    return this.fetchNavigationProperty<TemplateStatusEdm>(
      this.templateStatus(key), 
      'entity', options) as Observable<ODataEntity<TemplateStatusEdm>>;
  }
  public setTemplateStatusEdmAsTemplateStatus(key: EntityKey<PersonTemplateStatusEdm>, target: ODataEntityResource<ODataEntity<TemplateStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.templateStatus(key).reference()
      .set(target, {etag});
  }
  public unsetTemplateStatusEdmAsTemplateStatus(key: EntityKey<PersonTemplateStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TemplateStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.templateStatus(key).reference()
      .unset({etag});
  }
  public updatedByPerson(key: EntityKey<PersonTemplateStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('updatedByPerson'); 
  }
  public fetchUpdatedByPerson(key: EntityKey<PersonTemplateStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.updatedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsUpdatedByPerson(key: EntityKey<PersonTemplateStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.updatedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsUpdatedByPerson(key: EntityKey<PersonTemplateStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.updatedByPerson(key).reference()
      .unset({etag});
  }
  //#endregion
}
