import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './DataDomain/EFEntities/jobassetedm.entity';
import { MediaContributorEdm } from './DataDomain/EFEntities/mediacontributoredm.entity';
import { MediaContributorJobAssetEdm } from './DataDomain/EFEntities/mediacontributorjobassetedm.entity';
//#endregion

@Injectable()
export class MediaContributorJobAssetsService extends ODataEntitySetService<MediaContributorJobAssetEdm> {
  constructor(client: ODataClient) {
    super(client, 'MediaContributorJobAssets', 'paplusApi.DataDomain.EFEntities.MediaContributorJobAssetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobAsset(key: EntityKey<MediaContributorJobAssetEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobAsset'); 
  }
  public fetchJobAsset(key: EntityKey<MediaContributorJobAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobAsset(key), 
      'entity', options) as Observable<ODataEntity<JobAssetEdm>>;
  }
  public setJobAssetEdmAsJobAsset(key: EntityKey<MediaContributorJobAssetEdm>, target: ODataEntityResource<ODataEntity<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetEdmAsJobAsset(key: EntityKey<MediaContributorJobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .unset({etag});
  }
  public mediaContributor(key: EntityKey<MediaContributorJobAssetEdm>): ODataNavigationPropertyResource<MediaContributorEdm> { 
    return this.entity(key).navigationProperty<MediaContributorEdm>('mediaContributor'); 
  }
  public fetchMediaContributor(key: EntityKey<MediaContributorJobAssetEdm>, options?: ODataQueryArgumentsOptions<MediaContributorEdm>) {
    return this.fetchNavigationProperty<MediaContributorEdm>(
      this.mediaContributor(key), 
      'entity', options) as Observable<ODataEntity<MediaContributorEdm>>;
  }
  public setMediaContributorEdmAsMediaContributor(key: EntityKey<MediaContributorJobAssetEdm>, target: ODataEntityResource<ODataEntity<MediaContributorEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaContributor(key).reference()
      .set(target, {etag});
  }
  public unsetMediaContributorEdmAsMediaContributor(key: EntityKey<MediaContributorJobAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MediaContributorEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaContributor(key).reference()
      .unset({etag});
  }
  //#endregion
}
