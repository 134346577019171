//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonAssetStatusesService } from './personassetstatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonAssetStatusesServiceEntitySetConfig = {
  name: 'PersonAssetStatuses',
  entityType: 'paplusApi.DataDomain.EFEntities.PersonAssetStatusEdm',
  service: PersonAssetStatusesService
} as EntitySetConfig;
//#endregion