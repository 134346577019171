import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ODataClient, ODataProperty, ODataServiceFactory } from "angular-odata";
import { PeopleService, PersonEdm } from "./../../odata";

@Injectable({
    providedIn: 'root'
})
export class PAPlusUserAccountService {
    constructor(private http: HttpClient,
        private factory: ODataServiceFactory,
        private peopleService: PeopleService) {

    }

    private paplusUserSubject = new ReplaySubject<PersonEdm>();
    private paplusCurrentUser: PersonEdm;
    private userDetailsAreLoading: boolean = false;

    retrievePaPlusUser(identityGuid: string): Observable<PersonEdm> {

        if (this.paplusCurrentUser) {
            //console.log("User Subject:", this.paplusCurrentUser);
            return of(this.paplusCurrentUser);
        }

        if (!this.userDetailsAreLoading) {
            this.getCurrentUserByIdentityGuid(identityGuid).subscribe((res) => {
                this.paplusCurrentUser = res;
                //console.log("Db User:", res);
                this.callIPAddressAPI().then(ipAddress => {
                    //this.createLoginAudit(res, ipAddress);
                });
                this.paplusUserSubject.next(res);
                this.userDetailsAreLoading = false;
                
            });
            this.userDetailsAreLoading = true;
        }

        return this.paplusUserSubject.asObservable();
    }

    private getCurrentUserByIdentityGuid(identityGuid: string): Observable<PersonEdm> {

        let peopleService = this.factory.entitySet<PersonEdm>(
            'People',
            'paplusApi.DataDomain.EFEntities.PersonEdm'
        );
        let person = peopleService.entities();

        person.query(q => q.expand({
            personOuRoles: {
                expand: {
                    role: {
                        expand: {
                            rolePermissions: {
                                expand: {
                                     permission: {}
                                }
                            }
                        }
                    }
                }
            }
        }));

        person.query(q => q.filter('identityGuid eq ' + identityGuid));
        return person.fetchOne().pipe(
            tap(usr => this.paplusCurrentUser = usr.entity as PersonEdm),
            map(res =>
                res.entity as PersonEdm
            )
        );
    }

    callIPAddressAPI(): Promise<string> {
        return this.getIpAddress()
            .then((data: any) => data.ip)
            .catch((error: any) => {
                console.error('Error fetching IP address', error);
                return '';
            });
    }

    getIpAddress(): Promise<any> {
        return this.http.get('https://api.ipify.org?format=json').toPromise();
    }
}