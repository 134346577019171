//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './ouedm.entity';
import { PersonOuRoleEdm } from './personouroleedm.entity';
import { ProjectPersonRoleEdm } from './projectpersonroleedm.entity';
import { RolePermissionEdm } from './rolepermissionedm.entity';
//#endregion

export interface RoleEdm {
  //#region ODataApiGen Properties
  roleGuid: string;
  ouGuid?: string;
  description: string;
  appRoleTag: string;
  isSystemRole?: boolean;
  roleLevel?: number;
  ou?: OuEdm;
  personOuRoles?: PersonOuRoleEdm[];
  projectPersonRoles?: ProjectPersonRoleEdm[];
  rolePermissions?: RolePermissionEdm[];
  //#endregion
}