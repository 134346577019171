//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetMediaEdm } from './jobassetmediaedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobAssetMediaEdmEntityConfig = {
  name: 'JobAssetMediaEdm',
  keys: [{name: 'jobAssetMediaGuid'}],
  fields: {
    jobAssetMediaGuid: {type: 'Edm.Guid', nullable: false},
    jobAssetGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String', maxLength: -1},
    abbreviatedDescription: {type: 'Edm.String', nullable: false},
    fileNamePart: {type: 'Edm.String', nullable: false},
    type: {type: 'Edm.Int32'},
    min: {type: 'Edm.Int32'},
    max: {type: 'Edm.Int32'},
    comment: {type: 'Edm.String', maxLength: -1},
    jobAsset: {type: 'PaPlusApi.DataDomain.EFEntities.JobAssetEdm', navigation: true, referentials: [{property: 'jobAssetGuid', referencedProperty: 'jobAssetGuid'}]},
    captures: {type: 'PaPlusApi.DataDomain.EFEntities.CaptureEdm', collection: true, navigation: true},
    personMediaStatuses: {type: 'PaPlusApi.DataDomain.EFEntities.PersonMediaStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<JobAssetMediaEdm>;
//#endregion