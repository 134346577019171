//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './addressedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AddressEdmEntityConfig = {
  name: 'AddressEdm',
  keys: [{name: 'addressGuid'}],
  fields: {
    addressGuid: {type: 'Edm.Guid', nullable: false},
    line1: {type: 'Edm.String'},
    line2: {type: 'Edm.String'},
    city: {type: 'Edm.String'},
    county: {type: 'Edm.String'},
    countryCode: {type: 'Edm.String'},
    country: {type: 'Edm.String'},
    postcode: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    jobAddresses: {type: 'PaPlusApi.DataDomain.EFEntities.JobAddressEdm', collection: true, navigation: true},
    ouAddresses: {type: 'PaPlusApi.DataDomain.EFEntities.OuAddressEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AddressEdm>;
//#endregion