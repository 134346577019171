import { Injectable } from '@angular/core';
import { INavigationClient, NavigationClient } from '@azure/msal-browser';
import { DefaultSystemBrowserOptions, InAppBrowser } from '@capacitor/inappbrowser';
import { DeepLinksService } from "@sharedLibrary/services/deep-links.service";

export class CustomNavigationClient extends NavigationClient implements INavigationClient {
    constructor(private deepLinkService: DeepLinksService) {
        super();
    }

    override async navigateExternal(url: string, options: any): Promise<boolean> {
        console.log('navigateExternal', url, options);

        const systempBrowserOptions = DefaultSystemBrowserOptions;
        systempBrowserOptions.android.showTitle = true;

        this.deepLinkService.deepLinkOpened$.subscribe(async (url: string) => {
            await InAppBrowser.close();
        });

        await InAppBrowser.openInSystemBrowser({
            url: url,
            options: systempBrowserOptions
        });

        return true;
    }
}