import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions,
  ODataBaseService
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobsListEdm } from './DataDomain/EFEntities/jobslistedm.complex';
//#endregion

@Injectable()
export class ContainerService extends ODataBaseService {

  constructor(client: ODataClient) {
    super(client, 'ContainerContainer', 'PAPlus');
  }

  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  public jobsLists(): ODataFunctionResource<null, JobsListEdm> { 
    return this.client.function<null, JobsListEdm>('paplusApi.JobsLists', this.apiNameOrEntityType);
  }
  public callJobsLists(options?: ODataFunctionOptions<JobsListEdm>) {
    return this.callFunction<null, JobsListEdm>(
      null, 
      this.jobsLists(), 
      'entity', options) as Observable<ODataEntity<JobsListEdm>>;
  }
  //#endregion
}