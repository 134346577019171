//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './assetedm.entity';
import { OuEdm } from './ouedm.entity';
//#endregion

export interface AssetOuEdm {
  //#region ODataApiGen Properties
  ouGuid?: string;
  assetGuid?: string;
  asset?: AssetEdm;
  ou?: OuEdm;
  //#endregion
}