//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetTemplateEdm } from './assettemplateedm.entity';
import { JobTemplateEdm } from './jobtemplateedm.entity';
import { OuTemplateEdm } from './outemplateedm.entity';
import { PersonEdm } from './personedm.entity';
import { PersonTemplateStatusEdm } from './persontemplatestatusedm.entity';
//#endregion

export interface TemplateEdm {
  //#region ODataApiGen Properties
  templateGuid?: string;
  personGuid?: string;
  description: string;
  enabled?: boolean;
  person?: PersonEdm;
  assetTemplates?: AssetTemplateEdm[];
  jobTemplates?: JobTemplateEdm[];
  ouTemplates?: OuTemplateEdm[];
  personTemplateStatuses?: PersonTemplateStatusEdm[];
  //#endregion
}