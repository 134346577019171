//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonMediaStatusesService } from './personmediastatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonMediaStatusesServiceEntitySetConfig = {
  name: 'PersonMediaStatuses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.PersonMediaStatusEdm',
  service: PersonMediaStatusesService
} as EntitySetConfig;
//#endregion