import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AddressEdm } from './DataDomain/EFEntities/addressedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonAssetStatusEdm } from './DataDomain/EFEntities/personassetstatusedm.entity';
import { PersonDeviceEdm } from './DataDomain/EFEntities/persondeviceedm.entity';
import { PersonJobEdm } from './DataDomain/EFEntities/personjobedm.entity';
import { PersonMediaContributorEdm } from './DataDomain/EFEntities/personmediacontributoredm.entity';
import { PersonMediaStatusEdm } from './DataDomain/EFEntities/personmediastatusedm.entity';
import { PersonOuRoleEdm } from './DataDomain/EFEntities/personouroleedm.entity';
import { PersonTemplateStatusEdm } from './DataDomain/EFEntities/persontemplatestatusedm.entity';
import { ProjectPersonRoleEdm } from './DataDomain/EFEntities/projectpersonroleedm.entity';
import { TemplateEdm } from './DataDomain/EFEntities/templateedm.entity';
//#endregion

@Injectable()
export class PeopleService extends ODataEntitySetService<PersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'People', 'paplusApi.DataDomain.EFEntities.PersonEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByAddresses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<AddressEdm> { 
    return this.entity(key).navigationProperty<AddressEdm>('createdByAddresses'); 
  }
  public fetchCreatedByAddresses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<AddressEdm>) {
    return this.fetchNavigationProperty<AddressEdm>(
      this.createdByAddresses(key), 
      'entities', options) as Observable<ODataEntities<AddressEdm>>;
  }
  public addAddressEdmToCreatedByAddresses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<AddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByAddresses(key).reference()
      .add(target);
  }
  public removeAddressEdmFromCreatedByAddresses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByAddresses(key).reference()
      .remove(target);
  }
  public personAssetStatuses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonAssetStatusEdm> { 
    return this.entity(key).navigationProperty<PersonAssetStatusEdm>('personAssetStatuses'); 
  }
  public fetchPersonAssetStatuses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonAssetStatusEdm>) {
    return this.fetchNavigationProperty<PersonAssetStatusEdm>(
      this.personAssetStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonAssetStatusEdm>>;
  }
  public addPersonAssetStatusEdmToPersonAssetStatuses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .add(target);
  }
  public removePersonAssetStatusEdmFromPersonAssetStatuses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personAssetStatuses(key).reference()
      .remove(target);
  }
  public personDevices(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonDeviceEdm> { 
    return this.entity(key).navigationProperty<PersonDeviceEdm>('personDevices'); 
  }
  public fetchPersonDevices(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonDeviceEdm>) {
    return this.fetchNavigationProperty<PersonDeviceEdm>(
      this.personDevices(key), 
      'entities', options) as Observable<ODataEntities<PersonDeviceEdm>>;
  }
  public addPersonDeviceEdmToPersonDevices(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonDeviceEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personDevices(key).reference()
      .add(target);
  }
  public removePersonDeviceEdmFromPersonDevices(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonDeviceEdm>>, etag?: string} = {}): Observable<any> {
    return this.personDevices(key).reference()
      .remove(target);
  }
  public personJobs(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonJobEdm> { 
    return this.entity(key).navigationProperty<PersonJobEdm>('personJobs'); 
  }
  public fetchPersonJobs(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonJobEdm>) {
    return this.fetchNavigationProperty<PersonJobEdm>(
      this.personJobs(key), 
      'entities', options) as Observable<ODataEntities<PersonJobEdm>>;
  }
  public addPersonJobEdmToPersonJobs(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonJobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personJobs(key).reference()
      .add(target);
  }
  public removePersonJobEdmFromPersonJobs(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonJobEdm>>, etag?: string} = {}): Observable<any> {
    return this.personJobs(key).reference()
      .remove(target);
  }
  public personMediaContributors(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonMediaContributorEdm> { 
    return this.entity(key).navigationProperty<PersonMediaContributorEdm>('personMediaContributors'); 
  }
  public fetchPersonMediaContributors(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonMediaContributorEdm>) {
    return this.fetchNavigationProperty<PersonMediaContributorEdm>(
      this.personMediaContributors(key), 
      'entities', options) as Observable<ODataEntities<PersonMediaContributorEdm>>;
  }
  public addPersonMediaContributorEdmToPersonMediaContributors(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonMediaContributorEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personMediaContributors(key).reference()
      .add(target);
  }
  public removePersonMediaContributorEdmFromPersonMediaContributors(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonMediaContributorEdm>>, etag?: string} = {}): Observable<any> {
    return this.personMediaContributors(key).reference()
      .remove(target);
  }
  public personMediaStatuses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonMediaStatusEdm> { 
    return this.entity(key).navigationProperty<PersonMediaStatusEdm>('personMediaStatuses'); 
  }
  public fetchPersonMediaStatuses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonMediaStatusEdm>) {
    return this.fetchNavigationProperty<PersonMediaStatusEdm>(
      this.personMediaStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonMediaStatusEdm>>;
  }
  public addPersonMediaStatusEdmToPersonMediaStatuses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .add(target);
  }
  public removePersonMediaStatusEdmFromPersonMediaStatuses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .remove(target);
  }
  public personOuRoles(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonOuRoleEdm> { 
    return this.entity(key).navigationProperty<PersonOuRoleEdm>('personOuRoles'); 
  }
  public fetchPersonOuRoles(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonOuRoleEdm>) {
    return this.fetchNavigationProperty<PersonOuRoleEdm>(
      this.personOuRoles(key), 
      'entities', options) as Observable<ODataEntities<PersonOuRoleEdm>>;
  }
  public addPersonOuRoleEdmToPersonOuRoles(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .add(target);
  }
  public removePersonOuRoleEdmFromPersonOuRoles(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .remove(target);
  }
  public personTemplateStatuses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonTemplateStatusEdm> { 
    return this.entity(key).navigationProperty<PersonTemplateStatusEdm>('personTemplateStatuses'); 
  }
  public fetchPersonTemplateStatuses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonTemplateStatusEdm>) {
    return this.fetchNavigationProperty<PersonTemplateStatusEdm>(
      this.personTemplateStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonTemplateStatusEdm>>;
  }
  public addPersonTemplateStatusEdmToPersonTemplateStatuses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .add(target);
  }
  public removePersonTemplateStatusEdmFromPersonTemplateStatuses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .remove(target);
  }
  public projectPersonRoles(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<ProjectPersonRoleEdm> { 
    return this.entity(key).navigationProperty<ProjectPersonRoleEdm>('projectPersonRoles'); 
  }
  public fetchProjectPersonRoles(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<ProjectPersonRoleEdm>) {
    return this.fetchNavigationProperty<ProjectPersonRoleEdm>(
      this.projectPersonRoles(key), 
      'entities', options) as Observable<ODataEntities<ProjectPersonRoleEdm>>;
  }
  public addProjectPersonRoleEdmToProjectPersonRoles(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .add(target);
  }
  public removeProjectPersonRoleEdmFromProjectPersonRoles(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .remove(target);
  }
  public reportingPersonOuRoles(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonOuRoleEdm> { 
    return this.entity(key).navigationProperty<PersonOuRoleEdm>('reportingPersonOuRoles'); 
  }
  public fetchReportingPersonOuRoles(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonOuRoleEdm>) {
    return this.fetchNavigationProperty<PersonOuRoleEdm>(
      this.reportingPersonOuRoles(key), 
      'entities', options) as Observable<ODataEntities<PersonOuRoleEdm>>;
  }
  public addPersonOuRoleEdmToReportingPersonOuRoles(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reportingPersonOuRoles(key).reference()
      .add(target);
  }
  public removePersonOuRoleEdmFromReportingPersonOuRoles(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.reportingPersonOuRoles(key).reference()
      .remove(target);
  }
  public templates(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<TemplateEdm> { 
    return this.entity(key).navigationProperty<TemplateEdm>('templates'); 
  }
  public fetchTemplates(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<TemplateEdm>) {
    return this.fetchNavigationProperty<TemplateEdm>(
      this.templates(key), 
      'entities', options) as Observable<ODataEntities<TemplateEdm>>;
  }
  public addTemplateEdmToTemplates(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<TemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.templates(key).reference()
      .add(target);
  }
  public removeTemplateEdmFromTemplates(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.templates(key).reference()
      .remove(target);
  }
  public updatedByPersonAssetStatuses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonAssetStatusEdm> { 
    return this.entity(key).navigationProperty<PersonAssetStatusEdm>('updatedByPersonAssetStatuses'); 
  }
  public fetchUpdatedByPersonAssetStatuses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonAssetStatusEdm>) {
    return this.fetchNavigationProperty<PersonAssetStatusEdm>(
      this.updatedByPersonAssetStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonAssetStatusEdm>>;
  }
  public addPersonAssetStatusEdmToUpdatedByPersonAssetStatuses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.updatedByPersonAssetStatuses(key).reference()
      .add(target);
  }
  public removePersonAssetStatusEdmFromUpdatedByPersonAssetStatuses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonAssetStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.updatedByPersonAssetStatuses(key).reference()
      .remove(target);
  }
  public updatedByPersonTemplateStatuses(key: EntityKey<PersonEdm>): ODataNavigationPropertyResource<PersonTemplateStatusEdm> { 
    return this.entity(key).navigationProperty<PersonTemplateStatusEdm>('updatedByPersonTemplateStatuses'); 
  }
  public fetchUpdatedByPersonTemplateStatuses(key: EntityKey<PersonEdm>, options?: ODataQueryArgumentsOptions<PersonTemplateStatusEdm>) {
    return this.fetchNavigationProperty<PersonTemplateStatusEdm>(
      this.updatedByPersonTemplateStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonTemplateStatusEdm>>;
  }
  public addPersonTemplateStatusEdmToUpdatedByPersonTemplateStatuses(key: EntityKey<PersonEdm>, target: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.updatedByPersonTemplateStatuses(key).reference()
      .add(target);
  }
  public removePersonTemplateStatusEdmFromUpdatedByPersonTemplateStatuses(key: EntityKey<PersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.updatedByPersonTemplateStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
