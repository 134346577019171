<ion-header [translucent]="true">
  <ion-toolbar color="primary" class="padding">

    <ion-button slot="start" (click)="toggleSideMenu()" color="secondary">
      <ion-icon slot="icon-only" [name]="(pubSub.$menuState | async) ? 'close' : 'menu'"></ion-icon>
    </ion-button>

    <ion-img class="logo" slot="start" src="assets/img/PA+-Logo-Part-White.png" alt="Photo Audit Plus"></ion-img>
    <!--<ion-button color="secondary" slot="end" expand="block" (click)="logout()">Log out</ion-button>-->

    <ion-buttons class="paddingLeft" slot="start" fill="clear">

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="userIsLoggedIn" class="paddingRight">

      <ion-button id="present-alert">Language</ion-button>
      <ion-alert trigger="present-alert" header="Default Language" [buttons]="alertButtons"
        [inputs]="alertInputs"></ion-alert>

      <div>
        <ion-button fill="clear" id="click-trigger">
          <ion-img src="assets/img/icon-user.png"></ion-img>
        </ion-button>
        <ion-popover trigger="click-trigger" triggerAction="click">
          <ng-template>
            <ion-list>
              <ion-item button (click)="logout()">Logout</ion-item>
            </ion-list>
          </ng-template>
        </ion-popover>
      </div>

    </ion-buttons>

  </ion-toolbar>
</ion-header>