import { AvailableEnvironment } from "./available-environments";

export const environment = {
  Production: false,
  BuildEnvironment: AvailableEnvironment.Local,
  PortalUrl: 'http://localhost:4200',
  MobileUrl: 'http://localhost:4200',
  Api: 'https://localhost:44310/odata',
  SignalR: 'https://localhost:44310/signalr',
  B2cDomain: 'paplusdev',
  B2cTenantId: '31806fdc-be9d-4e64-88df-f9579aa99fbd',
  PortalClientId: '0e013de8-0fad-4b64-91ce-97f53328d206',
  MobileClientId: 'a236752e-fa6b-441a-89bf-0f3e7a7411b0',
  BackendAppIdentifier: 'dev-api',
  GoogleMapsApiKey: 'AIzaSyAh-wSELQf54o5x6p33MDkucbMC2L0oHdQ',
  What3WordsKey: '87B5JULS',
  PortalSentryDns: 'https://4a8243585616091196b87aeaac23f893@o4508609265532928.ingest.de.sentry.io/4508766633590864',
  MobileSentryDns: 'https://f38c6e58d7ea8c9160ff2c89936a633f@o4508609265532928.ingest.de.sentry.io/4508767385223248',
};
