import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './DataDomain/EFEntities/captureedm.entity';
import { MediaStatusEdm } from './DataDomain/EFEntities/mediastatusedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonCaptureMediaStatusEdm } from './DataDomain/EFEntities/personcapturemediastatusedm.entity';
//#endregion

@Injectable()
export class PersonCaptureMediaStatusesService extends ODataEntitySetService<PersonCaptureMediaStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonCaptureMediaStatuses', 'PaPlusApi.DataDomain.EFEntities.PersonCaptureMediaStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public capture(key: EntityKey<PersonCaptureMediaStatusEdm>): ODataNavigationPropertyResource<CaptureEdm> { 
    return this.entity(key).navigationProperty<CaptureEdm>('capture'); 
  }
  public fetchCapture(key: EntityKey<PersonCaptureMediaStatusEdm>, options?: ODataQueryArgumentsOptions<CaptureEdm>) {
    return this.fetchNavigationProperty<CaptureEdm>(
      this.capture(key), 
      'entity', options) as Observable<ODataEntity<CaptureEdm>>;
  }
  public setCaptureEdmAsCapture(key: EntityKey<PersonCaptureMediaStatusEdm>, target: ODataEntityResource<ODataEntity<CaptureEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.capture(key).reference()
      .set(target, {etag});
  }
  public unsetCaptureEdmAsCapture(key: EntityKey<PersonCaptureMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CaptureEdm>>, etag?: string} = {}): Observable<any> {
    return this.capture(key).reference()
      .unset({etag});
  }
  public mediaStatus(key: EntityKey<PersonCaptureMediaStatusEdm>): ODataNavigationPropertyResource<MediaStatusEdm> { 
    return this.entity(key).navigationProperty<MediaStatusEdm>('mediaStatus'); 
  }
  public fetchMediaStatus(key: EntityKey<PersonCaptureMediaStatusEdm>, options?: ODataQueryArgumentsOptions<MediaStatusEdm>) {
    return this.fetchNavigationProperty<MediaStatusEdm>(
      this.mediaStatus(key), 
      'entity', options) as Observable<ODataEntity<MediaStatusEdm>>;
  }
  public setMediaStatusEdmAsMediaStatus(key: EntityKey<PersonCaptureMediaStatusEdm>, target: ODataEntityResource<ODataEntity<MediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaStatus(key).reference()
      .set(target, {etag});
  }
  public unsetMediaStatusEdmAsMediaStatus(key: EntityKey<PersonCaptureMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<MediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaStatus(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonCaptureMediaStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonCaptureMediaStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonCaptureMediaStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonCaptureMediaStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
