//#region ODataApiGen ODataImports
import {
  ApiConfig,
  EDM_PARSERS
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EFEntitiesSchema } from './PaPlusApi/DataDomain/EFEntities/efentities.schema';
import { DTOsSchema } from './PaPlusApi/DataDomain/DTOs/dtos.schema';
import { PaPlusApiSchema } from './PaPlusApi/paplusapi.schema';
//#endregion

//#region ODataApiGen ApiConfig
export const PAPlusOdataConfig = {
  serviceRootUrl: 'https://localhost:44300/odata',
  name: 'PAPlusOdata',
  version: '4.0',
  creation: new Date('2025-02-13T17:27:11.3965087+00:00'),
  schemas: [
    EFEntitiesSchema,
    DTOsSchema,
    PaPlusApiSchema
  ],
  parsers: EDM_PARSERS
} as ApiConfig;
//#endregion