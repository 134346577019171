import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { AssetTemplateEdm } from './DataDomain/EFEntities/assettemplateedm.entity';
import { TemplateEdm } from './DataDomain/EFEntities/templateedm.entity';
//#endregion

@Injectable()
export class AssetTemplatesService extends ODataEntitySetService<AssetTemplateEdm> {
  constructor(client: ODataClient) {
    super(client, 'AssetTemplates', 'paplusApi.DataDomain.EFEntities.AssetTemplateEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public asset(key: EntityKey<AssetTemplateEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('asset'); 
  }
  public fetchAsset(key: EntityKey<AssetTemplateEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.asset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsAsset(key: EntityKey<AssetTemplateEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsAsset(key: EntityKey<AssetTemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .unset({etag});
  }
  public template(key: EntityKey<AssetTemplateEdm>): ODataNavigationPropertyResource<TemplateEdm> { 
    return this.entity(key).navigationProperty<TemplateEdm>('template'); 
  }
  public fetchTemplate(key: EntityKey<AssetTemplateEdm>, options?: ODataQueryArgumentsOptions<TemplateEdm>) {
    return this.fetchNavigationProperty<TemplateEdm>(
      this.template(key), 
      'entity', options) as Observable<ODataEntity<TemplateEdm>>;
  }
  public setTemplateEdmAsTemplate(key: EntityKey<AssetTemplateEdm>, target: ODataEntityResource<ODataEntity<TemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.template(key).reference()
      .set(target, {etag});
  }
  public unsetTemplateEdmAsTemplate(key: EntityKey<AssetTemplateEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.template(key).reference()
      .unset({etag});
  }
  //#endregion
}
