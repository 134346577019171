//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './ouedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OuEdmEntityConfig = {
  name: 'OuEdm',
  keys: [{name: 'ouGuid'}],
  fields: {
    ouGuid: {type: 'Edm.Guid', nullable: false},
    parentOuGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    phoneNumber: {type: 'Edm.String'},
    webAddress: {type: 'Edm.String'},
    parentOu: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'parentOuGuid', referencedProperty: 'ouGuid'}]},
    assetOus: {type: 'paplusApi.DataDomain.EFEntities.AssetOuEdm', collection: true, navigation: true},
    jobs: {type: 'paplusApi.DataDomain.EFEntities.JobEdm', collection: true, navigation: true},
    ouAddresses: {type: 'paplusApi.DataDomain.EFEntities.OuAddressEdm', collection: true, navigation: true},
    ouCloseoutPackages: {type: 'paplusApi.DataDomain.EFEntities.OuCloseoutPackageEdm', collection: true, navigation: true},
    ouTemplates: {type: 'paplusApi.DataDomain.EFEntities.OuTemplateEdm', collection: true, navigation: true},
    parentOus: {type: 'paplusApi.DataDomain.EFEntities.OuEdm', collection: true, navigation: true},
    personOuRoles: {type: 'paplusApi.DataDomain.EFEntities.PersonOuRoleEdm', collection: true, navigation: true},
    projects: {type: 'paplusApi.DataDomain.EFEntities.ProjectEdm', collection: true, navigation: true},
    roles: {type: 'paplusApi.DataDomain.EFEntities.RoleEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<OuEdm>;
//#endregion