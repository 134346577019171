import { Component, Input, OnInit } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Position } from '@capacitor/geolocation';
import { ModalController } from '@ionic/angular';
import { CaptureSource } from '@sharedLibrary/enums/capture-enums';
import { JobAssetMediaEdm, JobEdm, MediaStatusEdm, PersonEdm, PersonMediaStatusEdm } from '@sharedLibrary/odata';
import { CapturesService } from '@sharedLibrary/services/captures.service';
import { CommonService } from '@sharedLibrary/services/common.service';
import { LocationService } from '@sharedLibrary/services/location.service';

import { BehaviorSubject } from 'rxjs';
import { MediaStatus } from '@sharedLibrary/enums/status-enums';
import { JobAssetMediaService, PersonMediaStatusService } from '@sharedLibrary/services/job-asset-media.service';
import { ToastMessageService } from '@sharedLibrary/services/toast-message.service';
import { StorageService } from '@sharedLibrary/services/storage.service';
import { FileSystemPath } from '@sharedLibrary/enums/storage-enums';
import { BASE64_IMAGE_PREFIXx } from '@sharedLibrary/constants/general';
import { CaptureUploadResult } from '@sharedLibrary/models/custom-capture-models';


@Component({
  selector: 'app-take-capture',
  templateUrl: './take-capture.component.html',
  styleUrls: ['./take-capture.component.scss'],
})
export class TakeCaptureComponent implements OnInit {

  @Input() job: JobEdm;
  @Input() jobAssetMedia: JobAssetMediaEdm;
  @Input() inputPhoto: Photo;

  isLoading$ = new BehaviorSubject<boolean>(true);

  private mediaStatuses: MediaStatusEdm[] = [];
  //private loggedInUser: PersonEdm;
  public image = '/assets/img/image-awaiting.png';
  public captureCount: number;
  public geoLocation: Position;


  constructor(
    //private authService: MsalService,
    private capturesService: CapturesService,
    private commonService: CommonService,
    private jobAssetMediaService: JobAssetMediaService,
    //private jobDetailsService: JobDetailsService,
    private personMediaStatusService: PersonMediaStatusService,
    private locationService: LocationService,
    private modalController: ModalController,
    //private paplusUserService: PAPlusUserAccountService,
    private storageService: StorageService,
    private toastMessageService: ToastMessageService,
  ) { }

  ngOnInit() {
    // let activeAccount = this.authService.instance.getActiveAccount();
    // if (activeAccount !== null) {
    //   this.paplusUserService.retrievePaPlusUser(activeAccount.localAccountId).subscribe(res => {
    //     this.loggedInUser = res;
    //   });
    // }

    this.jobAssetMediaService.getMediaStatuses().subscribe(statuses => {
      this.mediaStatuses = statuses;
    });

    if (this.inputPhoto) {
      this.image = BASE64_IMAGE_PREFIXx + this.inputPhoto.base64String;
      this.captureCount = this.jobAssetMedia.captures.length + 1;
      this.isLoading$.next(false);
    }

  }

  async openCamera() {
    const isLocationOn = await this.locationService.isLocationServiceOn();

    if (!isLocationOn) {
      this.commonService.openSnackBar('Please turn on the location.', 3000, 'error-snackbar');
      return;
    }

    // get and set the current user's latest position
    const latestPosition = await this.locationService.getCurrentUserLocation();
    if (latestPosition) {
      this.geoLocation = latestPosition;
    }

    // check if we have valid most recent user's position
    if (!this.locationService.isLocationValid(this.geoLocation)) {
      this.commonService.openSnackBar('Failed to get your recent position.', 3000, 'error-snackbar');
      return;
    }

    //set the capture count to initial value if we retake the capture
    if (this.jobAssetMedia) {
      this.captureCount = this.jobAssetMedia.captures.length ? this.jobAssetMedia.captures.length : 0;
    }

    //display camera
    const takenPhoto = await Camera.getPhoto({
      source: CameraSource.Camera,
      allowEditing: false,
      quality: 100,
      resultType: CameraResultType.Base64
    }).catch((error: Error) => console.log(error.message));


    if (takenPhoto) {
      this.image = BASE64_IMAGE_PREFIXx + takenPhoto.base64String;
      this.inputPhoto = takenPhoto;
    }
  }

  async uploadClick() {
    this.isLoading$.next(true);
    let fileToUpload: File = await this.convertToPNG(this.inputPhoto);

    if (fileToUpload) {

      if (!this.geoLocation) {
        this.geoLocation = await this.locationService.getCurrentUserLocation();
      }

      let exifMetadata = JSON.stringify(this.inputPhoto.exif);
      let long = this.geoLocation.coords.longitude;
      let lat = this.geoLocation.coords.latitude;

      let personMediaStatus: PersonMediaStatusEdm = {
        personGuid: '4DFE79AA-9C8E-4B82-81CE-019345275682', //this.loggedInUser.personGuid, //TODO: Add in the logged in user service
        jobAssetMediaGuid: this.jobAssetMedia.jobAssetMediaGuid,
        mediaStatusGuid: this.mediaStatuses.find(s => s.enumVal == MediaStatus.Awaiting).mediaStatusGuid,
        fromUtcDateTime: new Date(),
        notes: 'Initial Upload'
      }

      this.personMediaStatusService.createPersonMediaStatus(personMediaStatus).subscribe({
        next: (pms => {
          this.capturesService.uploadCapture(
            this.job.jobGuid,
            this.jobAssetMedia.jobAssetGuid,
            this.jobAssetMedia.jobAssetMediaGuid,
            CaptureSource.AppCamera,
            lat,
            long,
            exifMetadata,
            fileToUpload).subscribe({
              next: (res => {
                //this.jobDetailsService.fetchJobData(this.job.jobGuid);
                console.log(this.job);
                const filePath: string = `${FileSystemPath.MediaFolder}/${this.jobAssetMedia.jobAssetGuid}/${this.jobAssetMedia.jobAssetMediaGuid}/${res.captureGuid}`;
                this.storageService.saveBlobToFilesystem(fileToUpload, filePath);
                const uploadResult: CaptureUploadResult = {uploadSuccessful: true, base64String: this.inputPhoto.base64String};
                this.modalController.dismiss(uploadResult);
              }),
              error: (error => {
                this.commonService.handleError(this.uploadClick.name, error, 'Uploading Capture.')
                this.isLoading$.next(false);
                this.toastMessageService.showMedium('Something went wrong when uploading the capture.');
              })
            });
        }),
        error: (error => {
          this.commonService.handleError(this.uploadClick.name, error, 'Creating PersonMediaStatus.')
          this.isLoading$.next(false);
          this.toastMessageService.showMedium('Something went wrong when uploading the capture.');
        })
      });
    }
  }

  cancelClick() {
    this.modalController.dismiss({ cancel: "direct" });
  }

  deleteNote() {

  }

  async convertToPNG(photo: Photo): Promise<File> {

    // Convert Base64 to Blob
    const base64Data = photo.base64String || ''; // Ensure base64String is not null
    const contentType = 'image/png';
    const blob = this.base64ToBlob(base64Data, contentType);

    // Convert Blob to File
    const dateTaken = new Date();
    const dateStr = dateTaken.getFullYear().toString() + dateTaken.getMonth().toString() + dateTaken.getDay().toString() + dateTaken.getHours().toString() + dateTaken.getMinutes().toString() + dateTaken.getSeconds().toString()
    const fileName = 'capture_' + dateStr + '.png';
    console.log("FileName:", fileName);
    const file = new File([blob], fileName, { type: contentType });

    return file;
  }

  // Helper function to convert Base64 to Blob
  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

}
