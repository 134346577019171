//#region ODataApiGen ODataImports
import {
  NgModule
} from '@angular/core';//#endregion

//#region ODataApiGen Imports
import { ContainerService } from './paplusApi/container.service';
import { AddressesService } from './paplusApi/addresses.service';
import { AssetsService } from './paplusApi/assets.service';
import { AssetMediasService } from './paplusApi/assetmedias.service';
import { AssetOusService } from './paplusApi/assetous.service';
import { AssetParentAssetsService } from './paplusApi/assetparentassets.service';
import { AssetStatusesService } from './paplusApi/assetstatuses.service';
import { AssetTemplatesService } from './paplusApi/assettemplates.service';
import { CapturesService } from './paplusApi/captures.service';
import { CloseoutPackagesService } from './paplusApi/closeoutpackages.service';
import { DevicesService } from './paplusApi/devices.service';
import { IconsService } from './paplusApi/icons.service';
import { JobsService } from './paplusApi/jobs.service';
import { JobAddressesService } from './paplusApi/jobaddresses.service';
import { JobAssetsService } from './paplusApi/jobassets.service';
import { JobAssetMediasService } from './paplusApi/jobassetmedias.service';
import { JobAssetParentAssetsService } from './paplusApi/jobassetparentassets.service';
import { JobAssetTemplatesService } from './paplusApi/jobassettemplates.service';
import { JobTemplatesService } from './paplusApi/jobtemplates.service';
import { MediaContributorsService } from './paplusApi/mediacontributors.service';
import { MediaContributorJobAssetsService } from './paplusApi/mediacontributorjobassets.service';
import { MediaStatusesService } from './paplusApi/mediastatuses.service';
import { NotificationTemplatesService } from './paplusApi/notificationtemplates.service';
import { OusService } from './paplusApi/ous.service';
import { OuAddressesService } from './paplusApi/ouaddresses.service';
import { OuCloseoutPackagesService } from './paplusApi/oucloseoutpackages.service';
import { OuTemplatesService } from './paplusApi/outemplates.service';
import { PermissionsService } from './paplusApi/permissions.service';
import { PeopleService } from './paplusApi/people.service';
import { PersonAssetStatusesService } from './paplusApi/personassetstatuses.service';
import { PersonDevicesService } from './paplusApi/persondevices.service';
import { PersonJobsService } from './paplusApi/personjobs.service';
import { PersonMediaContributorsService } from './paplusApi/personmediacontributors.service';
import { PersonMediaStatusesService } from './paplusApi/personmediastatuses.service';
import { PersonOuRolesService } from './paplusApi/personouroles.service';
import { PersonTemplateStatusesService } from './paplusApi/persontemplatestatuses.service';
import { ProjectsService } from './paplusApi/projects.service';
import { ProjectPersonRolesService } from './paplusApi/projectpersonroles.service';
import { ProjectProjectStatusesService } from './paplusApi/projectprojectstatuses.service';
import { ProjectStatusesService } from './paplusApi/projectstatuses.service';
import { RolesService } from './paplusApi/roles.service';
import { RolePermissionsService } from './paplusApi/rolepermissions.service';
import { TemplatesService } from './paplusApi/templates.service';
import { TemplateStatusesService } from './paplusApi/templatestatuses.service';
import { PersonTemplateInfoEdmService } from './paplusApi/persontemplateinfoedm.service';
//#endregion

@NgModule({
  providers: [
//#region ODataApiGen Providers
    ContainerService,
    AddressesService,
    AssetsService,
    AssetMediasService,
    AssetOusService,
    AssetParentAssetsService,
    AssetStatusesService,
    AssetTemplatesService,
    CapturesService,
    CloseoutPackagesService,
    DevicesService,
    IconsService,
    JobsService,
    JobAddressesService,
    JobAssetsService,
    JobAssetMediasService,
    JobAssetParentAssetsService,
    JobAssetTemplatesService,
    JobTemplatesService,
    MediaContributorsService,
    MediaContributorJobAssetsService,
    MediaStatusesService,
    NotificationTemplatesService,
    OusService,
    OuAddressesService,
    OuCloseoutPackagesService,
    OuTemplatesService,
    PermissionsService,
    PeopleService,
    PersonAssetStatusesService,
    PersonDevicesService,
    PersonJobsService,
    PersonMediaContributorsService,
    PersonMediaStatusesService,
    PersonOuRolesService,
    PersonTemplateStatusesService,
    ProjectsService,
    ProjectPersonRolesService,
    ProjectProjectStatusesService,
    ProjectStatusesService,
    RolesService,
    RolePermissionsService,
    TemplatesService,
    TemplateStatusesService,
    PersonTemplateInfoEdmService//#endregion
  ]
})
export class PAPlusOdataModule { }