//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuListEdm } from './oulistedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OuListEdmComplexConfig = {
  name: 'OuListEdm',
  fields: {
    ouGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    line1: {type: 'Edm.String'},
    line2: {type: 'Edm.String'},
    city: {type: 'Edm.String'},
    county: {type: 'Edm.String'},
    postcode: {type: 'Edm.String'},
    webAddress: {type: 'Edm.String'},
    parentOu: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<OuListEdm>;
//#endregion