import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectProjectStatusEdm } from './DataDomain/EFEntities/projectprojectstatusedm.entity';
import { ProjectStatusEdm } from './DataDomain/EFEntities/projectstatusedm.entity';
//#endregion

@Injectable()
export class ProjectStatusesService extends ODataEntitySetService<ProjectStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'ProjectStatuses', 'PaPlusApi.DataDomain.EFEntities.ProjectStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public projectProjectStatuses(key: EntityKey<ProjectStatusEdm>): ODataNavigationPropertyResource<ProjectProjectStatusEdm> { 
    return this.entity(key).navigationProperty<ProjectProjectStatusEdm>('projectProjectStatuses'); 
  }
  public fetchProjectProjectStatuses(key: EntityKey<ProjectStatusEdm>, options?: ODataQueryArgumentsOptions<ProjectProjectStatusEdm>) {
    return this.fetchNavigationProperty<ProjectProjectStatusEdm>(
      this.projectProjectStatuses(key), 
      'entities', options) as Observable<ODataEntities<ProjectProjectStatusEdm>>;
  }
  public addProjectProjectStatusEdmToProjectProjectStatuses(key: EntityKey<ProjectStatusEdm>, target: ODataEntityResource<ODataEntities<ProjectProjectStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectProjectStatuses(key).reference()
      .add(target);
  }
  public removeProjectProjectStatusEdmFromProjectProjectStatuses(key: EntityKey<ProjectStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectProjectStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectProjectStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
