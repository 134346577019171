import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  /**
 * Converts a Blob object into a Base64-encoded string.
 * This function reads the contents of the Blob and encodes it as a Base64 string.
 * @param data The Blob to be converted to Base64.
 * @returns A promise that resolves with the Base64-encoded string representation of the Blob data,
 * or rejects with an error if the conversion fails.
 */
async convertBlobToBase64(data: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onerror = () => {
      reader.abort(); // Abort the read operation to free up resources
      reject(new Error("Failed to read the file."));
    };

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1]); // Splits and takes the Base64 part after the comma
      } else {
        reject(new Error("File read completed but no data was obtained."));
      }
    };

    reader.readAsDataURL(data);
  });
}

/**
 * Converts a File into a Base64-encoded string.
 * This function reads the data of the File and encodes it as a Base64 string.
 * @param file The File to be converted to Base64.
 * @returns A promise that resolves with the Base64-encoded string representation of the File data,
 * or rejects with an error if the conversion fails.
 */
convertFileToBase64 (file: File): Promise<string>{
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  });
}

}
