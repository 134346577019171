import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { PersonOuRoleEdm } from './DataDomain/EFEntities/personouroleedm.entity';
import { ProjectPersonRoleEdm } from './DataDomain/EFEntities/projectpersonroleedm.entity';
import { RoleEdm } from './DataDomain/EFEntities/roleedm.entity';
import { RolePermissionEdm } from './DataDomain/EFEntities/rolepermissionedm.entity';
//#endregion

@Injectable()
export class RolesService extends ODataEntitySetService<RoleEdm> {
  constructor(client: ODataClient) {
    super(client, 'Roles', 'paplusApi.DataDomain.EFEntities.RoleEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public ouGuidOu(key: EntityKey<RoleEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('ouGuidOu'); 
  }
  public fetchOuGuidOu(key: EntityKey<RoleEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.ouGuidOu(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsOuGuidOu(key: EntityKey<RoleEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouGuidOu(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsOuGuidOu(key: EntityKey<RoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouGuidOu(key).reference()
      .unset({etag});
  }
  public personOuRoles(key: EntityKey<RoleEdm>): ODataNavigationPropertyResource<PersonOuRoleEdm> { 
    return this.entity(key).navigationProperty<PersonOuRoleEdm>('personOuRoles'); 
  }
  public fetchPersonOuRoles(key: EntityKey<RoleEdm>, options?: ODataQueryArgumentsOptions<PersonOuRoleEdm>) {
    return this.fetchNavigationProperty<PersonOuRoleEdm>(
      this.personOuRoles(key), 
      'entities', options) as Observable<ODataEntities<PersonOuRoleEdm>>;
  }
  public addPersonOuRoleEdmToPersonOuRoles(key: EntityKey<RoleEdm>, target: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .add(target);
  }
  public removePersonOuRoleEdmFromPersonOuRoles(key: EntityKey<RoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .remove(target);
  }
  public projectPersonRoles(key: EntityKey<RoleEdm>): ODataNavigationPropertyResource<ProjectPersonRoleEdm> { 
    return this.entity(key).navigationProperty<ProjectPersonRoleEdm>('projectPersonRoles'); 
  }
  public fetchProjectPersonRoles(key: EntityKey<RoleEdm>, options?: ODataQueryArgumentsOptions<ProjectPersonRoleEdm>) {
    return this.fetchNavigationProperty<ProjectPersonRoleEdm>(
      this.projectPersonRoles(key), 
      'entities', options) as Observable<ODataEntities<ProjectPersonRoleEdm>>;
  }
  public addProjectPersonRoleEdmToProjectPersonRoles(key: EntityKey<RoleEdm>, target: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .add(target);
  }
  public removeProjectPersonRoleEdmFromProjectPersonRoles(key: EntityKey<RoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectPersonRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.projectPersonRoles(key).reference()
      .remove(target);
  }
  public rolePermissions(key: EntityKey<RoleEdm>): ODataNavigationPropertyResource<RolePermissionEdm> { 
    return this.entity(key).navigationProperty<RolePermissionEdm>('rolePermissions'); 
  }
  public fetchRolePermissions(key: EntityKey<RoleEdm>, options?: ODataQueryArgumentsOptions<RolePermissionEdm>) {
    return this.fetchNavigationProperty<RolePermissionEdm>(
      this.rolePermissions(key), 
      'entities', options) as Observable<ODataEntities<RolePermissionEdm>>;
  }
  public addRolePermissionEdmToRolePermissions(key: EntityKey<RoleEdm>, target: ODataEntityResource<ODataEntities<RolePermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.rolePermissions(key).reference()
      .add(target);
  }
  public removeRolePermissionEdmFromRolePermissions(key: EntityKey<RoleEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RolePermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.rolePermissions(key).reference()
      .remove(target);
  }
  //#endregion
}
