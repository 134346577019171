import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaContributorEdm } from './DataDomain/EFEntities/mediacontributoredm.entity';
import { MediaContributorJobAssetEdm } from './DataDomain/EFEntities/mediacontributorjobassetedm.entity';
import { PersonMediaContributorEdm } from './DataDomain/EFEntities/personmediacontributoredm.entity';
//#endregion

@Injectable()
export class MediaContributorsService extends ODataEntitySetService<MediaContributorEdm> {
  constructor(client: ODataClient) {
    super(client, 'MediaContributors', 'paplusApi.DataDomain.EFEntities.MediaContributorEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public mediaContributorJobAssets(key: EntityKey<MediaContributorEdm>): ODataNavigationPropertyResource<MediaContributorJobAssetEdm> { 
    return this.entity(key).navigationProperty<MediaContributorJobAssetEdm>('mediaContributorJobAssets'); 
  }
  public fetchMediaContributorJobAssets(key: EntityKey<MediaContributorEdm>, options?: ODataQueryArgumentsOptions<MediaContributorJobAssetEdm>) {
    return this.fetchNavigationProperty<MediaContributorJobAssetEdm>(
      this.mediaContributorJobAssets(key), 
      'entities', options) as Observable<ODataEntities<MediaContributorJobAssetEdm>>;
  }
  public addMediaContributorJobAssetEdmToMediaContributorJobAssets(key: EntityKey<MediaContributorEdm>, target: ODataEntityResource<ODataEntities<MediaContributorJobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.mediaContributorJobAssets(key).reference()
      .add(target);
  }
  public removeMediaContributorJobAssetEdmFromMediaContributorJobAssets(key: EntityKey<MediaContributorEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<MediaContributorJobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.mediaContributorJobAssets(key).reference()
      .remove(target);
  }
  public personMediaContributors(key: EntityKey<MediaContributorEdm>): ODataNavigationPropertyResource<PersonMediaContributorEdm> { 
    return this.entity(key).navigationProperty<PersonMediaContributorEdm>('personMediaContributors'); 
  }
  public fetchPersonMediaContributors(key: EntityKey<MediaContributorEdm>, options?: ODataQueryArgumentsOptions<PersonMediaContributorEdm>) {
    return this.fetchNavigationProperty<PersonMediaContributorEdm>(
      this.personMediaContributors(key), 
      'entities', options) as Observable<ODataEntities<PersonMediaContributorEdm>>;
  }
  public addPersonMediaContributorEdmToPersonMediaContributors(key: EntityKey<MediaContributorEdm>, target: ODataEntityResource<ODataEntities<PersonMediaContributorEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personMediaContributors(key).reference()
      .add(target);
  }
  public removePersonMediaContributorEdmFromPersonMediaContributors(key: EntityKey<MediaContributorEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonMediaContributorEdm>>, etag?: string} = {}): Observable<any> {
    return this.personMediaContributors(key).reference()
      .remove(target);
  }
  //#endregion
}
