//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonAssetStatusEdm } from './personassetstatusedm.entity';
//#endregion

export interface AssetStatusEdm {
  //#region ODataApiGen Properties
  assetStatusGuid: string;
  description: string;
  showInUi: boolean;
  active: boolean;
  personAssetStatuses?: PersonAssetStatusEdm[];
  //#endregion
}