//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetParentAssetEdm } from './assetparentassetedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AssetParentAssetEdmEntityConfig = {
  name: 'AssetParentAssetEdm',
  keys: [{name: 'assetGuid'},{name: 'parentAssetGuid'}],
  fields: {
    assetGuid: {type: 'Edm.Guid'},
    parentAssetGuid: {type: 'Edm.Guid'},
    asset: {type: 'paplusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'assetGuid', referencedProperty: 'assetGuid'}]},
    parentAsset: {type: 'paplusApi.DataDomain.EFEntities.AssetEdm', navigation: true, referentials: [{property: 'parentAssetGuid', referencedProperty: 'assetGuid'}]}
  }
} as StructuredTypeConfig<AssetParentAssetEdm>;
//#endregion