import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CaptureEdm } from './DataDomain/EFEntities/captureedm.entity';
import { JobAssetEdm } from './DataDomain/EFEntities/jobassetedm.entity';
import { JobAssetMediaEdm } from './DataDomain/EFEntities/jobassetmediaedm.entity';
import { PersonMediaStatusEdm } from './DataDomain/EFEntities/personmediastatusedm.entity';
//#endregion

@Injectable()
export class JobAssetMediasService extends ODataEntitySetService<JobAssetMediaEdm> {
  constructor(client: ODataClient) {
    super(client, 'JobAssetMedias', 'paplusApi.DataDomain.EFEntities.JobAssetMediaEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public captures(key: EntityKey<JobAssetMediaEdm>): ODataNavigationPropertyResource<CaptureEdm> { 
    return this.entity(key).navigationProperty<CaptureEdm>('captures'); 
  }
  public fetchCaptures(key: EntityKey<JobAssetMediaEdm>, options?: ODataQueryArgumentsOptions<CaptureEdm>) {
    return this.fetchNavigationProperty<CaptureEdm>(
      this.captures(key), 
      'entities', options) as Observable<ODataEntities<CaptureEdm>>;
  }
  public addCaptureEdmToCaptures(key: EntityKey<JobAssetMediaEdm>, target: ODataEntityResource<ODataEntities<CaptureEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.captures(key).reference()
      .add(target);
  }
  public removeCaptureEdmFromCaptures(key: EntityKey<JobAssetMediaEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CaptureEdm>>, etag?: string} = {}): Observable<any> {
    return this.captures(key).reference()
      .remove(target);
  }
  public jobAsset(key: EntityKey<JobAssetMediaEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobAsset'); 
  }
  public fetchJobAsset(key: EntityKey<JobAssetMediaEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobAsset(key), 
      'entity', options) as Observable<ODataEntity<JobAssetEdm>>;
  }
  public setJobAssetEdmAsJobAsset(key: EntityKey<JobAssetMediaEdm>, target: ODataEntityResource<ODataEntity<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetEdmAsJobAsset(key: EntityKey<JobAssetMediaEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .unset({etag});
  }
  public personMediaStatuses(key: EntityKey<JobAssetMediaEdm>): ODataNavigationPropertyResource<PersonMediaStatusEdm> { 
    return this.entity(key).navigationProperty<PersonMediaStatusEdm>('personMediaStatuses'); 
  }
  public fetchPersonMediaStatuses(key: EntityKey<JobAssetMediaEdm>, options?: ODataQueryArgumentsOptions<PersonMediaStatusEdm>) {
    return this.fetchNavigationProperty<PersonMediaStatusEdm>(
      this.personMediaStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonMediaStatusEdm>>;
  }
  public addPersonMediaStatusEdmToPersonMediaStatuses(key: EntityKey<JobAssetMediaEdm>, target: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .add(target);
  }
  public removePersonMediaStatusEdmFromPersonMediaStatuses(key: EntityKey<JobAssetMediaEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonMediaStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personMediaStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
