//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateInfoDto } from './persontemplateinfodto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonTemplateInfoDtoEntityConfig = {
  name: 'PersonTemplateInfoDto',
  keys: [{name: 'templateGuid'}],
  fields: {
    templateGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid', nullable: false},
    ouGuid: {type: 'Edm.Guid', nullable: false},
    templateDescription: {type: 'Edm.String'},
    readOnlyTemplate: {type: 'Edm.Boolean'},
    templateStatusGuid: {type: 'Edm.Guid'},
    templateStatusDescription: {type: 'Edm.String'},
    totalSearchCount: {type: 'Edm.Int32', nullable: false},
    assetsCount: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<PersonTemplateInfoDto>;
//#endregion