//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetMediasService } from './assetmedias.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetMediasServiceEntitySetConfig = {
  name: 'AssetMedias',
  entityType: 'PaPlusApi.DataDomain.EFEntities.AssetMediaEdm',
  service: AssetMediasService
} as EntitySetConfig;
//#endregion