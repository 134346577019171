//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetMediasService } from './jobassetmedias.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobAssetMediasServiceEntitySetConfig = {
  name: 'JobAssetMedias',
  entityType: 'PaPlusApi.DataDomain.EFEntities.JobAssetMediaEdm',
  service: JobAssetMediasService
} as EntitySetConfig;
//#endregion