//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateStatusEdm } from './persontemplatestatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonTemplateStatusEdmEntityConfig = {
  name: 'PersonTemplateStatusEdm',
  keys: [{name: 'personTemplateStatusGuid'}],
  fields: {
    personTemplateStatusGuid: {type: 'Edm.Guid', nullable: false},
    templateStatusGuid: {type: 'Edm.Guid'},
    templateGuid: {type: 'Edm.Guid'},
    personGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    updatedBy: {type: 'Edm.Guid'},
    person: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]},
    template: {type: 'paplusApi.DataDomain.EFEntities.TemplateEdm', navigation: true, referentials: [{property: 'templateGuid', referencedProperty: 'templateGuid'}]},
    templateStatus: {type: 'paplusApi.DataDomain.EFEntities.TemplateStatusEdm', navigation: true, referentials: [{property: 'templateStatusGuid', referencedProperty: 'templateStatusGuid'}]},
    updatedByPerson: {type: 'paplusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'updatedBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonTemplateStatusEdm>;
//#endregion