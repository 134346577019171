import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { DynamicEnvironmentService } from '@sharedLibrary/services/dynamic-environment.service';
import { AvailableEnvironment } from 'projects/shared/src/environments/available-environments';

@Component({
    selector: 'app-environment-settings',
    templateUrl: './environment-settings.component.html',
    styleUrls: ['./environment-settings.component.scss'],
})
export class EnvironmentSettingsComponent implements OnInit {
    // A reference to the current environment config in use.
    environmentConfig = this.dynamicEnvService.environment;

    // The list of available environments (extracted from your enum).
    availableEnvironments = Object.values(AvailableEnvironment);

    // Track the user’s selected environment
    selectedEnvironment = this.dynamicEnvService.currentEnvrionment;

    constructor(private alertController: AlertController,
        private dynamicEnvService: DynamicEnvironmentService) { }

    ngOnInit() { }

    /**
     * Called when the ion-segment value changes.
     */
    onEnvironmentSegmentChanged(event: any) {
        this.selectedEnvironment = event.detail.value as AvailableEnvironment;
        this.environmentConfig = this.dynamicEnvService.getEnvironmentConfig(this.selectedEnvironment);
    }

    /**
     * Show a confirmation dialog. If user confirms, switch environment.
     */
    async confirmEnvironmentSwitch() {
        const alert = await this.alertController.create({
            header: 'Warning',
            message:
                'Changing your environment will log you out, and you cannot change it back without logging in again. Are you sure you want to continue?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Continue',
                    handler: () => {
                        this.switchEnvironment();
                    },
                },
            ],
        });

        await alert.present();
    }

    /**
     * Actually perform the environment switch, log out, and restart the app.
     */
    private switchEnvironment() {
        // 1. Here you would store/update the new environment somewhere, 
        //    e.g., in local storage, a service, or pass it to your global config.
        //    For example:
        // localStorage.setItem('selectedEnvironment', this.selectedEnvironment);

        // 2. Perform any logout steps if necessary.
        //    Example: authService.logout();

        // 3. Reload or restart the app. 
        //    In a typical Capacitor/Cordova environment, you might use:
        //    location.reload();
        //    or 
        //    navigator['app'].exitApp(); // (if you really want to close the app)
        this.dynamicEnvService.switchEnvironment(this.selectedEnvironment);
        // TODO: We probably want to logout before reloading the app
        location.reload();
    }

    get objectKeys() {
        return Object.keys;
    }
}
