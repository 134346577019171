import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetEdm } from './DataDomain/EFEntities/assetedm.entity';
import { AssetStatusEdm } from './DataDomain/EFEntities/assetstatusedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonAssetStatusEdm } from './DataDomain/EFEntities/personassetstatusedm.entity';
//#endregion

@Injectable()
export class PersonAssetStatusesService extends ODataEntitySetService<PersonAssetStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonAssetStatuses', 'PaPlusApi.DataDomain.EFEntities.PersonAssetStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public asset(key: EntityKey<PersonAssetStatusEdm>): ODataNavigationPropertyResource<AssetEdm> { 
    return this.entity(key).navigationProperty<AssetEdm>('asset'); 
  }
  public fetchAsset(key: EntityKey<PersonAssetStatusEdm>, options?: ODataQueryArgumentsOptions<AssetEdm>) {
    return this.fetchNavigationProperty<AssetEdm>(
      this.asset(key), 
      'entity', options) as Observable<ODataEntity<AssetEdm>>;
  }
  public setAssetEdmAsAsset(key: EntityKey<PersonAssetStatusEdm>, target: ODataEntityResource<ODataEntity<AssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .set(target, {etag});
  }
  public unsetAssetEdmAsAsset(key: EntityKey<PersonAssetStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.asset(key).reference()
      .unset({etag});
  }
  public assetStatus(key: EntityKey<PersonAssetStatusEdm>): ODataNavigationPropertyResource<AssetStatusEdm> { 
    return this.entity(key).navigationProperty<AssetStatusEdm>('assetStatus'); 
  }
  public fetchAssetStatus(key: EntityKey<PersonAssetStatusEdm>, options?: ODataQueryArgumentsOptions<AssetStatusEdm>) {
    return this.fetchNavigationProperty<AssetStatusEdm>(
      this.assetStatus(key), 
      'entity', options) as Observable<ODataEntity<AssetStatusEdm>>;
  }
  public setAssetStatusEdmAsAssetStatus(key: EntityKey<PersonAssetStatusEdm>, target: ODataEntityResource<ODataEntity<AssetStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetStatus(key).reference()
      .set(target, {etag});
  }
  public unsetAssetStatusEdmAsAssetStatus(key: EntityKey<PersonAssetStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AssetStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetStatus(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonAssetStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonAssetStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonAssetStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonAssetStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  public updatedByPerson(key: EntityKey<PersonAssetStatusEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('updatedByPerson'); 
  }
  public fetchUpdatedByPerson(key: EntityKey<PersonAssetStatusEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.updatedByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsUpdatedByPerson(key: EntityKey<PersonAssetStatusEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.updatedByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsUpdatedByPerson(key: EntityKey<PersonAssetStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.updatedByPerson(key).reference()
      .unset({etag});
  }
  //#endregion
}
