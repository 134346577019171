import { AfterViewInit, ChangeDetectorRef, Component, Renderer2, ViewChild } from '@angular/core';
import { APP_PAGES } from './tabs/tabs-page/tabs-page';
import { MsalAuthenticationService } from './shared/services/msal.authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvents } from './shared/enum/events-enum';
import { PubSubService } from './shared/services/pub-sub.service';
import { CUSTOMERS_HEADER, JOBS_HEADER, PROJECTS_HEADER, USERS_HEADER } from './shared/constant/header_list';
import { AlertController, IonMenu, ModalController } from '@ionic/angular';
import { hasPermission } from './shared/utils/user-permissions.extensions';
import { PersonEdm } from '@odata';
import { PAPlusUserAccountService } from './shared/services/paplus-account.service';
import { MsalService } from '@azure/msal-angular';
import { register } from 'swiper/element/bundle';
import { take } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(IonMenu, { static: false }) menu: IonMenu;

  private loggedInUser: PersonEdm;

  public appPages: any[] = APP_PAGES.filter(page => page.inSidemenu);
  public headerLink: any[] = JOBS_HEADER.filter(header => header.visible);
  public loggedIn = false;
  protected dark = false; // Do we need this, maybe it can be removed/refactored
  protected menuBpWidth = 1366;
  protected isMenuSideMode = false;

  constructor(
    private alertController: AlertController,
    private authService: MsalAuthenticationService,
    private modalController: ModalController,
    private bpObserver: BreakpointObserver,
    private msalService: MsalService,
    private paPlusUserService: PAPlusUserAccountService,
    protected pubSub: PubSubService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {
    this.authService.initalize();
    this._initTranslate();
    pubSub.init();
    this.listenForEvents();
  }

  ngAfterViewInit() {
    const menuElement = document.querySelector('ion-menu');

    this.menu.ionWillOpen.subscribe(() => {
      this.renderer.addClass(menuElement, 'expanded');
      this.pubSub.menuSate.next(true);
      //console.log('menu open');
    });

    this.menu.ionDidClose.subscribe(() => {
      this.renderer.removeClass(menuElement, 'expanded');
      this.pubSub.menuSate.next(false);
      //console.log('menu close');
    });

    // Observe the breakpoint state for changes
    this.bpObserver.observe(`(max-width: ${this.menuBpWidth}px)`).subscribe((res) => {
      if (res.matches) {
        this.isMenuSideMode = false;
        this.pubSub.menuSate.next(false);
        //console.log('moving out of side menu mode');
      }
      else {
        this.isMenuSideMode = true;
        //console.log('moving into side menu mode');
      }
    });
  }

  listenForEvents() {
    this.pubSub.subscribe(GlobalEvents.USER_LOGIN, (result) => {
      this.updateLoggedInStatus(result[0])
    });

    this.pubSub.subscribe(GlobalEvents.USER_LOGOUT, (result) => {
      this.updateLoggedInStatus(result[0]);
    });

    this.pubSub.subscribe(GlobalEvents.OPENCLOSEMENU, (result) => {
      const menuElement = document.querySelector('ion-menu');
      if (this.isMenuSideMode) {
        this.pubSub.menuSate
          .pipe(take(1))
          .subscribe((opened) => {
            if (opened) {
              this.pubSub.menuSate.next(false);
              this.renderer.removeClass(menuElement, 'expanded')
              //console.log('open menu button clicked - Expanding');
            } else {
              this.pubSub.menuSate.next(true);
              this.renderer.addClass(menuElement, 'expanded')
              //console.log('close menu button clicked - Collapsing');
            }
          });
      } else {
        this.menu.toggle().then((opened) => {
          if (opened) {
            //console.log('open menu button clicked - Opening');
          } else {
            //console.log('close menu button clicked - Closing');
          }
        });
      }
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.ref.detectChanges();
    }, 300);
  }


  updateTabs(sidemenu: string, TABS: object[]) {
    if (TABS != undefined) {
      let tabArrayObject = [{ Tabs: TABS, parent: sidemenu }]
      this.pubSub.publish(GlobalEvents.PAGE_TABS, tabArrayObject);
    }

    switch (sidemenu) {
      case 'jobs':
        this.headerLink = JOBS_HEADER.filter(header => header.visible);
        break;
      case 'projects':
        this.headerLink = PROJECTS_HEADER.filter(header => header.visible);
        break;
      case 'users':
        this.headerLink = USERS_HEADER.filter(header => header.visible);
        break;
      case 'customers':
        this.headerLink = CUSTOMERS_HEADER.filter(header => header.visible);
        break
      default:
        this.headerLink = JOBS_HEADER.filter(header => header.visible);
        break;
    }
  }


  private _initTranslate() { // Set the default language for translation strings, and the current language.

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
  }

  canReadPage(p: any) {
    if (!this.loggedInUser) {
      this.loadLoggedInUser();
    }

    return hasPermission(this.loggedInUser, p.permission);
  }

  async loadLoggedInUser() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount != null) {
      this.paPlusUserService.retrievePaPlusUser(activeAccount.localAccountId).subscribe(async res => {
        this.loggedInUser = res;

        if (!hasPermission(this.loggedInUser, '_portalAccess')) {
          let alertButtons = [
            {
              text: 'OK',
              role: 'confirm',
              handler: () => {

                this.modalController.dismiss(
                  this.msalService.logout()
                );
              },
            },
          ];

          const alert = await this.alertController.create({
            header: "Unauthorised",
            //subHeader: 'A Sub Header Is Optional',
            message: "You do not have access to the PA+ Portal, you will now be logged out.",
            buttons: alertButtons
          });

          await alert.present();
        }
      });
    }
  }
}
