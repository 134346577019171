import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { JobAssetEdm, JobEdm, JobTemplateEdm } from '@sharedLibrary/odata';
import { AssetCounterService } from '@sharedLibrary/services/asset-counter.service';

@Component({
  selector: 'lib-job-assets-container',
  templateUrl: './job-assets-container.component.html',
  styleUrls: ['./job-assets-container.component.scss'],
})
export class JobAssetsContainerComponent  implements OnInit, OnChanges {

  
    @Input() job: JobEdm;
  
    public jobTemplate: JobTemplateEdm;
    public jobAssets: JobAssetEdm[] = [];
    jobUpdated: any = false;
  
    constructor(
      private assetCounterService: AssetCounterService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
      const canUpdateJob: boolean = !changes['job'].firstChange &&changes['job'].currentValue && changes['job'].currentValue.jobTemplates.length > 0;
      if (canUpdateJob) {
        // console.log('job has been UPDATED second change, CHANGES: ', changes);
        const job = changes['job'].currentValue as JobEdm;
        const jobTemplate = job.jobTemplates[0];
        this.jobUpdated = true;
        const jobAssets: JobAssetEdm[] = [];
        jobTemplate.jobAssetTemplates.forEach(jat => {
          if (jat.jobAsset.jobParentAssetGuid == null) {
            jobAssets.push(jat.jobAsset);
          }          
        });
        this.jobAssets = jobAssets;
      }
    }
  
    ngOnInit() {
      if (this.job && this.job.jobTemplates.length > 0) {
  
        this.jobTemplate = this.job.jobTemplates[0];
        this.jobTemplate.jobAssetTemplates.forEach(jat => {
          if (jat.jobAsset.jobParentAssetGuid == null) {
            this.jobAssets.push(jat.jobAsset);
          }
        });
      }
    }

  fillAssetCounter(asset: JobAssetEdm) {
    // console.log('fillAssetCounter JOB ASSET CONTAINER: job updated ->', this.jobUpdated);
    
    return this.assetCounterService.fillAssetCounter(asset);
  }

}
