//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MediaStatusesService } from './mediastatuses.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const MediaStatusesServiceEntitySetConfig = {
  name: 'MediaStatuses',
  entityType: 'PaPlusApi.DataDomain.EFEntities.MediaStatusEdm',
  service: MediaStatusesService
} as EntitySetConfig;
//#endregion