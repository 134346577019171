import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetOuEdm } from './DataDomain/EFEntities/assetouedm.entity';
import { JobEdm } from './DataDomain/EFEntities/jobedm.entity';
import { OuEdm } from './DataDomain/EFEntities/ouedm.entity';
import { OuAddressEdm } from './DataDomain/EFEntities/ouaddressedm.entity';
import { OuCloseoutPackageEdm } from './DataDomain/EFEntities/oucloseoutpackageedm.entity';
import { OuTemplateEdm } from './DataDomain/EFEntities/outemplateedm.entity';
import { PersonOuRoleEdm } from './DataDomain/EFEntities/personouroleedm.entity';
import { ProjectEdm } from './DataDomain/EFEntities/projectedm.entity';
import { RoleEdm } from './DataDomain/EFEntities/roleedm.entity';
//#endregion

@Injectable()
export class OusService extends ODataEntitySetService<OuEdm> {
  constructor(client: ODataClient) {
    super(client, 'Ous', 'paplusApi.DataDomain.EFEntities.OuEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public assetOus(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<AssetOuEdm> { 
    return this.entity(key).navigationProperty<AssetOuEdm>('assetOus'); 
  }
  public fetchAssetOus(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<AssetOuEdm>) {
    return this.fetchNavigationProperty<AssetOuEdm>(
      this.assetOus(key), 
      'entities', options) as Observable<ODataEntities<AssetOuEdm>>;
  }
  public addAssetOuEdmToAssetOus(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<AssetOuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.assetOus(key).reference()
      .add(target);
  }
  public removeAssetOuEdmFromAssetOus(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AssetOuEdm>>, etag?: string} = {}): Observable<any> {
    return this.assetOus(key).reference()
      .remove(target);
  }
  public jobs(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<JobEdm> { 
    return this.entity(key).navigationProperty<JobEdm>('jobs'); 
  }
  public fetchJobs(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<JobEdm>) {
    return this.fetchNavigationProperty<JobEdm>(
      this.jobs(key), 
      'entities', options) as Observable<ODataEntities<JobEdm>>;
  }
  public addJobEdmToJobs(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<JobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobs(key).reference()
      .add(target);
  }
  public removeJobEdmFromJobs(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<JobEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobs(key).reference()
      .remove(target);
  }
  public ouAddresses(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<OuAddressEdm> { 
    return this.entity(key).navigationProperty<OuAddressEdm>('ouAddresses'); 
  }
  public fetchOuAddresses(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<OuAddressEdm>) {
    return this.fetchNavigationProperty<OuAddressEdm>(
      this.ouAddresses(key), 
      'entities', options) as Observable<ODataEntities<OuAddressEdm>>;
  }
  public addOuAddressEdmToOuAddresses(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<OuAddressEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouAddresses(key).reference()
      .add(target);
  }
  public removeOuAddressEdmFromOuAddresses(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuAddressEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouAddresses(key).reference()
      .remove(target);
  }
  public ouCloseoutPackages(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<OuCloseoutPackageEdm> { 
    return this.entity(key).navigationProperty<OuCloseoutPackageEdm>('ouCloseoutPackages'); 
  }
  public fetchOuCloseoutPackages(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<OuCloseoutPackageEdm>) {
    return this.fetchNavigationProperty<OuCloseoutPackageEdm>(
      this.ouCloseoutPackages(key), 
      'entities', options) as Observable<ODataEntities<OuCloseoutPackageEdm>>;
  }
  public addOuCloseoutPackageEdmToOuCloseoutPackages(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<OuCloseoutPackageEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouCloseoutPackages(key).reference()
      .add(target);
  }
  public removeOuCloseoutPackageEdmFromOuCloseoutPackages(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuCloseoutPackageEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouCloseoutPackages(key).reference()
      .remove(target);
  }
  public ouTemplates(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<OuTemplateEdm> { 
    return this.entity(key).navigationProperty<OuTemplateEdm>('ouTemplates'); 
  }
  public fetchOuTemplates(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<OuTemplateEdm>) {
    return this.fetchNavigationProperty<OuTemplateEdm>(
      this.ouTemplates(key), 
      'entities', options) as Observable<ODataEntities<OuTemplateEdm>>;
  }
  public addOuTemplateEdmToOuTemplates(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<OuTemplateEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .add(target);
  }
  public removeOuTemplateEdmFromOuTemplates(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuTemplateEdm>>, etag?: string} = {}): Observable<any> {
    return this.ouTemplates(key).reference()
      .remove(target);
  }
  public parentOu(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('parentOu'); 
  }
  public fetchParentOu(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.parentOu(key), 
      'entity', options) as Observable<ODataEntity<OuEdm>>;
  }
  public setOuEdmAsParentOu(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntity<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentOu(key).reference()
      .set(target, {etag});
  }
  public unsetOuEdmAsParentOu(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentOu(key).reference()
      .unset({etag});
  }
  public parentOus(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<OuEdm> { 
    return this.entity(key).navigationProperty<OuEdm>('parentOus'); 
  }
  public fetchParentOus(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<OuEdm>) {
    return this.fetchNavigationProperty<OuEdm>(
      this.parentOus(key), 
      'entities', options) as Observable<ODataEntities<OuEdm>>;
  }
  public addOuEdmToParentOus(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<OuEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.parentOus(key).reference()
      .add(target);
  }
  public removeOuEdmFromParentOus(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<OuEdm>>, etag?: string} = {}): Observable<any> {
    return this.parentOus(key).reference()
      .remove(target);
  }
  public personOuRoles(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<PersonOuRoleEdm> { 
    return this.entity(key).navigationProperty<PersonOuRoleEdm>('personOuRoles'); 
  }
  public fetchPersonOuRoles(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<PersonOuRoleEdm>) {
    return this.fetchNavigationProperty<PersonOuRoleEdm>(
      this.personOuRoles(key), 
      'entities', options) as Observable<ODataEntities<PersonOuRoleEdm>>;
  }
  public addPersonOuRoleEdmToPersonOuRoles(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .add(target);
  }
  public removePersonOuRoleEdmFromPersonOuRoles(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonOuRoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.personOuRoles(key).reference()
      .remove(target);
  }
  public projects(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<ProjectEdm> { 
    return this.entity(key).navigationProperty<ProjectEdm>('projects'); 
  }
  public fetchProjects(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<ProjectEdm>) {
    return this.fetchNavigationProperty<ProjectEdm>(
      this.projects(key), 
      'entities', options) as Observable<ODataEntities<ProjectEdm>>;
  }
  public addProjectEdmToProjects(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<ProjectEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.projects(key).reference()
      .add(target);
  }
  public removeProjectEdmFromProjects(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProjectEdm>>, etag?: string} = {}): Observable<any> {
    return this.projects(key).reference()
      .remove(target);
  }
  public roles(key: EntityKey<OuEdm>): ODataNavigationPropertyResource<RoleEdm> { 
    return this.entity(key).navigationProperty<RoleEdm>('roles'); 
  }
  public fetchRoles(key: EntityKey<OuEdm>, options?: ODataQueryArgumentsOptions<RoleEdm>) {
    return this.fetchNavigationProperty<RoleEdm>(
      this.roles(key), 
      'entities', options) as Observable<ODataEntities<RoleEdm>>;
  }
  public addRoleEdmToRoles(key: EntityKey<OuEdm>, target: ODataEntityResource<ODataEntities<RoleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.roles(key).reference()
      .add(target);
  }
  public removeRoleEdmFromRoles(key: EntityKey<OuEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<RoleEdm>>, etag?: string} = {}): Observable<any> {
    return this.roles(key).reference()
      .remove(target);
  }
  //#endregion
}
