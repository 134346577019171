//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OuCloseoutPackageEdm } from './oucloseoutpackageedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OuCloseoutPackageEdmEntityConfig = {
  name: 'OuCloseoutPackageEdm',
  keys: [{name: 'ouCloseOutPackageGuid'}],
  fields: {
    ouCloseOutPackageGuid: {type: 'Edm.Guid', nullable: false},
    ouGuid: {type: 'Edm.Guid'},
    closeoutPackageGuid: {type: 'Edm.Guid'},
    fromUtcDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toUtcDateTime: {type: 'Edm.DateTimeOffset'},
    closeoutPackage: {type: 'PaPlusApi.DataDomain.EFEntities.CloseoutPackageEdm', navigation: true, referentials: [{property: 'closeoutPackageGuid', referencedProperty: 'closeoutPackageGuid'}]},
    ou: {type: 'PaPlusApi.DataDomain.EFEntities.OuEdm', navigation: true, referentials: [{property: 'ouGuid', referencedProperty: 'ouGuid'}]}
  }
} as StructuredTypeConfig<OuCloseoutPackageEdm>;
//#endregion