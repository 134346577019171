//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateStatusEdm } from './persontemplatestatusedm.entity';
//#endregion

export interface TemplateStatusEdm {
  //#region ODataApiGen Properties
  templateStatusGuid: string;
  description: string;
  showInUi: boolean;
  active: boolean;
  personTemplateStatuses?: PersonTemplateStatusEdm[];
  //#endregion
}