//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface PersonTemplateInfoEdm {
  //#region ODataApiGen Properties
  templateGuid: string;
  personGuid: string;
  ouGuid: string;
  templateDescription?: string;
  readOnlyTemplate?: boolean;
  templateStatusGuid?: string;
  templateStatusDescription?: string;
  totalSearchCount: number;
  assetsCount: number;
  //#endregion
}