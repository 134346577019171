//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface CaptureDTO {
  //#region ODataApiGen Properties
  captureGuid: string;
  jobAssetMediaGuid: string;
  createUtcDateTime: Date;
  notes: string;
  mediaSource?: number;
  filePath: string;
  nativeFileName: string;
  imageData?: ArrayBuffer;
  contentType?: string;
  //#endregion
}