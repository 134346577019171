import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobEdm } from './DataDomain/EFEntities/jobedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/personedm.entity';
import { PersonJobEdm } from './DataDomain/EFEntities/personjobedm.entity';
//#endregion

@Injectable()
export class PersonJobsService extends ODataEntitySetService<PersonJobEdm> {
  constructor(client: ODataClient) {
    super(client, 'PersonJobs', 'PaPlusApi.DataDomain.EFEntities.PersonJobEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public job(key: EntityKey<PersonJobEdm>): ODataNavigationPropertyResource<JobEdm> { 
    return this.entity(key).navigationProperty<JobEdm>('job'); 
  }
  public fetchJob(key: EntityKey<PersonJobEdm>, options?: ODataQueryArgumentsOptions<JobEdm>) {
    return this.fetchNavigationProperty<JobEdm>(
      this.job(key), 
      'entity', options) as Observable<ODataEntity<JobEdm>>;
  }
  public setJobEdmAsJob(key: EntityKey<PersonJobEdm>, target: ODataEntityResource<ODataEntity<JobEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.job(key).reference()
      .set(target, {etag});
  }
  public unsetJobEdmAsJob(key: EntityKey<PersonJobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobEdm>>, etag?: string} = {}): Observable<any> {
    return this.job(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<PersonJobEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<PersonJobEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<PersonJobEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<PersonJobEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
