//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonMediaContributorsService } from './personmediacontributors.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PersonMediaContributorsServiceEntitySetConfig = {
  name: 'PersonMediaContributors',
  entityType: 'PaPlusApi.DataDomain.EFEntities.PersonMediaContributorEdm',
  service: PersonMediaContributorsService
} as EntitySetConfig;
//#endregion