//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PeopleService } from './people.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PeopleServiceEntitySetConfig = {
  name: 'People',
  entityType: 'PaPlusApi.DataDomain.EFEntities.PersonEdm',
  service: PeopleService
} as EntitySetConfig;
//#endregion