//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AssetTemplatesService } from './assettemplates.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AssetTemplatesServiceEntitySetConfig = {
  name: 'AssetTemplates',
  entityType: 'PaPlusApi.DataDomain.EFEntities.AssetTemplateEdm',
  service: AssetTemplatesService
} as EntitySetConfig;
//#endregion