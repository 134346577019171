//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TemplateStatusEdm } from './templatestatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TemplateStatusEdmEntityConfig = {
  name: 'TemplateStatusEdm',
  keys: [{name: 'templateStatusGuid'}],
  fields: {
    templateStatusGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    showInUi: {type: 'Edm.Boolean', nullable: false},
    active: {type: 'Edm.Boolean', nullable: false},
    personTemplateStatuses: {type: 'paplusApi.DataDomain.EFEntities.PersonTemplateStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<TemplateStatusEdm>;
//#endregion