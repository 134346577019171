//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonDeviceEdm } from './persondeviceedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonDeviceEdmEntityConfig = {
  name: 'PersonDeviceEdm',
  keys: [{name: 'personDeviceGuid'}],
  fields: {
    personDeviceGuid: {type: 'Edm.Guid', nullable: false},
    personGuid: {type: 'Edm.Guid'},
    deviceGuid: {type: 'Edm.Guid'},
    fromDateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    toDateTime: {type: 'Edm.DateTimeOffset'},
    device: {type: 'PaPlusApi.DataDomain.EFEntities.DeviceEdm', navigation: true, referentials: [{property: 'deviceGuid', referencedProperty: 'deviceGuid'}]},
    person: {type: 'PaPlusApi.DataDomain.EFEntities.PersonEdm', navigation: true, referentials: [{property: 'personGuid', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<PersonDeviceEdm>;
//#endregion