import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobAssetEdm } from './DataDomain/EFEntities/jobassetedm.entity';
import { JobAssetParentAssetEdm } from './DataDomain/EFEntities/jobassetparentassetedm.entity';
//#endregion

@Injectable()
export class JobAssetParentAssetsService extends ODataEntitySetService<JobAssetParentAssetEdm> {
  constructor(client: ODataClient) {
    super(client, 'JobAssetParentAssets', 'PaPlusApi.DataDomain.EFEntities.JobAssetParentAssetEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public jobAsset(key: EntityKey<JobAssetParentAssetEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobAsset'); 
  }
  public fetchJobAsset(key: EntityKey<JobAssetParentAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobAsset(key), 
      'entity', options) as Observable<ODataEntity<JobAssetEdm>>;
  }
  public setJobAssetEdmAsJobAsset(key: EntityKey<JobAssetParentAssetEdm>, target: ODataEntityResource<ODataEntity<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetEdmAsJobAsset(key: EntityKey<JobAssetParentAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobAsset(key).reference()
      .unset({etag});
  }
  public jobParentAssetGuidJobAsset(key: EntityKey<JobAssetParentAssetEdm>): ODataNavigationPropertyResource<JobAssetEdm> { 
    return this.entity(key).navigationProperty<JobAssetEdm>('jobParentAssetGuidJobAsset'); 
  }
  public fetchJobParentAssetGuidJobAsset(key: EntityKey<JobAssetParentAssetEdm>, options?: ODataQueryArgumentsOptions<JobAssetEdm>) {
    return this.fetchNavigationProperty<JobAssetEdm>(
      this.jobParentAssetGuidJobAsset(key), 
      'entity', options) as Observable<ODataEntity<JobAssetEdm>>;
  }
  public setJobAssetEdmAsJobParentAssetGuidJobAsset(key: EntityKey<JobAssetParentAssetEdm>, target: ODataEntityResource<ODataEntity<JobAssetEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGuidJobAsset(key).reference()
      .set(target, {etag});
  }
  public unsetJobAssetEdmAsJobParentAssetGuidJobAsset(key: EntityKey<JobAssetParentAssetEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<JobAssetEdm>>, etag?: string} = {}): Observable<any> {
    return this.jobParentAssetGuidJobAsset(key).reference()
      .unset({etag});
  }
  //#endregion
}
