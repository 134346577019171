import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonTemplateStatusEdm } from './DataDomain/EFEntities/persontemplatestatusedm.entity';
import { TemplateStatusEdm } from './DataDomain/EFEntities/templatestatusedm.entity';
//#endregion

@Injectable()
export class TemplateStatusesService extends ODataEntitySetService<TemplateStatusEdm> {
  constructor(client: ODataClient) {
    super(client, 'TemplateStatuses', 'paplusApi.DataDomain.EFEntities.TemplateStatusEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public personTemplateStatuses(key: EntityKey<TemplateStatusEdm>): ODataNavigationPropertyResource<PersonTemplateStatusEdm> { 
    return this.entity(key).navigationProperty<PersonTemplateStatusEdm>('personTemplateStatuses'); 
  }
  public fetchPersonTemplateStatuses(key: EntityKey<TemplateStatusEdm>, options?: ODataQueryArgumentsOptions<PersonTemplateStatusEdm>) {
    return this.fetchNavigationProperty<PersonTemplateStatusEdm>(
      this.personTemplateStatuses(key), 
      'entities', options) as Observable<ODataEntities<PersonTemplateStatusEdm>>;
  }
  public addPersonTemplateStatusEdmToPersonTemplateStatuses(key: EntityKey<TemplateStatusEdm>, target: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .add(target);
  }
  public removePersonTemplateStatusEdmFromPersonTemplateStatuses(key: EntityKey<TemplateStatusEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PersonTemplateStatusEdm>>, etag?: string} = {}): Observable<any> {
    return this.personTemplateStatuses(key).reference()
      .remove(target);
  }
  //#endregion
}
