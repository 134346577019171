//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CloseoutPackageEdm } from './closeoutpackageedm.entity';
import { OuEdm } from './ouedm.entity';
//#endregion

export interface OuCloseoutPackageEdm {
  //#region ODataApiGen Properties
  ouCloseOutPackageGuid: string;
  ouGuid?: string;
  closeoutPackageGuid?: string;
  fromUtcDateTime: Date;
  toUtcDateTime?: Date;
  closeoutPackage?: CloseoutPackageEdm;
  ou?: OuEdm;
  //#endregion
}