import { AvailableEnvironment } from "./available-environments";

export const environment = {
  Production: true,
  BuildEnvironment: AvailableEnvironment.Prod,
  PortalUrl: 'https://portal.paplus.prod.rgsit.cloud',
  MobileUrl: 'https://mobile.paplus.prod.rgsit.cloud',
  Api: 'https://api.paplus.prod.rgsit.cloud/odata',
  SignalR: 'https://api.paplus.prod.rgsit.cloud/signalr',
  B2cDomain: 'paplusprod',
  B2cTenantId: '31806fdc-be9d-4e64-88df-f9579aa99fbd', // TODO
  PortalClientId: 'dbb93e89-7f65-4179-a3c8-83266f97d70f', // TODO
  MobileClientId: 'a236752e-fa6b-441a-89bf-0f3e7a7411b0', // TODO
  BackendAppIdentifier: 'prod-api',
  GoogleMapsApiKey: 'AIzaSyAh-wSELQf54o5x6p33MDkucbMC2L0oHdQ',
  What3WordsKey: '87B5JULS',
  PortalSentryDns: 'https://4a8243585616091196b87aeaac23f893@o4508609265532928.ingest.de.sentry.io/4508766633590864',
  MobileSentryDns: 'https://f38c6e58d7ea8c9160ff2c89936a633f@o4508609265532928.ingest.de.sentry.io/4508767385223248',
};
