import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Injectable, NgZone } from "@angular/core";
import { DynamicEnvironmentService } from '@sharedLibrary/services/dynamic-environment.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeepLinksService {
    constructor(private zone: NgZone,
        private envService: DynamicEnvironmentService) {
    }

    private deepLinkOpened: Subject<string> = new Subject<string>();
    public deepLinkOpened$ = this.deepLinkOpened.asObservable();

    initializeDeepLinks() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                console.log('Deep link opened with URL: ' + event.url);
                const fullUrl = event.url;
                const projectUrl = this.envService.getProjectUrl();

                if (fullUrl.startsWith(projectUrl)) {
                    // Remove the prefix
                    const relativeUrl = fullUrl.substring(projectUrl.length);

                    // Ensure it starts with a forward slash
                    let navigationPath = relativeUrl.startsWith('/')
                        ? relativeUrl
                        : `/${relativeUrl}`;

                    console.log('Navigating to:', navigationPath);

                    // Notify subscribers
                    this.deepLinkOpened.next(navigationPath);

                    // Navigate to the path
                    window.location.href = navigationPath;
                }
            });
        });
    }
}