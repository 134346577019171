//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProjectStatusEdm } from './projectstatusedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProjectStatusEdmEntityConfig = {
  name: 'ProjectStatusEdm',
  keys: [{name: 'projectStatusGuid'}],
  fields: {
    projectStatusGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String', nullable: false, maxLength: -1},
    projectProjectStatuses: {type: 'PaPlusApi.DataDomain.EFEntities.ProjectProjectStatusEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<ProjectStatusEdm>;
//#endregion