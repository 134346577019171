import { Inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PROJECT_TYPE, ProjectType } from './project-type';

// Import environments
import { environment as environmentBase } from 'projects/shared/src/environments/environment';
import { environment as environmentLocal } from 'projects/shared/src/environments/environment.local';
import { environment as environmentDev } from 'projects/shared/src/environments/environment.dev';
import { environment as environmentUat } from 'projects/shared/src/environments/environment.uat';
import { environment as environmentStaging } from 'projects/shared/src/environments/environment.staging';
import { environment as environmentTraining } from 'projects/shared/src/environments/environment.training';
import { environment as environmentProd } from 'projects/shared/src/environments/environment.prod';
import { AvailableEnvironment } from '../../environments/available-environments';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicEnvironmentService {
    private environmentType: AvailableEnvironment;
    private environmentSubject = new BehaviorSubject<any>(this.environment);
    public environment$ = this.environmentSubject.asObservable();

    constructor(@Inject(PROJECT_TYPE) private projectType: ProjectType) {
        this.environmentType = this.detectEnvironment();

        console.log('Detected Native Platform:', this.isNative());
        console.log('Detected Project:', this.projectType);
        console.log('Detected Environment:', this.environmentType);
        console.log("Cache Location:", this.getOAuthCacheLocation());
    }

    private detectEnvironment(): AvailableEnvironment {
        if (environmentBase.BuildEnvironment) {
            return this.detectEnvironmentFromBuildEnv();
        }
        // We might need to tweak this if we ever deploy the portal as a mobile app
        else if (this.isMobile()) {
            return this.detectEnvironmentFromLocalStorage();
        }
        else {
            const hostname = window.location.hostname.toLowerCase();
            return this.detectEnvironmentFromHostname(hostname);
        }
    }

    // Detect the environment based on hostname
    private detectEnvironmentFromHostname(hostname: string): AvailableEnvironment {
        console.log('Detecting environment from hostname:', hostname);
        if (this.isLocalhost(hostname)) {
            return AvailableEnvironment.Local;
        } else if (hostname.includes('.dev.')) {
            return AvailableEnvironment.Dev;
        } else if (hostname.includes('.uat.')) {
            return AvailableEnvironment.Uat;
        } else if (hostname.includes('.staging.')) {
            return AvailableEnvironment.Staging;
        } else if (hostname.includes('.training.')) {
            return AvailableEnvironment.Training;
        } else {
            return AvailableEnvironment.Prod;
        }
    }

    // Detect the environment bases on the local storage
    private detectEnvironmentFromLocalStorage(): AvailableEnvironment {
        console.log('Detecting environment from local storage');
        const environment = localStorage.getItem('environment');
        if (environment) {
            return environment as AvailableEnvironment;
        } else {
            return AvailableEnvironment.Prod;
        }
    }

    // Detect the environment based on the build environment
    private detectEnvironmentFromBuildEnv(): AvailableEnvironment {
        console.log('Detecting environment from build environment');
        return environmentBase.BuildEnvironment;
    }

    // Switch the environment and save to local storage
    public switchEnvironment(environment: AvailableEnvironment) {
        this.environmentType = environment;
        localStorage.setItem('environment', environment);
        // Notify subscribers of the new environment
        this.environmentSubject.next(this.environment);
    }

    public isPortal(): boolean {
        return this.projectType === ProjectType.Portal;
    }

    public isMobile(): boolean {
        return this.projectType === ProjectType.Mobile;
    }

    public isNative(): boolean {
        return Capacitor.isNativePlatform();
    }

    public isLocalhost(hostname?: string): boolean {
        hostname = hostname || window.location.hostname.toLowerCase();
        return hostname === 'localhost' || hostname === '127.0.0.1'
    }

    public isNativeOrLocalhost(): boolean {
        return this.isNative() || this.isLocalhost();
    }

    public getProjectType(): string {
        return this.projectType
    }

    public getClientId(): string {
        return this.isMobile() ? this.environment.MobileClientId : this.environment.PortalClientId;
    }

    public getProjectUrl(): string {
        return this.isMobile() ? this.environment.MobileUrl : this.environment.PortalUrl;
    }

    public getRedirectUri(): string {
        if (this.isLocalhost() && !this.isNative()) {
            return '/auth';
        }

        return this.getProjectUrl() + '/auth';
    }

    public getPostLogoutRedirectUri(): string {
        if (this.isLocalhost() && !this.isNative()) {
            return '/logout';
        }

        return this.getProjectUrl() + '/logout';
    }

    public getOAuthCacheLocation(): string {
        return this.isNative() ? BrowserCacheLocation.LocalStorage : BrowserCacheLocation.SessionStorage;
    }

    public getSentryDns(): string {
        return this.isMobile() ? this.environment.MobileSentryDns : this.environment.PortalSentryDns;
    }

    public getEnvironmentConfig(environment: AvailableEnvironment): any {
        switch (environment) {
            case AvailableEnvironment.Local:
                return environmentLocal;
            case AvailableEnvironment.Dev:
                return environmentDev;
            case AvailableEnvironment.Uat:
                return environmentUat;
            case AvailableEnvironment.Staging:
                return environmentStaging;
            case AvailableEnvironment.Training:
                return environmentTraining;
            default:
                return environmentProd;
        }
    }

    // Public getters
    public get currentEnvrionment(): AvailableEnvironment {
        return this.environmentType;
    }

    public get currentEnvironmentName(): string {
        return this.environmentType.toString();
    }

    // This Getter method make it easier to intgrate with existing code
    public get environment(): any {
        return this.getEnvironmentConfig(this.environmentType);
    }
}
